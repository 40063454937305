import React from 'react';
import { styled } from '@/theme';
import { Icon } from 'antd';
import { DateTime } from 'luxon';
import { TimeIcon } from '@/static/icons';
import { formatDiffDate } from '@/shared/utils';

const LeftTimeTag = styled.div<{ size: 'sm' | 'md' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ size }) => (size === 'md' ? '14px' : '12px')};
  line-height: ${({ size }) => (size === 'md' ? '18px' : '15px')};
`;

type LeftTimeProps = {
  endsDate: DateTime;
  size?: 'sm' | 'md';
  withIcon?: boolean;
  short?: boolean;
};

export const LeftTime: React.FC<LeftTimeProps> = ({ endsDate, size = 'md', withIcon = true, short = false }) => {
  const diff = formatDiffDate(endsDate, false, short);

  return (
    <LeftTimeTag size={size}>
      {withIcon && <Icon component={TimeIcon} css={{ fontSize: size === 'md' ? 18 : 15, marginRight: 8 }} />}
      {`${diff} left`}
    </LeftTimeTag>
  );
};
