import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ColumnFlex } from '@/components';

export const OffersContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: min-content;
  margin-bottom: 20px;
`;

export const PropertyCardsTag = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 20px;
  position: relative;
  height: 100%;
`;

export const OffersCardsEmptyTag = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  color: #929ea8;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  flex-direction: column;
`;

export const OffersCardsEmptyTitle = styled.div`
  max-width: 215px;
  text-align: center;
`;

export const OffersCardsEmptyLogo = css`
  margin-bottom: 30px;
  filter: grayscale(100%);
  width: 228px;
  height: 52px;
`;

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  border-radius: 4px;
  height: auto;
  min-width: auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  & > * {
    height: 100%;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const OfferHeaderTag = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 100px 1fr;
  max-height: 100px;
  margin: 20px 20px 0 20px;
  border-radius: 6px;
  background-color: #f1f1f1;
  overflow: hidden;
  min-height: 93px;
`;

export const OfferInfoColumnTag = styled(ColumnFlex)`
  padding: 12px;
  overflow: hidden;

  > *:not(:first-child) {
    > * {
      font-size: 13px !important;
      height: auto;
    }
  }
`;

export const OfferImageWrapperTag = styled.div`
  position: relative;
`;

export const OffersCountPositionTag = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 1;
`;

export const OfferImageTag = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
`;
