export enum AppRoutes {
  RENTER_LANDING = '/landing',
  RENTER_ACCOUNT = '/account',
  RENTER_NOT_FOUND_PAGE = '/not-found',
  RENTER_ERROR_PAGE = '/something-went-wrong',

  RENTER_RENTALS = '/rentals',
  RENTER_SINGLE_RENTAL = '/rentals/:id',
  RENTER_SINGLE_RENTAL_BIDDING = '/rentals/:id/bidding',
  RENTER_OFFERS = '/offers',
  RENTALS_ID_COMPANY_SINGLE_UNITS = '/rentals/:id/single-units',
}

export function getSingleRentalUrl(offerId?: string | null): string {
  return `/rentals/${offerId}`;
}

export function getSingleRentalBiddingUrl(offerId?: string | null): string {
  return `/rentals/${offerId}/bidding`;
}
