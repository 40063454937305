import React from 'react';

import { RenterLayout } from '../_layouts/RenterLayout';
import { NotFound } from './NotFound';

export default function NotFoundPage() {
  return (
    <RenterLayout padding="none">
      <NotFound />
    </RenterLayout>
  );
}
