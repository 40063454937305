import React from 'react';
import { Modal, Icon } from 'antd';

import { useModalsState } from '@/providers/modalProvider';
import { toast } from 'react-toastify';
import { styled } from '@/theme';
import { BID_STATUSES } from '@/shared/constants';
import { RedAlert } from '@/static/icons';
import { ModalFooter, Loader } from '@/components';

import { OFFER_REJECT_MODAL_BID_MUTATION } from './queries';
import { useHistory } from 'react-router-dom';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import { useMutationSafe } from '@/features/_hooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 24px 39px 8px 39px;
  height: 150px;
`;

type OfferRejectModalProps = {
  visible?: boolean;
  bidId?: string;
  offerId?: string;
  options?: {
    refetchQueries?: Array<string>;
    awaitRefetchQueries?: boolean;
  };
};

export const OfferRejectModal = ({ visible, bidId, offerId, options = {} }: OfferRejectModalProps) => {
  const { offerRejectModal } = useModalsState();
  const redirectUrl = getSingleRentalUrl(offerId);
  const history = useHistory();

  const [bidCancel, { loading: bidCancelLoading }] = useMutationSafe(OFFER_REJECT_MODAL_BID_MUTATION, {
    onCompleted: () => {
      toast.success('Successfully rejected');
      offerRejectModal.close();
      history.push(redirectUrl);
    },
  });

  const title = (
    <div>
      <Icon
        component={RedAlert}
        css={{ marginRight: '8px', fontSize: '20px', lineHeight: '22px', verticalAlign: 'middle' }}
      />
      Reject Offer
    </div>
  );

  const handleBidCancel = () => {
    bidCancel({
      ...options,
      variables: {
        filter: {
          id: bidId,
        },
        data: {
          status: BID_STATUSES.canceled,
        },
      },
    });
  };

  return (
    <Modal
      title={title}
      closable={!bidCancelLoading}
      maskClosable={!bidCancelLoading}
      className="livo-modal"
      visible={visible}
      onCancel={offerRejectModal.close}
      width="100%"
      style={{ maxWidth: 400 }}
      centered
      footer={
        <ModalFooter
          cancelProps={{
            onClick: offerRejectModal.close,
            disabled: bidCancelLoading,
          }}
          okProps={{
            type: 'danger',
            text: 'Yes, Reject Offer',
            disabled: bidCancelLoading,
            loading: bidCancelLoading,
            onClick: handleBidCancel,
          }}
        />
      }
    >
      <Choose>
        <When condition={bidCancelLoading}>
          <Loader height="150px" stretch />
        </When>
        <Otherwise>
          <Container>
            Are you sure you want to reject the offer? This action cannot be undone. You will release the apartment of
            your dreams to someone else.
          </Container>
        </Otherwise>
      </Choose>
    </Modal>
  );
};
