import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { REACT_APP_PROD, REACT_APP_API_SUFFIX, REACT_APP_SENTRY_DSN, REACT_APP_VERSION_OVERRIDE } = process.env as any;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  // just in case we forget to update the version number
  release: `mobile-${REACT_APP_VERSION_OVERRIDE || '0.9.9'}${REACT_APP_API_SUFFIX}`,
  // simply for context; shouldn't be using it in dev anyway
  environment: REACT_APP_PROD ? 'production' : 'development',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: REACT_APP_PROD ? 0.5 : 1.0,
});

export const sendToSentry = (
  err: Error | string,
  errorInfo?: { [key: string]: any },
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback?: (eventID: string) => {},
) => {
  // should mostly be a prod-only feature
  // but if you really-really want it,
  // you can overrite the REACT_APP_PROD flag
  if (!REACT_APP_PROD) {
    return;
  }

  Sentry.withScope(scope => {
    if (errorInfo) {
      scope.setExtras(errorInfo);
    }

    if (window) {
      scope.setTag('location_pathname', window.location.pathname);
    }

    const auth = localStorage.getItem('auth');

    if (auth) {
      try {
        const authJson = JSON.parse(auth);
        scope.setUser({ email: authJson?.email });
      } catch (err) {
        scope.setExtras({ authJsonParse: 'error' });
      }
    }

    let eventID = null;

    if (typeof err === 'string') {
      eventID = Sentry.captureMessage(err);
    } else {
      eventID = Sentry.captureException(err);
    }

    callback?.(eventID);
  });
};
