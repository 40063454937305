import { useCurrentUser, useAllowedCommunities } from '@/features/_hooks';

import { getApplicationRole } from '@/shared/utils';
import { APPLICATION_ROLE_KEYS } from '@/shared/constants';

export const usePermissions = () => {
  const { user } = useCurrentUser();
  const allowedCommunities = useAllowedCommunities();

  const applicationRole = getApplicationRole(user);

  const isAdmin = !!applicationRole?.admin;
  const isSuperAdmin = !!applicationRole?.superAdmin;
  const isRenter = applicationRole?.key === APPLICATION_ROLE_KEYS.renter;
  const isCompanyUser = !!applicationRole?.companyType;
  const isCompanyOwner = !!applicationRole?.companyOwner;
  const isCompanyManager = applicationRole?.key === APPLICATION_ROLE_KEYS.companyManager;
  const isCompanyAdmin = applicationRole?.key === APPLICATION_ROLE_KEYS.companyAdmin;

  return {
    isAdmin,
    isSuperAdmin,
    isRenter,
    isCompanyUser,
    isCompanyOwner,
    isCompanyManager,
    isCompanyAdmin,
    applicationRole,
    allowedCommunities,
  };
};
