import React from 'react';
import { Properties } from './Properties';
import { RenterLayout } from '../_layouts/RenterLayout';

export default function PropertiesPage() {
  return (
    <RenterLayout>
      <Properties />
    </RenterLayout>
  );
}
