import React from 'react';
import { Modal, Avatar } from 'antd';
import { stringifyNA, getInitials } from '@/shared/utils';

import Maybe from 'graphql/tsutils/Maybe';
import { User } from '@/shared/types/graphql';
import { useModalsState } from '@/providers';
import { AsyncContent, Grid, WebsiteLink, Scrollable } from '@/components';
import { EntityOption, InformationSection } from '@/features';

type ScheduleShowingContactModalProps = {
  visible?: boolean;
  contact?: Maybe<User>;
  loading?: boolean;
};

export const ScheduleShowingContactModal = ({ visible, contact, loading }: ScheduleShowingContactModalProps) => {
  const { scheduleShowingContactModal } = useModalsState();

  return (
    <Modal
      title="To schedule a showing, please contact:"
      className="livo-modal"
      visible={visible}
      onCancel={scheduleShowingContactModal.close}
      width="100%"
      style={{ maxWidth: 600 }}
      centered
      footer={null}
    >
      <Scrollable padding="25px">
        <AsyncContent loading={loading}>
          <Grid.Layout gap="20px">
            <Avatar src={contact?.avatar?.downloadUrl || undefined} size={188}>
              {getInitials(contact?.firstName, contact?.lastName)}
            </Avatar>
            <Grid.Layout gap="20px">
              <InformationSection header="Information">
                <Grid.Layout gap="20px">
                  <EntityOption
                    label="Company Name"
                    value={stringifyNA(contact?.company?.name)}
                    type="uppercase-label"
                  />
                  <EntityOption
                    label="Company Website"
                    value={<WebsiteLink website={contact?.company?.website} />}
                    type="uppercase-label"
                  />
                  <EntityOption
                    label="Agent Name"
                    value={stringifyNA(`${contact?.firstName} ${contact?.lastName}`)}
                    type="uppercase-label"
                  />
                  <EntityOption label="Email" value={stringifyNA(contact?.email)} type="uppercase-label" />
                  <EntityOption label="Phone #1" value={stringifyNA(contact?.phone)} type="uppercase-label" />
                </Grid.Layout>
              </InformationSection>
            </Grid.Layout>
          </Grid.Layout>
        </AsyncContent>
      </Scrollable>
    </Modal>
  );
};
