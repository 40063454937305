import gql from 'graphql-tag';

export const QUERY_EVENT = gql`
  query QueryEvent($event: EventResolverInput!) {
    eventResolverQuery(event: $event) {
      success
      inner
    }
  }
`;

export enum EventTypes {
  GET_OFFER = 'getOffer',
}
