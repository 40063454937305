import { css } from '@emotion/core';
import { Theme } from './types';

export const globalStyles = (theme: Theme) => css`
  html {
    -webkit-font-smoothing: antialiased;
    font-size: 62.5% !important;
  }

  body {
    font-family: 'Poppins', sans-serif !important;
    background-color: #f4f5f6;
    overflow-x: hidden;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  ul,
  ol {
    padding: 0;
    list-style: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  input,
  textarea,
  button {
    border: none;
    outline: none;
    padding: 0;
  }
`;
