import React from 'react';
import styled from '@emotion/styled';

import { hasError } from '@/shared/utils';
import { MetaType } from '@/shared/types';

/* TODO: USE THEME FOR THE COLOR/FONTS */

const Root = styled.div`
  position: relative;
`;

const Container = styled.div``;

const Label = styled.div`
  line-height: 1.6rem;
  margin-bottom: 3px;
  font-size: 1.2rem;
  color: #7d828c;
  letter-spacing: 0.5px;
`;

const Note = styled.div`
  margin-top: 3px;
  font-size: 1rem;
  font-weight: 500;
  color: #a3afb7;
`;

const Error = styled.div`
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  color: #fe4b3d;
`;

const Field = styled.div<{ error: boolean }>`
  position: relative;

  .ant-input,
  .ant-select-selection {
    border-color: ${({ error }) => error && '#fe4b3d'};
  }
`;

type InputFieldProps = {
  children?: React.ReactChild;
  label?: React.ReactNode;
  note?: string;
  stretch?: boolean;
  hideErrorLabel?: boolean;
  direction?: 'row' | 'column';
  meta?: MetaType;
};

export const FormField = ({ meta = {}, label, note, children, hideErrorLabel, ...rest }: InputFieldProps) => {
  const anyError = hasError(meta);

  return (
    <Root {...rest}>
      <Container>
        {label && <Label>{label}</Label>}
        <Field error={anyError}>{children}</Field>
        {note && <Note>{note}</Note>}
      </Container>
      {anyError && !hideErrorLabel && <Error role="alert">{meta.error || meta.submitError}</Error>}
    </Root>
  );
};
