import React from 'react';
import { Modal } from 'antd';

import { useModalsState } from '@/providers';

import { formatVirtualTourSrc } from 'livo-shared/src';

type VirtualTourModalProps = {
  visible?: boolean;
  virtualTour?: string;
};

export const VirtualTourModal = ({ visible, virtualTour = '' }: VirtualTourModalProps) => {
  const { virtualTourModal } = useModalsState();
  const virtualTourSrc = formatVirtualTourSrc(virtualTour);

  const videoHeight = 350;

  return (
    <Modal
      className="virtual-tour-modal"
      visible={visible}
      onCancel={virtualTourModal.close}
      footer={null}
      maskClosable={false}
      style={{ height: videoHeight }}
      width={600}
      centered
    >
      <iframe width="100%" height={videoHeight} src={virtualTourSrc} frameBorder="0" title="Virtual tour" />
    </Modal>
  );
};
