import styled from '@/theme/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  padding: 20px;

  > * {
    padding: 0 !important;
  }
`;

export const AppartmentProperties = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const AppartmentOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    display: grid;
    grid-gap: 20px;
  }
`;

export const PropertyType = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 25px;
  color: #929ea8;
`;

export const PropertyName = styled.span`
  text-transform: uppercase;
  color: ${props => props.theme.vars.black};
  font-size: 18px;
  line-height: 27px;
`;

export const OfferSubTitle = styled.span`
  @media (max-width: 992px) {
    text-align: center;
    width: 100%;
  }
`;

export const OfferTitle = styled.p<{ isPrimaryColor?: boolean }>`
  color: ${props => (props.isPrimaryColor ? props.theme.vars.primaryColor : props.theme.vars.black)};
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    font-weight: 500;
    width: 100%;
  }
`;

export const OfferPropertyInfo = styled.div`
  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    padding: 0 1.5rem;
  }
`;

export const OfferAuctionContainer = styled.div`
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    padding: 0 1.5rem 2rem;
  }
`;

export const AnnouncingTitle = styled.div`
  color: ${({ theme }) => theme.vars.primaryColor};
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.78px;
  line-height: 32px;
  text-align: center;
`;
