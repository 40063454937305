import React from 'react';
import { styled } from '@/theme';
import { Icon } from 'antd';
import Maybe from 'graphql/tsutils/Maybe';

const EntityOptionTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  & :not(:last-child) {
    margin-right: 4px;
  }
`;

const EntityOptionIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 20px;
`;

const EntitySemiboldOptionLabel = styled.div`
  color: #ababab;
  margin-right: 10px;
  line-height: 14px;
  font-weight: 400;
`;

const EntitySemiboldOptionValue = styled.div<{ selected: boolean }>`
  color: ${props => (props.selected ? props.theme.vars.primaryColor : '#323c47')};
  line-height: 14px;
  font-weight: 400;
`;

export const EntityUppercaseOptionLabel = styled.span`
  line-height: 16px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #939393;
  font-weight: 500;
`;

export const EntityUppercaseOptionValue = styled.span<{ selected: boolean }>`
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  color: ${props => (props.selected ? props.theme.vars.primaryColor : '#323c47')};
  text-transform: uppercase;
`;

type EntityOptionProps = {
  type?: 'semibold' | 'uppercase' | 'uppercase-label';
  fragmented?: boolean;
  selected?: boolean;
  value?: React.ReactNode;
  label?: Maybe<string>;
  icon?: React.FunctionComponent;
};

export const EntityOption: React.FC<EntityOptionProps> = ({
  label,
  value,
  icon,
  type = 'semibold',
  fragmented = false,
  selected = false,
}) => {
  const option = React.useMemo(() => {
    switch (type) {
      case 'semibold':
        return (
          <>
            {icon && <EntityOptionIcon component={icon} />}
            <EntitySemiboldOptionLabel>{label}</EntitySemiboldOptionLabel>
            <EntitySemiboldOptionValue selected={selected}>{value}</EntitySemiboldOptionValue>
          </>
        );

      case 'uppercase':
        return (
          <>
            {icon && <EntityOptionIcon component={icon} />}
            <EntityUppercaseOptionLabel>{label}</EntityUppercaseOptionLabel>
            <EntityUppercaseOptionValue selected={selected}>{value}</EntityUppercaseOptionValue>
          </>
        );

      case 'uppercase-label':
        return (
          <>
            {icon && <EntityOptionIcon component={icon} />}
            <EntityUppercaseOptionLabel>{label}</EntityUppercaseOptionLabel>
            <EntitySemiboldOptionValue selected={selected}>{value}</EntitySemiboldOptionValue>
          </>
        );

      default:
        return null;
    }
  }, [label, value, icon, type, selected]);

  return fragmented ? option : <EntityOptionTag>{option}</EntityOptionTag>;
};
