import React from 'react';
import { Input } from 'antd';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Heading, ButtonLink } from '@/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > * {
    margin-bottom: 8px;
  }
`;

const ButtonAdditionalCss = css`
  margin-left: auto;
`;

type SearchProps = {
  placeholder: string;
  query: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type ActionProps = {
  text: string;
  icon: Record<'type', string>;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  'data-e2e-id'?: string;
};

type TableHeader = {
  title: string;
  searchProps?: SearchProps;
  actionProps?: ActionProps;
};

export const TableHeader = ({ title, searchProps, actionProps }: TableHeader) => {
  const query = searchProps?.query;
  const placeholder = searchProps?.placeholder;
  const onChange = searchProps?.onChange;

  const text = actionProps?.text;
  const icon = actionProps?.icon;
  const onClick = actionProps?.onClick;

  return (
    <Container>
      <Heading type="h1">{title}</Heading>
      {searchProps && <Input value={query} placeholder={placeholder} onChange={onChange} />}
      {actionProps && (
        <ButtonLink
          data-e2e-id={actionProps['data-e2e-id']}
          onClick={onClick}
          text={text}
          iconProps={icon}
          css={ButtonAdditionalCss}
        />
      )}
    </Container>
  );
};
