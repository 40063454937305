import React from 'react';
import { styled } from '@/theme';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { formatAmount, stringifySingleUnitType } from '@/shared/utils';
import { Offer } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';
import { Info } from '@/components';
import { EntityOption } from '@/features';

const PropertyOfferDetailsContainer = styled.div`
  margin-bottom: 24px;
`;

const HouseOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e3e3e3;
`;

const PropertyOfferDetailsTag = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;

  > *:nth-child(odd) {
    text-align: end;
  }
`;

type PropertyOfferDetailsProps = {
  offer: Offer;
};

export const PropertyOfferDetails: React.FC<PropertyOfferDetailsProps> = ({ offer }) => {
  const securityDeposit = formatAmount(offer.securityDeposite);
  const termsOfLease = offer.termsOfLease;
  const rentAmount = formatAmount(offer.rentAmount);
  const moveinDate = offer.moveinDate;
  const secureNowAmount = formatAmount(offer.secureNowAmount);

  const singleUnit = offer.historyOfferSingleUnit;
  const squareFt = singleUnit?.squareFt;
  const bedrooms = singleUnit?.bedrooms;
  const bathrooms = singleUnit?.bathrooms;

  const houseMetaLabel = React.useMemo(() => {
    const squareFtLabel = squareFt ? `${squareFt} sqft` : `${NOT_AVALIABLE} sqft`;
    const bedroomsLabel = bedrooms ? `${bedrooms} ${pluralize('bed', bedrooms)}` : `${NOT_AVALIABLE} beds`;
    const bathroomsLabel = bathrooms ? `${bathrooms} ${pluralize('bath', bathrooms)}` : `${NOT_AVALIABLE} baths`;

    return `${squareFtLabel} | ${bedroomsLabel} | ${bathroomsLabel}`;
  }, [squareFt, bedrooms, bathrooms]);

  const termsOfLeaseLabel = termsOfLease ? `${termsOfLease} ${pluralize('month', termsOfLease)}` : NOT_AVALIABLE;
  const moveInDatelabel = moveinDate ? DateTime.fromISO(moveinDate).toFormat('DD') : NOT_AVALIABLE;
  const securityDepositLabel = securityDeposit ? `$${securityDeposit}` : NOT_AVALIABLE;
  const rentAmountLabel = rentAmount ? `$${rentAmount}/month` : NOT_AVALIABLE;

  const type = singleUnit?.type ? stringifySingleUnitType(singleUnit.type) : NOT_AVALIABLE;

  return (
    <PropertyOfferDetailsContainer>
      <HouseOptionsContainer>
        <EntityOption fragmented label={type} type="uppercase-label" value={houseMetaLabel} />
      </HouseOptionsContainer>
      <PropertyOfferDetailsTag>
        <EntityOption fragmented label="Rent amount" value={rentAmountLabel} />
        <EntityOption fragmented label="Preferred Terms" value={termsOfLeaseLabel} />
        <EntityOption fragmented label="Move-in Date" value={moveInDatelabel} />
        <EntityOption fragmented label="Security Deposit" value={securityDepositLabel} />
        <If condition={offer.secureNow === true}>
          <EntityOption
            fragmented
            label="SecureNow™"
            value={
              <>
                {secureNowAmount ? `$${secureNowAmount}` : NOT_AVALIABLE}
                <Info title="SecureNow™ is the quickest way to secure your new home. If you would like the bidding process to end and be awarded the lease, simply click SecureNow™ and start the leasing signing process." />
              </>
            }
          />
        </If>
      </PropertyOfferDetailsTag>
    </PropertyOfferDetailsContainer>
  );
};
