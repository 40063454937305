import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-btn {
    margin: 8px 0;
    padding: 0 32px;
    letter-spacing: 0;
    border-radius: 5px;
    font-weight: 500;
    span {
      vertical-align: middle;
    }
  }
`;

export const AccountFooter = ({
  resetForm,
  isPristine,
  loading,
}: {
  resetForm: () => void;
  isPristine: boolean;
  loading: boolean;
}) => {
  return (
    <Container>
      <Button size="large" disabled={isPristine || loading} type="primary" htmlType="submit">
        Save Changes
      </Button>
      <Button size="large" loading={loading} disabled={isPristine} onClick={resetForm}>
        Cancel
      </Button>
    </Container>
  );
};
