import React from 'react';
import pluralize from 'pluralize';
import { styled } from '@/theme';
import { DateTime, Duration } from 'luxon';
import { Icon } from 'antd';
import { TimeIcon } from '@/static/icons';
import { RowFlex } from '@/components';
import { useInterval } from '@/features/_hooks';

const TimerTag = styled(RowFlex)<{ outline?: boolean }>`
  display: inline-flex;
  font-size: 16px;
  height: 34px;
  font-weight: 500;
  border: ${props => (props.outline ? '1px solid #d0d7dd' : '0')};
  white-space: nowrap;
  padding: ${props => (props.outline ? '4px 8px' : '0')};
`;

const TimerText = styled.span<{ isRed: boolean }>`
  color: ${props => (props.isRed ? '#FF3100' : '#7d828c')};
`;

type TimerProps = {
  endsDate: DateTime | string;
  icon?: boolean;
  outline?: boolean;
};

export const Timer: React.FC<TimerProps> = ({ endsDate, icon = true, outline = true }) => {
  const date = typeof endsDate === 'string' ? DateTime.fromISO(endsDate) : endsDate;
  const [diff, setDiff]: [Duration, (arg: Duration) => void] = React.useState<Duration>(date.diffNow());

  useInterval(() => {
    setDiff(date.diffNow());
  }, 1000);

  return (
    <TimerTag align="center" outline={outline}>
      {icon && <Icon component={TimeIcon} />}
      <TimerText isRed={(diff as any).shiftTo('hours').hours < 24}>
        {(diff as any).toObject().milliseconds > 0
          ? diff.toFormat(`d 'd', h 'h', m 'm' left`)
          : diff.negate().toFormat(`d 'd', h 'h', m 'm' ago`)}
      </TimerText>
    </TimerTag>
  );
};

const TimerStylishContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 20px;
  justify-content: center;
`;

const TimerStylishDigit = styled.div<{ type: 'default' | 'red' | 'gray' }>`
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: center;

  > span:first-child {
    text-align: center;
    font-size: 40px;
    color: ${props => (props.type === 'default' ? 'black' : props.type === 'red' ? '#FF3100' : 'gray')};
  }

  > span:last-child {
    text-align: center;
  }
`;

const TimerStylishDots = styled.div`
  &:after {
    content: ':';
    font-size: 36px;
  }
`;

const TimerStylishMod = styled.div`
  align-self: center;
`;

export const TimerStylish: React.FC<TimerProps> = ({ endsDate }) => {
  const date = typeof endsDate === 'string' ? DateTime.fromISO(endsDate) : endsDate;
  const [diff, setDiff]: [Duration, (arg: Duration) => void] = React.useState<Duration>(date.diffNow());

  const timeRender = React.useMemo(() => {
    const timeLabel =
      (diff as any).toObject().milliseconds > 0
        ? {
            type: 'left',
            time: diff.toFormat(`d, h, m`).split(','),
          }
        : {
            type: 'ago',
            time: diff
              .negate()
              .toFormat(`d, h, m`)
              .split(','),
          };

    const digitType =
      timeLabel.type === 'left' ? ((diff as any).shiftTo('hours').hours < 24 && 'red') || 'default' : 'gray';

    return (
      <>
        <TimerStylishDigit type={digitType}>
          <span>{timeLabel.time[0]}</span>
          <span>{pluralize('day', +timeLabel.time[0] || 0)}</span>
        </TimerStylishDigit>
        <TimerStylishDots />
        <TimerStylishDigit type={digitType}>
          <span>{timeLabel.time[1]}</span>
          <span>{pluralize('hour', +timeLabel.time[1] || 0)}</span>
        </TimerStylishDigit>
        <TimerStylishDots />
        <TimerStylishDigit type={digitType}>
          <span>{timeLabel.time[2]}</span>
          <span>{pluralize('minute', +timeLabel.time[2] || 0)}</span>
        </TimerStylishDigit>
        {timeLabel.type === 'ago' && <TimerStylishMod>{timeLabel.type}</TimerStylishMod>}
      </>
    );
  }, [diff]);

  useInterval(() => {
    setDiff(date.diffNow());
  }, 1000);

  return <TimerStylishContainer>{timeRender}</TimerStylishContainer>;
};
