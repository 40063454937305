import React from 'react';
import { Modal } from 'antd';

import { useModalsState } from '@/providers';

type CalendarModalProps = {
  visible?: boolean;
  calendarLink?: string;
};

export const CalendarModal = ({ visible, calendarLink = '' }: CalendarModalProps) => {
  const { calendarModal } = useModalsState();

  const iframeHeight = 650;

  return (
    <Modal
      className="virtual-tour-modal"
      visible={visible}
      onCancel={calendarModal.close}
      footer={null}
      maskClosable={false}
      style={{ height: iframeHeight }}
      width={600}
      centered
    >
      <iframe width="100%" height={iframeHeight} src={calendarLink} frameBorder="0" title="calendly schedule" />
    </Modal>
  );
};
