import { styled } from '@/theme';

export const Container = styled.div``;

export const NameAndFeaturesContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  grid-column-gap: 24px;
  margin-bottom: 13px;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.vars.black};
  font-family: Poppins;
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 500;
`;

export const Description = styled.div`
  margin-bottom: 17px;
  color: #919daa;
  font-family: Poppins;
  font-size: 1.3rem;
  line-height: 2rem;
`;

export const InformationList = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
`;

export const InformationListItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`;

export const InformationListItemLabel = styled.div`
  color: #939ea7;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
`;

export const InformationListItemValue = styled.div`
  color: #323c47;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

export const Title = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.vars.black};
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

export const SingleUnitMediaWrapper = styled.div`
  height: 133px;
  object-fit: cover;
  margin-bottom: 16px;
  border-radius: 6px;
  margin-right: 10px;
`;

export const SingleUnitMediaImage = styled.img`
  height: 133px;
  object-fit: cover;
  margin-bottom: 16px;
  border-radius: 6px;
`;
