import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from 'react-apollo';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';
import { Button } from 'antd';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { CustomCard, Loader, MediaGallery, Tag, Link } from '@/components';
import { PropertyAmenities, PropertyDetails, PropertyOfferDetails } from '@/features';
import { LeftTime } from '@/features/LeftTime';
import { Offer, File, SingleUnit, Bid } from '@/shared/types/graphql';
import { OFFER_COMBINED_STATUSES, OFFER_STATUSES } from '@/shared/constants';
import { useCurrentUser, usePermissions, useOfferBidCombinedStatus, useMutationSafe } from '@/features/_hooks';
import { RenterContext } from '@/providers';
import { PropertyBidView } from './PropertyBidView';

import { OFFER_QUERY, USER_UPDATE_MUTATION } from './queries';
import { PropertyHeading, Container, Info, ActionsContainer, MediaContainer, TimeAndOffersContainer } from './styled';
import { storeVisitedOffer } from '@/shared/utils/visitedUnits';
import { getSingleRentalBiddingUrl, AppRoutes } from '@/shared/constants/appRoutes';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';

type RedirectParams =
  | {
      offerId?: string;
      secureNow?: boolean;
      pathname?: string;
    }
  | undefined;

type PropertyActionsProps = {
  secureNow: boolean;
  onBeatOffer: () => void;
  onSecureNow: () => void;
};

enum PropertyPageState {
  PROPERTY = 'property',
  OFFER = 'offer',
  SECURE_NOW = 'secure_now',
}

const PropertyActions = ({ secureNow, onBeatOffer, onSecureNow }: PropertyActionsProps) => {
  return (
    <ActionsContainer>
      {secureNow && (
        <Button type="primary" size="large" onClick={onSecureNow}>
          SecureNow™
        </Button>
      )}
      <Button type="primary" size="large" onClick={onBeatOffer}>
        Make an Offer
      </Button>
    </ActionsContainer>
  );
};

function TimeAndOffers(props: { endsIn: any; bidsCount: number }) {
  const { endsIn, bidsCount } = props;
  return (
    <span style={{ position: 'absolute', top: '100px', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <TimeAndOffersContainer>
        <LeftTime size="sm" endsDate={DateTime.fromISO(endsIn)} withIcon={false} short={true} />
        <span style={{ padding: '0 6px' }}>-</span>
        <span style={{ fontSize: 12 }}>{`${bidsCount} ${pluralize('offer', bidsCount)}`}</span>
      </TimeAndOffersContainer>
    </span>
  );
}

const ScrollAnchor = styled.div<{ top?: boolean }>`
  position: absolute;
  top: ${props => (props.top ? '-1000px' : '-28px')};
`;

export const Property = () => {
  const { isRenter } = usePermissions();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { id: offerId } = useParams();
  const { user } = useCurrentUser();
  const { isAuthorized } = useAuth();
  const { onLogin, anchorMenuData } = React.useContext(RenterContext);
  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });

  const [pageState, setPageState] = React.useState<PropertyPageState>(PropertyPageState.PROPERTY);

  const userId = user?.id;

  if (history.location?.search.length !== 0) {
    history.replace({ search: '' });
  }

  const { data: offerResponse, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_QUERY, {
    variables: { id: offerId },
    skip: !offerId,
    fetchPolicy: 'network-only',
  });

  const offer = offerResponse?.offer;
  const singleUnit = offer?.historyOfferSingleUnit;

  const [userUpdate] = useMutationSafe(USER_UPDATE_MUTATION);

  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items || [];
  const singleUnitMediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];

  const communityHasMedia = singleUnit?.community?.communityHasMedia?.items || [];
  const communityMediaList = React.useMemo(() => communityHasMedia.map(cm => cm?.media?.file), [
    communityHasMedia,
  ]) as File[];

  const mediaList = [...singleUnitMediaList, ...communityMediaList];
  const formattedMediaList = mediaList.map((item: any) => {
    const rawImgItem = { ...item };
    const rawImgUrl = rawImgItem?.downloadUrl;
    const params = rawImgUrl?.substring(rawImgUrl.indexOf('security'), rawImgUrl.length);
    const imageWidth = 425;

    const compressedImgUrl = `https://cdn.filestackcontent.com/resize=w:${imageWidth}/compress/${params}`;
    return { ...rawImgItem, compressedImgUrl };
  });

  const virtualTour = singleUnit?.virtualTour;
  const calendarLink = offer?.calendarLink;

  const bidsList = offer?.bid?.items || [];
  const userBidIndex = bidsList.findIndex(bid => bid?.renter?.user?.id === user?.id);
  const userBid = bidsList[userBidIndex];
  const isOfferLive = offer?.status === OFFER_STATUSES.live;

  const { kind: combinedStatusKind } = useOfferBidCombinedStatus(offer);

  const onBeatOffer = React.useCallback(() => {
    setPageState(PropertyPageState.OFFER);
    anchorMenuData?.setAnchorMenuVisible(false);
  }, [anchorMenuData]);
  const onSecureNow = React.useCallback(() => {
    setPageState(PropertyPageState.SECURE_NOW);
    anchorMenuData?.setAnchorMenuVisible(false);
  }, [anchorMenuData]);
  const onCancel = React.useCallback(() => {
    setPageState(PropertyPageState.PROPERTY);
    anchorMenuData?.setAnchorMenuVisible(true);
  }, [anchorMenuData]);

  React.useEffect(() => {
    anchorMenuData?.setAnchorMenuVisible(true);

    const redirect: RedirectParams = store.get(redirectType.afterLogin);

    const redirectOfferId = redirect?.offerId || '';
    const secureNow = redirect?.secureNow || false;
    const isUserHasBidAlready = (bid: Bid) => bid?.renter?.user?.id === userId;

    if (offer?.bid?.items?.some(isUserHasBidAlready) || !user) {
      onCancel();
    } else {
      if (redirectOfferId && isAuthorized) {
        secureNow ? onSecureNow() : onBeatOffer();
      }
    }
    redirectOfferId && store.drop(redirectType.afterLogin);

    if (singleUnit) {
      if (isRenter && isAuthorized && user?.email && offerId) {
        userUpdate({
          variables: {
            data: {
              renter: {
                update: {
                  visitedOffers: {
                    connect: [{ id: offerId }],
                  },
                },
              },
            },
            filter: {
              email: user.email,
            },
          },
        });
      } else if (!isAuthorized && offerId) {
        storeVisitedOffer(offerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerResponse, offerId, isRenter, isAuthorized, offer]);

  if (offerLoading) {
    return <Loader stretch />;
  }

  if (combinedStatusKind === OFFER_COMBINED_STATUSES.none || combinedStatusKind === OFFER_COMBINED_STATUSES.draft) {
    return <Redirect to={AppRoutes.RENTER_NOT_FOUND_PAGE} />;
  }

  const endsIn = offer?.endsIn;
  const bidsCount = offer?.bid?.count || 0;

  const renderContent = () => {
    switch (pageState) {
      case PropertyPageState.PROPERTY:
        return (
          <CustomCard overflowBody="hidden" padding="none">
            <Container onTouchEnd={() => anchorMenuData?.setLinkState('none')}>
              <MediaContainer>
                <div style={{ position: 'relative' }}>
                  <ScrollAnchor top ref={anchorMenuData?.overviewRef} />
                </div>
                <MediaGallery
                  mediaList={formattedMediaList}
                  virtualTour={virtualTour}
                  height={{ xs: '150px' }}
                  calendarLink={calendarLink}
                  contact={offer?.contact}
                  loading={offerLoading}
                />
                {isOfferLive && <TimeAndOffers endsIn={endsIn} bidsCount={bidsCount} />}
              </MediaContainer>
              <Info>
                <PropertyHeading>
                  <span>{singleUnit?.name}</span>
                  {offer?.numberId && <span>{`Livo Offer #${offer.numberId}`}</span>}
                </PropertyHeading>
                <Choose>
                  <When condition={isOfferLive}>
                    <PropertyOfferDetails offer={offer as Offer} />
                    <Choose>
                      <When condition={!isAuthorized}>
                        <PropertyActions
                          secureNow={!!offer?.secureNow}
                          onBeatOffer={() => onLogin && onLogin({ offerId })}
                          onSecureNow={() => onLogin && onLogin({ offerId, secureNow: true })}
                        />
                      </When>
                      <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES.rejected}>
                        <Tag color="red" type="raised">
                          Your offer was rejected
                        </Tag>
                      </When>
                      <Otherwise>
                        <Choose>
                          <When condition={!!userBid}>
                            <Link to={getSingleRentalBiddingUrl(offerId)}>View LivoSecure Transaction</Link>
                          </When>
                          <Otherwise>
                            <PropertyActions
                              secureNow={!!offer?.secureNow}
                              onBeatOffer={onBeatOffer}
                              onSecureNow={onSecureNow}
                            />
                          </Otherwise>
                        </Choose>
                      </Otherwise>
                    </Choose>
                  </When>
                  <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES.leased}>
                    <Tag color="red" type="raised">
                      Property Leased
                    </Tag>
                  </When>
                  <Otherwise>
                    <Choose>
                      <When
                        condition={
                          combinedStatusKind === OFFER_COMBINED_STATUSES['you-secured'] ||
                          combinedStatusKind === OFFER_COMBINED_STATUSES['you-won']
                        }
                      >
                        <Link to={getSingleRentalBiddingUrl(offerId)}>View Checklist</Link>
                      </When>
                      <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES['you-accept']}>
                        <Link to={getSingleRentalBiddingUrl(offerId)}>Accept Offer</Link>
                      </When>
                      <When condition={combinedStatusKind === OFFER_COMBINED_STATUSES.pending}>
                        <Tag color="orange" type="raised">
                          Auction Upcoming
                        </Tag>
                      </When>
                      <Otherwise>
                        <Tag color="red" type="raised">
                          Auction Ended
                        </Tag>
                      </Otherwise>
                    </Choose>
                  </Otherwise>
                </Choose>
                <div style={{ position: 'relative' }} role="separator" className="ant-divider ant-divider-horizontal">
                  <ScrollAnchor ref={anchorMenuData?.detailsRef} />
                </div>
                <PropertyDetails singleUnit={singleUnit} />
                <div style={{ position: 'relative' }} role="separator" className="ant-divider ant-divider-horizontal">
                  <ScrollAnchor ref={anchorMenuData?.amenitiesRef} />
                </div>
                <PropertyAmenities singleUnit={singleUnit as SingleUnit} />
              </Info>
            </Container>
          </CustomCard>
        );
      case PropertyPageState.OFFER:
        return <PropertyBidView offer={offer as Offer} onCancel={onCancel} />;
      case PropertyPageState.SECURE_NOW:
        return <PropertyBidView secureNow offer={offer as Offer} onCancel={onCancel} />;
    }
  };

  return renderContent();
};
