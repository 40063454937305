import React, { useState } from 'react';
import {
  TimelineItemSquare,
  TimelineOthersItemSquare,
  TimelineItemSubTitle,
  TimelineItemTitle,
  TimelineOthersItemTitle,
  TimelineContent,
  TimelineWrapperHor,
  TimelineItemCirclesWrapperHor,
  TimelineItemLineHor,
  TimelineItemCircleLineHor,
  TimelineContentLineHor,
  Size,
} from './styled';
import { isNil } from 'ramda';

const formPlace = (place: number) => {
  switch (place) {
    case 1:
      return `${place}st`;
    case 2:
      return `${place}nd`;
    case 3:
      return `${place}rd`;
    default:
      return `${place}th`;
  }
};

type TimeLineItem = {
  id: string | number;
  kind?: undefined;
  title?: string;
  subTitle?: string;
  actualIndex?: number;
  beforeText?: string;
  content?: React.ReactNode;
};

type ActiveTimelineState = {
  active?: string | number;
  defaultActive?: string | number;
  onActiveClick?: (activeId: string | number) => void;
};

type TimeLineProps = ActiveTimelineState & {
  items: TimeLineItem[];
  size?: Size;
  showContent?: boolean;
  inReverse?: boolean;
};

const useActiveItemState = (props: ActiveTimelineState): [string | number, (id: string | number) => void] => {
  const [active, setActive] = useState<string | number>(props.defaultActive || '');

  if (!isNil(props.active)) {
    return [
      props.active,
      props.onActiveClick ||
        (() => {
          return;
        }),
    ];
  } else {
    return [active, setActive];
  }
};

const useTimelineRefEffects = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [offset, setOffset] = React.useState(document.body.offsetWidth || 0);

  const onResize = React.useCallback(() => {
    setOffset(document.body.offsetWidth || 0);
  }, [setOffset]);

  React.useLayoutEffect(
    function adjustHeightAdHoc() {
      if (ref.current) {
        const { width } = ref.current.getBoundingClientRect();
        ref.current.style.height = `${width}px`;
        window.addEventListener('resize', onResize);
      }
      return () => {
        window.removeEventListener('resize', onResize);
      };
    },
    [ref, offset, onResize],
  );

  return ref;
};

function TimelineItem({
  item,
  index,
  size,
  activeId,
  showContent,
  setActive,
}: {
  item: TimeLineItem;
  index: number;
  size: Size;
  showContent: boolean;
  activeId: React.ReactText;
  setActive: any;
}) {
  const isYou = item?.title?.toLowerCase() === 'you';
  const ref = useTimelineRefEffects();

  return (
    <TimelineItemSquare
      ref={ref}
      size={size}
      active={showContent ? item.id === activeId : isYou}
      onClick={() => {
        showContent && setActive(item.id);
      }}
    >
      {!!item?.title && <TimelineItemTitle size={size}>{item?.title}</TimelineItemTitle>}

      {isYou && (
        <TimelineItemSubTitle size={size}>
          {formPlace((typeof item?.actualIndex === 'number' ? item?.actualIndex : index) + 1)}
        </TimelineItemSubTitle>
      )}
      {/* {item?.subTitle && <TimelineItemSubTitle size={size}>{item.subTitle}</TimelineItemSubTitle>} */}

      {showContent && activeId === item.id && <TimelineItemLineHor index={index} size={size} />}
    </TimelineItemSquare>
  );
}

function TimelineEmptyItem({ item, size }: { item: OthersItem | EmptyItem; size: Size }) {
  const ref = useTimelineRefEffects();
  return (
    <TimelineOthersItemSquare ref={ref} size={size} active={false}>
      <TimelineOthersItemTitle size={size}>
        {item?.kind === 'others' ? `${item.value} others` : `${item.value}`}
      </TimelineOthersItemTitle>
    </TimelineOthersItemSquare>
  );
}

type OthersItem = {
  kind: 'others';
  value: number;
};

type EmptyItem = {
  kind: 'empty';
  value: number;
};

type TimeLineItemWithTheOthers = TimeLineItem | OthersItem | EmptyItem;

export const TimeLineSimple: React.FC<TimeLineProps> = ({
  size = 'md',
  items,
  showContent = true,
  inReverse = false,
  ...rest
}) => {
  const [activeId, setActive] = useActiveItemState(rest);

  /**
   *  Some mocks to help test the implementation
   */

  // {
  //   const youItemIndex = items.findIndex(it => it.title?.toLowerCase() === 'you');
  //   const youItem = items[youItemIndex];

  //   // items.splice(0, items.length);
  //   // items.push(
  //   //   { id: 'x1', title: 'x1' },
  //   //   { id: 'x2', title: 'x2' },
  //   //   { id: 'x3', title: 'x3' },
  //   //   { id: 'x4', title: 'x4' },
  //   //   { id: 'x5', title: 'x5' },
  //   //   { id: 'x6', title: 'x6' },
  //   //   youItem,
  //   //   // { id: 'x7', title: 'x7' },
  //   //   // { id: 'x8', title: 'x8' },
  //   //   // { id: 'x9', title: 'x9' },
  //   //   // { id: 'x10', title: 'x10' },
  //   // );

  //   items.push(...items);
  // }

  let presentableItems: TimeLineItemWithTheOthers[] = React.useMemo(() => {
    const youItemIndex = items.findIndex(it => it.title?.toLowerCase() === 'you');
    const youItem = items[youItemIndex];
    const lastItemIndex = items.length - 1;
    const lastItem = items[lastItemIndex];

    const firstItem = items[0];
    const secondItem = items[1];
    const third = items[2];
    const othersItem: OthersItem = { kind: 'others', value: items.length - 3 };

    switch (true) {
      case items.length <= 4: {
        return items.length < 4
          ? new Array(4)
              .fill(null)
              .map((_, index) => (items[index] ? items[index] : { kind: 'empty', value: index + 1 }))
          : items;
      }

      // more than 4 items:

      // - but you are first
      case youItemIndex === 0: {
        return [youItem, secondItem, third, othersItem];
      }
      // - but you are second
      case youItemIndex === 1: {
        return [firstItem, youItem, third, othersItem];
      }
      // - but you are third
      case youItemIndex === 2: {
        return [firstItem, secondItem, youItem, othersItem];
      }
      // - but you are last
      case youItemIndex === lastItemIndex: {
        return [firstItem, secondItem, othersItem, youItem];
      }

      // - but you are just above the last one,
      case youItemIndex === lastItemIndex - 1: {
        const afterHeadBeforeYou: OthersItem = { kind: 'others', value: youItemIndex - 1 };
        return [firstItem, afterHeadBeforeYou, youItem, lastItem];
      }

      // - but you are somewhere in the middle
      default: {
        const afterHeadBeforeYou: OthersItem = { kind: 'others', value: youItemIndex };
        const afterYouAndTail: OthersItem = { kind: 'others', value: items.length - youItemIndex - 1 };
        return [firstItem, afterHeadBeforeYou, youItem, afterYouAndTail];
      }
    }
  }, [items]);

  if (inReverse) {
    presentableItems = presentableItems.reverse();
  }

  return (
    <TimelineWrapperHor size={size} withContent={showContent}>
      <TimelineItemCirclesWrapperHor>
        <TimelineItemCircleLineHor />
        {presentableItems.map((item, index) => {
          if (item?.kind === 'others' || item?.kind === 'empty') {
            return <TimelineEmptyItem key={`${index}-others`} item={item} size={size} />;
          } else {
            return (
              <TimelineItem
                key={item?.title || ''}
                item={item}
                index={index}
                activeId={activeId}
                setActive={setActive}
                size={size}
                showContent={showContent}
              />
            );
          }
        })}
      </TimelineItemCirclesWrapperHor>
      {showContent && (
        <TimelineContent size={size}>
          {items.find(item => item.id === activeId)?.content}
          <TimelineContentLineHor size={size} />
        </TimelineContent>
      )}
    </TimelineWrapperHor>
  );
};
