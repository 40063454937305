import React, { useEffect } from 'react';
import styled from '@/theme/styled';
import { useAuth } from '8base-react-sdk';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'antd';
import { css } from '@emotion/core';
import { toast } from 'react-toastify';

import { CloseIcon } from '@/static/icons';
import { ButtonLink } from '@/components';
import { SendEmailVerificationMutation } from '@/shared/graphql';
import { MutationSendEmailVerificationArgs, SuccessResponse } from '@/shared/types/graphql';
import { AppRoutes } from '@/shared/constants/appRoutes';
import { useMutationSafe } from '@/features/_hooks';

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const buttonLinkStyles = css`
  color: rgba(50, 60, 71, 0.45) !important;
  &:hover {
    color: rgba(50, 60, 71, 0.75) !important;
  }
`;

const ModalBodyText = styled.div`
  color: #85919d;
  font-family: Poppins;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  max-width: 270px;
`;

const ModalBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 325px;
`;

const ModalBodyEmail = styled.div`
  margin: 16px 0 13px 0;
  color: ${props => props.theme.vars.primaryColor};
  font-family: Poppins;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.5rem;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    font-size: 1.6rem;
    line-height: 2.4rem;
    word-break: break-word;
  }
`;

export const Confirmation = () => {
  const history = useHistory();

  const auth = useAuth();
  const { email, isEmailVerified } = auth.authClient.getState();

  const trySessionUpdateAsync = React.useCallback(async () => {
    try {
      const session = await auth.authClient.checkSession();
      await auth.authClient.setState({ isEmailVerified: session.isEmailVerified });
      return await auth.authClient.getState();
    } catch (e) {}

    return null;
  }, [auth.authClient]);

  const handeleLeaveConfirmationPage = React.useCallback(async () => {
    const newAuthState = await trySessionUpdateAsync();
    if (newAuthState?.isEmailVerified) {
      history.replace(AppRoutes.RENTER_OFFERS);
    } else {
      await auth.authClient.logout();
    }
  }, [auth.authClient, trySessionUpdateAsync, history]);

  const [sendEmailVerification, { loading: sendLoading }] = useMutationSafe<
    { sendEmailVerification: SuccessResponse },
    MutationSendEmailVerificationArgs
  >(SendEmailVerificationMutation);

  const onSendEmailVerification = React.useCallback(async () => {
    const { data } = await sendEmailVerification({ variables: { email } });
    const success = data?.sendEmailVerification?.success;

    success ? toast.success('Successfully sent.') : toast.error("Couldn't send. Try again later.");
  }, [email, sendEmailVerification]);

  const sendInitialVerificationEmail = () => {
    if (!email) {
      history.replace('/auth');
    } else if (isEmailVerified) {
      history.replace(AppRoutes.RENTER_OFFERS);
    } else if (email && !isEmailVerified) {
      onSendEmailVerification();
    }
  };

  useEffect(() => {
    sendInitialVerificationEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Card
        title="Email Confirmation"
        extra={<ButtonLink css={buttonLinkStyles} icon={<CloseIcon />} onClick={handeleLeaveConfirmationPage} />}
        style={{ width: '100%', height: '100%' }}
        bodyStyle={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 60px)' }}
      >
        <ModalBodyContent>
          <ModalBodyText>We sent a confirmation email to</ModalBodyText>
          <ModalBodyEmail>{email}</ModalBodyEmail>
          <ModalBodyText>Click on the link inside to confirm your account!</ModalBodyText>
          <Button
            size="large"
            type="primary"
            style={{ marginTop: '30px' }}
            onClick={onSendEmailVerification}
            disabled={sendLoading}
            loading={sendLoading}
          >
            Resend confirmation email
          </Button>
        </ModalBodyContent>
      </Card>
    </Container>
  );
};
