import React from 'react';
import { RenterLayout } from '@/pages/_layouts/RenterLayout';
import Offers from './components/Offers';

const LiveOffers = () => {
  return (
    <RenterLayout>
      <Offers />
    </RenterLayout>
  );
};

export default LiveOffers;
