import React from 'react';
import { Account } from './Account';
import { RenterLayout } from '../_layouts/RenterLayout';

export default function AccountPage() {
  return (
    <RenterLayout>
      <Account />
    </RenterLayout>
  );
}
