import { styled } from '@/theme';

export const Resume = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 25px;
  }

  @media (max-width: ${({ theme }) => theme.vars.breakpoints.sm.max}) {
    display: grid;
    gap: 15px;
  }
`;

export const UppercaseLabel = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: #929ea8;
  font-size: 12px;
`;

export const SectionHeading = styled.h2`
  color: #323c47;
  font-weight: 500 !important;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const SectionInfo = styled.section`
  display: grid;
  grid-template-columns: none;
  gap: 20px;

  > * {
    display: grid !important;
    grid-template-columns: auto;
    grid-gap: 8px;

    > * {
      line-height: 1.4em;
    }

    @media (min-width: ${({ theme }) => theme.vars.breakpoints.md.min}) {
      grid-template-columns: auto auto;
      justify-content: start;
    }
  }
`;

export const SectionInfoHalf = styled.div`
  display: grid;
  align-self: flex-start;
  gap: 20px;
`;

export const PropertyLogo = styled.img`
  height: 30px;
  max-width: 150px;
  object-fit: cover;
`;

export const Description = styled.div`
  margin-bottom: 25px;
  color: #919daa;
  font-family: Poppins;
  font-size: 1.3rem;
  line-height: 1.5;
  word-break: break-word;

  ol,
  ul {
    padding-left: 10px;
    list-style: revert;
  }
`;
