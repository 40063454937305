import React from 'react';
import ReactDOM from 'react-dom';
import 'moment-timezone';
import { App } from './App';
import { Settings } from 'luxon';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

Settings.defaultLocale = 'en-us';

ReactDOM.render(<App />, document.getElementById('root'));

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });

    let refreshing = false;

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) {
        return;
      }

      refreshing = true;
      window.location.reload();
    });
  },
});
