import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as R from 'ramda';

import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';
import { omit } from 'ramda';

const PrefixContainer = styled.div<{ prefixPadding?: number; stretch?: boolean; onlyArrows?: boolean; error?: string }>`
  position: relative;

  ${({ prefixPadding }) =>
    prefixPadding &&
    css`
      .ant-input-number {
        .ant-input-number-input {
          padding-left: ${prefixPadding}px;
        }
      }
    `}

  ${({ onlyArrows }) =>
    onlyArrows &&
    css`
      .ant-input-number {
        .ant-input-number-input {
          pointer-events: none;
        }

        .ant-input-number-handler-wrap {
          opacity: 1;
        }
      }
    `}

  ${({ stretch }) =>
    stretch &&
    css`
      .ant-input-number {
        width: 100%;
      }
    `}

  ${({ error }) => {
    return error
      ? css`
          .ant-input-number-input {
            box-shadow: 0 0 1.5px 1px red;
          }
        `
      : css`
          .ant-input-number-input {
            box-shadow: none;
          }
        `;
  }}
`;

const Prefix = styled.div`
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
  color: #999;
`;

type InputNumberFieldProps = InputNumberProps & {
  label?: React.ReactNode;
  note?: string;
  input: InputType;
  meta: MetaType;
  prefix?: string;
  prefixPadding?: number;
  stretch?: boolean;
  onlyArrows?: boolean;
  'data-e2e-id'?: string;
};

export const InputNumberField = ({
  label,
  note,
  input,
  meta,
  stretch,
  prefix,
  prefixPadding,
  onlyArrows,
  min,
  max,
  ...rest
}: InputNumberFieldProps) => {
  const { name, value, onChange, onBlur } = input;

  const onFocus = React.useCallback(
    (e?: React.FocusEvent<Element>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      if (onlyArrows && typeof e?.target?.blur === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        e.target.blur();
      }

      if (typeof input.onFocus === 'function') {
        return input.onFocus(e);
      }
    },
    [input, onlyArrows],
  );

  const getNewValue = (value?: number) => {
    if (R.isNil(value)) {
      return 0;
    }

    if (!R.isNil(max) && value > max) {
      return max;
    } else if (!R.isNil(min) && value < min) {
      return min;
    } else {
      return value;
    }
  };

  const handleChange = (value?: number) => {
    const newValue = getNewValue(value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    onChange(newValue);
  };

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <PrefixContainer
        prefixPadding={prefixPadding}
        stretch={stretch}
        onlyArrows={onlyArrows}
        {...(meta.dirty && meta.error && { error: meta.error })}
      >
        <InputNumber
          type="number"
          name={name}
          defaultValue={getNewValue(value)}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          {...omit(['data-e2e-id'], rest)}
        />
        {prefix && <Prefix>{prefix}</Prefix>}
      </PrefixContainer>
    </FormField>
  );
};
