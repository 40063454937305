type isDefinedProps = {
  value: any;
  isStrict: boolean;
};

export const isDefined = ({ value, isStrict }: isDefinedProps) => (isStrict ? value !== undefined : value != null);

export const hasError = (meta: Record<string, any> = {}) => {
  const { submitError, dirtySinceLastSubmit, error, touched } = meta;

  return (!!error || (!!submitError && !dirtySinceLastSubmit)) && !!touched;
};

export { getIconByMimetype, getDocumentTypeByMimetype } from './documentInfoByType';
export { getImageTypeByMimetype, getImageIconByMimetype } from './imageInfoByType';
export { createFilePreviewLink } from './createFilePreviewLink';
export { cropFileName } from './cropFileName';
export { stringifyAddress } from './stringifyAddress';
export { stringifyUserName } from './stringifyUserName';
export { stringifyDate } from './stringifyDate';
export { stringifyDateTime } from './stringifyDateTime';
export { stringifyPhone } from './stringifyPhone';
export { stringifySingleUnitType } from './stringifySingleUnitType';
export { stringifyCompanyType } from './stringifyCompanyType';
export { beautifyPhone } from './beautifyPhone';
export { getFullName } from './getFullName';
export { getInitials } from './getInitials';
export { stringifyNA } from './stringifyNA';
export { stringifyHref } from './stringifyHref';
export { normalizePhone } from './normalizePhone';
export { validateEntityDataByDraft } from './validateEntityDataByDraft';
export { formatDiffDate } from './formatDiffDate';
export { stringifyStateOfUSA } from './stringifyStateOfUSA';
export { stringifyAddressStreets } from './stringifyAddressStreets';
export { stringifyOfferEndsIn } from './stringifyOfferEndsIn';
export { stringifyCityState } from './stringifyCityState';
export { getCommunityAmenities } from './getCommunityAmenities';
export { getSingleUnitAmenities } from './getSingleUnitAmenities';
export { stringifyVirtualShowings } from './stringifyVirtualShowings';
export { parseSmartAddress } from './parseSmartAddress';
export { checkScrollTrackWidth } from './checkScrollTrackWidth';
export { parsePhone } from './parsePhone';
export { parseCommunityType } from './parseCommunityType';
export { parseSingleUnitType } from './parseSingleUnitType';
export { getUserBidIndex } from './getUserBidIndex';
export { stringifyRentAmount } from './stringifyRentAmount';
export { stringifyTermsOfLease } from './stringifyTermsOfLease';
export { checkOfferChecklistComplete } from './checkOfferChecklistComplete';
export { parseString } from './parseString';
export { parseNumber, parseNumberWithRound } from './parseNumber';
export { parseDate } from './parseDate';
export { stringifyIlsOrPms } from './stringifyIlsOrPms';
export { parseImportErrors } from './parseImportErrors';
export { stringToUppercase } from './stringToUppercase';
export { calculateBidRank } from './calculateBidRank';
export { formPlace } from './formPlace';
export { westernReportingXMLResponseHasNoErrors } from './westernReportingXMLResponseHasNoErrors';
export { stringifyEndsIn } from './stringifyEndsIn';
export { getTimeToApproveOffer } from './getTimeToApproveOffer';
export { getCompressedImageUrl } from './getCompressedImageUrl';
export { formatAmount } from './formatAmount';
export * from './roles';
