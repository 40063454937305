import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useModalsState } from '@/providers';
import { Offer, Bid, Maybe } from '@/shared/types/graphql';
import { formatAmount, stringifyHref, westernReportingXMLResponseHasNoErrors } from '@/shared/utils';
import { useQuery } from 'react-apollo';
import { BEGIN_WR_SCREENING, CHECK_SCREENING_LINK, WR_SCREENING_MUTATION } from './queries';
import { toast } from 'react-toastify';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import { useMutationSafe } from '@/features/_hooks';

const { Paragraph } = Typography;

type BidOfferActionsProps = {
  userBidPlace: number;
  activeItemId: number;
  offer?: Maybe<Offer>;
  bid: Bid;
  userBid?: Bid;
  onImprove?: () => void;
  onBeat?: (bid: Bid) => void;
  onMatch?: (bid: Bid) => void;
  onSecure?: () => void;
  onContact?: () => void;
};

export const BidOfferActions: React.FC<BidOfferActionsProps> = ({
  activeItemId,
  userBidPlace,
  offer,
  bid,
  userBid,
  onImprove,
  onMatch,
  onBeat,
  onSecure,
  onContact,
}) => {
  const { bidCreateModal, bidDeleteModal } = useModalsState();
  const history = useHistory();
  const { id: offerId } = useParams();

  const isOwner = userBidPlace === activeItemId;
  const applicationFormLink: any = bid?.offer?.applicationLink;
  const companyId = bid?.offer?.activeOfferSingleUnit?.company?.id || '';

  const companyWesternReporting = bid?.offer?.activeOfferSingleUnit?.company?.companyWesternReportingAccount;
  // eslint-disable-next-line
  const isUsingWesternReporting = companyWesternReporting?.isUsingWesternReporting || false;
  const [
    beginWesternReportingScreening,
    { data: beginWesternReportingScreeningResponse, loading: beginWesternReportingScreeningResponseLoading },
  ] = useMutationSafe(BEGIN_WR_SCREENING, {
    refetchQueries: ['checkWrScreeningLink'],
  });

  // eslint-disable-next-line
  const livoManagesScreening = bid?.offer?.livoManagesScreening;
  const thirdPartyScreeningLink = stringifyHref(bid?.offer?.screeningLink);

  const { data: screeningData } = useQuery(CHECK_SCREENING_LINK, {
    variables: {
      renterFilter: {
        renter: {
          id: {
            equals: bid?.renter?.id,
          },
        },
        company: {
          id: {
            equals: companyId,
          },
        },
      },
    },
  });

  const screeningLink = screeningData?.westernReportingScreeningsList?.items[0]?.screeningLink || undefined;

  const [westernReportingScreenings] = useMutationSafe(WR_SCREENING_MUTATION, {
    refetchQueries: ['CheckWrScreeningLink'],

    onCompleted: () => {
      toast.success('Done!');
    },
    onError: error => {
      toast.error(`Error: ${error}`);
    },
  });

  const westernReportingScreeningMutate = React.useCallback(
    async (link: string) => {
      if (westernReportingXMLResponseHasNoErrors(beginWesternReportingScreeningResponse)) {
        const rawScreeningFormResult = beginWesternReportingScreeningResponse?.beginWesternReportingScreening?.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(rawScreeningFormResult, 'text/xml');
        const rawScreeningId = xmlDoc?.querySelector('OrderId');
        const rawScreeningStatus = xmlDoc?.querySelector('OrderStatus');

        const renterId = bid?.renter?.id || '';
        const cleanScreeningId = rawScreeningId?.innerHTML || '';
        const cleanScreeningStatus = rawScreeningStatus?.innerHTML || '';

        await westernReportingScreenings({
          variables: {
            data: {
              status: cleanScreeningStatus,
              screeningID: cleanScreeningId,
              screeningLink: link,
              company: {
                connect: {
                  id: companyId,
                },
              },
              renter: {
                connect: {
                  id: renterId,
                },
              },
            },
          },
        });
      }
    },
    [beginWesternReportingScreeningResponse, bid, companyId, westernReportingScreenings],
  );

  const validateScreeningLink = React.useCallback(() => {
    if (westernReportingXMLResponseHasNoErrors(beginWesternReportingScreeningResponse)) {
      const rawScreeningFormResult = beginWesternReportingScreeningResponse?.beginWesternReportingScreening?.result;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(rawScreeningFormResult, 'text/xml');
      const rawScreeningFormLink = xmlDoc?.querySelector('ApplicantInterfaceURL');
      const cleanLink =
        rawScreeningFormLink?.innerHTML
          .replace('<![CDATA[', '')
          .replace(']]>', '')
          .split('amp;')
          .join('') || '';

      if (cleanLink.length === 0) {
        return toast.error('Something went wrong');
      } else {
        westernReportingScreeningMutate(cleanLink);
      }
    }
  }, [beginWesternReportingScreeningResponse, westernReportingScreeningMutate]);

  React.useEffect(() => {
    if (beginWesternReportingScreeningResponse && !beginWesternReportingScreeningResponseLoading) {
      validateScreeningLink();
    }
  }, [
    beginWesternReportingScreeningResponse,
    beginWesternReportingScreeningResponseLoading,
    validateScreeningLink,
    westernReportingScreeningMutate,
  ]);

  const sharedModalProps = React.useMemo(
    () => ({
      visible: true,
      options: {
        refetchQueries: ['SingleUnitOfferQuery'],
        awaitRefetchQueries: true,
      },
      incrementedBidAmount: offer?.incrementedBidAmount,
      securityDeposite: bid?.securityDeposite || offer?.securityDeposite,
      offerId: offer?.id,
      minAmount: offer?.rentAmount,
      maxAmount: offer?.secureNowAmount,
      minimumLeaseLength: offer?.minimumLeaseLength,
      maximumLeaseLength: offer?.maximumLeaseLength,
      userBidId: userBid?.id,
    }),
    [bid, offer, userBid],
  );

  const onImproveOffer = React.useCallback(
    onImprove
      ? onImprove
      : () =>
          bidCreateModal.setProps({
            ...sharedModalProps,
            securityDepositeEditable: offer?.securityDepositeEditable,
            rentAmount: bid?.rentAmount,
            termsOfLease: bid?.termsOfLease,
            offerMoveinDate: offer?.moveinDate,
            title: 'Improve My Offer',
          }),
    [bidCreateModal, offer, bid, sharedModalProps, onImprove],
  );

  const onBeatOffer = React.useCallback(
    onBeat
      ? () => onBeat(bid)
      : () =>
          bidCreateModal.setProps({
            ...sharedModalProps,
            securityDepositeEditable: offer?.securityDepositeEditable,
            rentAmount: (bid?.rentAmount || 0) + (offer?.incrementedBidAmount || 0),
            termsOfLease: bid?.termsOfLease,
            offerMoveinDate: offer?.moveinDate,
          }),
    [bidCreateModal, offer, bid, sharedModalProps, onBeat],
  );

  const onSecureNow = React.useCallback(
    onSecure
      ? onSecure
      : () =>
          bidCreateModal.setProps({
            ...sharedModalProps,
            securityDepositeEditable: offer?.securityDepositeEditable,
            rentAmount: offer?.secureNowAmount,
            termsOfLease: offer?.termsOfLease,
            secureNow: true,
            title: 'SecureNow™',
          }),
    [bidCreateModal, offer, sharedModalProps, onSecure],
  );

  const onMatchOffer = React.useCallback(
    onMatch
      ? () => onMatch(bid)
      : () =>
          bidCreateModal.setProps({
            ...sharedModalProps,
            securityDepositeEditable: offer?.securityDepositeEditable,
            rentAmount: bid?.rentAmount,
            termsOfLease: bid?.termsOfLease,
          }),
    [bidCreateModal, offer, bid, sharedModalProps, onMatch],
  );

  const onWithdrawOffer = React.useCallback(
    () =>
      bidDeleteModal.setProps({
        options: {
          refetchQueries: ['SingleUnitOfferQuery'],
          awaitRefetchQueries: true,
          onCompleted: () => {
            history.push(getSingleRentalUrl(offerId));
          },
        },
        visible: true,
        bidId: bid?.id,
        offerId: offer?.id,
      }),
    [bidDeleteModal, bid, offer, history, offerId],
  );

  // eslint-disable-next-line
  const onBeginScreening = React.useCallback(async () => {
    const renterId = bid?.renter?.id || '';
    const companyId = bid?.offer?.activeOfferSingleUnit?.company?.id || '';
    return await beginWesternReportingScreening({
      variables: {
        data: {
          renterId: renterId,
          companyId: companyId,
        },
      },
    });
  }, [beginWesternReportingScreening, bid]);

  // eslint-disable-next-line
  const onGetLink = React.useCallback(() => {
    window.open(stringifyHref(screeningLink));
  }, [screeningLink]);

  // eslint-disable-next-line
  const onGetThirdPartyLink = () => window.open(stringifyHref(thirdPartyScreeningLink));

  const onOpenApplicationForm = React.useCallback(() => {
    const href = stringifyHref(applicationFormLink);
    return window.open(href);
  }, [applicationFormLink]);

  return (
    <>
      {offer?.secureNow && (
        <Paragraph
          css={{ fontSize: '1.25rem', textAlign: 'center', marginTop: 15, fontWeight: 'bold', color: '#f37a22' }}
        >
          *Secure amount is set at ${formatAmount(offer.secureNowAmount)}
        </Paragraph>
      )}
      <Row gutter={[12, 12]}>
        <Col span={24}>
          {isOwner ? (
            <Button type="primary" block size="large" onClick={onImproveOffer}>
              Edit Your Offer
            </Button>
          ) : (
            <Button type="primary" block size="large" onClick={onBeatOffer}>
              Beat This Offer!
            </Button>
          )}
        </Col>
        {offer?.secureNow && (
          <Col xs={24}>
            <Button type="default" block size="large" onClick={onSecureNow}>
              SecureNow™!
            </Button>
          </Col>
        )}
        {!isOwner && (
          <Col xs={24}>
            <Button type="default" block size="large" onClick={onMatchOffer}>
              Match Offer
            </Button>
          </Col>
        )}
        {applicationFormLink && (
          <Col span={24}>
            <Button type="primary" block size="large" onClick={onOpenApplicationForm}>
              Start Application and Screening Process
            </Button>
          </Col>
        )}
        {/*<If condition={!!livoManagesScreening && isUsingWesternReporting}>*/}
        {/*  <Col span={24}>*/}
        {/*    {isUsingWesternReporting && !screeningLink ? (*/}
        {/*      <Button*/}
        {/*        loading={beginWesternReportingScreeningResponseLoading}*/}
        {/*        disabled={beginWesternReportingScreeningResponseLoading}*/}
        {/*        type="default"*/}
        {/*        block*/}
        {/*        size="large"*/}
        {/*        onClick={onBeginScreening}*/}
        {/*      >*/}
        {/*        Get Screening Link*/}
        {/*      </Button>*/}
        {/*    ) : (*/}
        {/*      <Button type="default" disabled={screeningDataLoading} block size="large" onClick={onGetLink}>*/}
        {/*        Begin Screening*/}
        {/*      </Button>*/}
        {/*    )}*/}
        {/*  </Col>*/}
        {/*</If>*/}
        {/*<If condition={!livoManagesScreening && thirdPartyScreeningLink !== NOT_AVALIABLE}>*/}
        {/*  <Col span={24}>*/}
        {/*    <Button type="default" disabled={screeningDataLoading} block size="large" onClick={onGetThirdPartyLink}>*/}
        {/*      Begin Screening*/}
        {/*    </Button>*/}
        {/*  </Col>*/}
        {/*</If>*/}
        <Col span={24}>
          <Button type="default" block size="large" onClick={onContact}>
            Contact Agent
          </Button>
        </Col>
        {isOwner && (
          <Col span={24}>
            <Button type="default" block size="large" onClick={onWithdrawOffer}>
              Withdraw Offer
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};
