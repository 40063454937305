import React from 'react';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import moment, { Moment } from 'moment';

import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';
import { omit } from 'ramda';

type DatePickerFieldProps = DatePickerProps & {
  label?: React.ReactNode;
  note?: string;
  input: InputType;
  meta: MetaType;
  width?: string | number;
  stretch?: boolean;
  displayFormat?: string;
  timezone?: string;
  'data-e2e-id'?: string;
};

export const DatePickerField = ({
  label,
  note,
  input,
  meta,
  width,
  stretch,
  displayFormat,
  showTime,
  timezone = moment.tz.guess(),
  ...rest
}: DatePickerFieldProps) => {
  const { name, value, onChange, onFocus } = input;

  const DatePickerWidth = stretch ? '100%' : width ? width : 150;

  const handleChange = (date: Moment | null) => {
    let newValue;

    if (showTime) {
      newValue = date ? date.toISOString() : date;
    } else {
      newValue = date ? date.startOf('day').toISOString() : date;
    }

    onChange && onChange(newValue);
  };

  return (
    <FormField label={label} note={note} meta={meta} data-e2e-id={rest['data-e2e-id']}>
      <DatePicker
        style={{ width: DatePickerWidth }}
        name={name}
        value={value ? moment(value).tz(timezone) : null}
        onChange={handleChange}
        onFocus={onFocus}
        format={displayFormat}
        showTime={showTime}
        {...omit(['data-e2e-id'], rest)}
      />
    </FormField>
  );
};
