import React from 'react';
import { Button } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';

import { usePermissions } from '@/features/_hooks';
import styled from '@/theme/styled';
import { ReactComponent as LivoSecureColoredLogo } from '@/static/LivoSecureColor.svg';
import { RenterContext } from '@/providers';
import { Navigation } from './Navigation';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';

export const RenterLayoutTag = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: 1px solid #f4f5f6;
  position: relative;
`;

export const RenderHeaderTag = styled.div<{ withAnchors: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ theme, withAnchors }) =>
    withAnchors ? `${theme.vars.headerHeightWithAnchors}` : `${theme.vars.headerHeight}`};
  background-color: #fff;
  border-bottom: 1px solid #f4f5f6;
  position: sticky;
  top: 0;
  z-index: 5;
`;

export const RenderHeaderNavTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
`;

export const RenterLogo = styled(LivoSecureColoredLogo)`
  display: flex;
  flex-direction: row;
  height: 28px;
  width: 123px;
  color: #323c47;
`;

export const RenderLogInButton = styled(Button)`
  height: 40px;
  border: 1px solid #f37a22;
  color: #f37a22;
`;

export const RenderContentTag = styled.div<{ padding: 'default' | 'none' }>`
  flex: 1;
  overflow-x: auto;
  padding: ${({ padding }) => (padding === 'default' ? `24px 24px 0 24px` : '0')};
  background-color: #fff;
`;

const ScrollLinksContainer = styled.div`
  width: 100%;
  background-color: white;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 8px;

  text-align: center;

  box-shadow: inset 0 0 0 1px #f4f5f6;

  > * {
    padding: 12px 0;
    margin: 0 12px;
  }

  @media (min-width: 426px) {
    display: none;
  }
`;

const ScrollLink = styled.span<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.selected ? '#f37a22' : 'inherit')};
  border-bottom: ${props => (props.selected ? '2px solid #f37a22' : '2px solid transparent')};
`;

const RenderHeaderTagTop = styled.div`
  display: flex;
  height: ${({ theme }) => `${theme.vars.headerHeight}`};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
`;

type RenterLayoutProps = {
  children: React.ReactNode;
  padding?: 'default' | 'none';
};

export const RenterLayout: React.FC<RenterLayoutProps> = ({ children, padding = 'default' }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });

  const onLogin = React.useCallback(
    (params?: { secureNow?: boolean; offerId?: string }) => {
      store.set(redirectType.afterLogin, { ...params, pathname });
      history.replace('/auth');
    },
    [pathname, history, store],
  );

  const { isAuthorized } = useAuth();

  const { isRenter } = usePermissions();

  const overviewRef = React.useRef<HTMLDivElement>(null);
  const detailsRef = React.useRef<HTMLDivElement>(null);
  const amenitiesRef = React.useRef<HTMLDivElement>(null);
  const [linkState, setLinkState] = React.useState<'none' | 'overview' | 'details' | 'amenities'>('none');
  const [anchorMenuVisible, setAnchorMenuVisible] = React.useState<boolean>(false);

  const anchorMenuData = {
    overviewRef,
    detailsRef,
    amenitiesRef,
    setLinkState,
    setAnchorMenuVisible,
  };

  return (
    <RenterLayoutTag>
      <RenterContext.Provider value={{ onLogin, anchorMenuData }}>
        <RenderHeaderTag withAnchors={anchorMenuVisible}>
          <RenderHeaderTagTop>
            <RenterLogo />
            <RenderHeaderNavTag>
              {isAuthorized ? (
                <Navigation isRenter={isRenter} />
              ) : (
                <RenderLogInButton onClick={() => onLogin()}>Log In</RenderLogInButton>
              )}
            </RenderHeaderNavTag>
          </RenderHeaderTagTop>
          <If condition={anchorMenuVisible}>
            <ScrollLinksContainer>
              <ScrollLink
                selected={linkState === 'overview'}
                onClick={() => {
                  overviewRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  setLinkState('overview');
                }}
              >
                Overview
              </ScrollLink>
              <ScrollLink
                selected={linkState === 'details'}
                onClick={() => {
                  detailsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  setLinkState('details');
                }}
              >
                Details
              </ScrollLink>
              <ScrollLink
                selected={linkState === 'amenities'}
                onClick={() => {
                  amenitiesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  setLinkState('amenities');
                }}
              >
                Amenities
              </ScrollLink>
            </ScrollLinksContainer>
          </If>
        </RenderHeaderTag>
        <RenderContentTag padding={padding}>{children}</RenderContentTag>
      </RenterContext.Provider>
    </RenterLayoutTag>
  );
};
