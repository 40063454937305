import gql from 'graphql-tag';

export const CHECK_USER_EMAIL_QUERY = gql`
  query CheckUserEmailQuery($email: String) {
    usersList(filter: { email: { equals: $email } }) {
      count
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    user {
      id
      firstName
      lastName
      email
      firstTimer
      isBlocked
      phone {
        code
        number
      }
      avatar {
        id
        downloadUrl
      }
      company {
        id
        name
        type
        companyLogo {
          id
          downloadUrl
        }
      }
      roles {
        items {
          id
          name
        }
      }
      communityManager {
        items {
          id
          name
        }
      }
      singleUnitAgent {
        items {
          id
          name
        }
      }
    }
  }
`;

export const USER_BLOCK_SUB = gql`
  subscription UserBlock {
    Users(filter: { node: { id: { equals: "__loggedInUserId" } }, mutation_in: update }) {
      node {
        id
        isBlocked
      }
      updatedFields
      previousValues {
        isBlocked
      }
    }
  }
`;
