export enum storageType {
  Session,
}

export enum redirectType {
  afterLogin = 'afterLogin',
}

export enum storageKey {
  Redirect = 'Redirect',
}
