import React from 'react';
import { ColumnFlex, Responsive, TimeLine, TimeLineSimple } from '@/components';
import { Offer, Bid } from '@/shared/types/graphql';
import { useCurrentUser } from '@/features/_hooks/useCurrentUser';
import pluralize from 'pluralize';
import { BidOfferInfo, BidOfferInfoSimple } from '@/features/BidOffersTimeline/BidOfferInfo';

type BidOffersTimelineProps = {
  timeline?: 'simple' | 'horizontal' | 'default';
  offer?: Offer;
  size?: 'sm' | 'md';
  bids: Bid[];
  isUserRejected: boolean;
  actions?: (args: {
    userBidPlace: number;
    activeItemId: number;
    offer?: Offer;
    bids: Bid[];
    bid: Bid;
  }) => React.ReactNode;
};

export const BidOffersTimeline: React.FC<BidOffersTimelineProps> = ({
  timeline = 'default',
  size = 'md',
  isUserRejected,
  offer,
  bids,
  actions,
}) => {
  const { user } = useCurrentUser();
  const [activeItemId, setActive]: any = React.useState(timeline === 'default' ? 1 : 0);

  React.useEffect(() => {
    if (activeItemId > bids.length) {
      setActive(bids.length);
    }
  }, [activeItemId, setActive, bids.length]);

  const [timelineItems, actionsRender] = React.useMemo(() => {
    if (!user || bids.length === 0) {
      return [[], null];
    }

    switch (timeline) {
      case 'horizontal':
      case 'simple': {
        const userBidPlace = bids.findIndex(bid => bid?.renter?.user?.id === user?.id);
        const _timelineItems = bids.map((bid, index) => {
          return {
            id: index,
            title: userBidPlace === index ? 'You' : `${index + 1}`,
            actualIndex: index,
            content: (
              <BidOfferInfoSimple
                owner={userBidPlace === index}
                size={size}
                offer={offer}
                bid={bid}
                bids={bids}
                isUserRejected={isUserRejected}
                userBidPlace={userBidPlace}
                activeItemId={index}
                actions={actions}
              />
            ),
          };
        });
        return [
          _timelineItems,
          actions && (
            <>
              <br />
              {actions({ userBidPlace, activeItemId, offer, bids, bid: bids[activeItemId] })}
            </>
          ),
        ];
      }

      case 'default':
      default: {
        const userBidPlace = bids.findIndex(bid => bid?.renter?.user?.id === user?.id) + 1;
        const _timelineItems = [];

        if (!!bids[0]) {
          const isOwner = userBidPlace === 1;

          _timelineItems.push({
            id: 1,
            title: !isOwner ? '1' : 'You',
            content: (
              <BidOfferInfo
                size={size}
                offer={offer}
                bid={bids[0]}
                bids={bids}
                isUserRejected={isUserRejected}
                userBidPlace={userBidPlace}
                activeItemId={1}
                actions={actions}
              />
            ),
          });
        }

        if (!!bids[1]) {
          const isOwner = userBidPlace === 2;

          _timelineItems.push({
            id: 2,
            title: !isOwner ? '2' : 'You',
            content: (
              <BidOfferInfo
                size={size}
                offer={offer}
                bid={bids[1]}
                bids={bids}
                isUserRejected={isUserRejected}
                userBidPlace={userBidPlace}
                activeItemId={2}
                actions={actions}
              />
            ),
          });
        }

        if (!!bids[2]) {
          const isOwner = userBidPlace === 3;

          _timelineItems.push({
            id: 3,
            title: !isOwner ? '3' : 'You',
            content: (
              <BidOfferInfo
                size={size}
                offer={offer}
                bid={bids[2]}
                bids={bids}
                isUserRejected={isUserRejected}
                userBidPlace={userBidPlace}
                activeItemId={3}
                actions={actions}
              />
            ),
          });
        }

        if (!!bids[3]) {
          const isOwner = userBidPlace === 4;

          if (userBidPlace > 4) {
            const othersCount = userBidPlace - 4;

            _timelineItems.push({
              id: 4,
              title: 'You',
              subTitle: `${userBidPlace} Pos.`,
              beforeText: `${othersCount} ${pluralize('other', othersCount)}`,
              content: (
                <BidOfferInfo
                  size={size}
                  offer={offer}
                  bid={bids[3]}
                  bids={bids}
                  isUserRejected={isUserRejected}
                  userBidPlace={userBidPlace}
                  activeItemId={4}
                  actions={actions}
                />
              ),
            });
          } else {
            _timelineItems.push({
              id: 4,
              title: !isOwner ? '4' : 'You',
              content: (
                <BidOfferInfo
                  size={size}
                  offer={offer}
                  bid={bids[3]}
                  bids={bids}
                  isUserRejected={isUserRejected}
                  userBidPlace={userBidPlace}
                  activeItemId={4}
                  actions={actions}
                />
              ),
            });
          }
        }
        return [
          _timelineItems,
          actions && (
            <>
              <br />
              {actions({ userBidPlace, activeItemId, offer, bids, bid: bids[activeItemId - 1] })}
            </>
          ),
        ];
      }
    }
  }, [bids, size, offer, isUserRejected, actions, timeline, user, activeItemId]);

  const isTimelineSimple = timeline === 'simple' || timeline === 'horizontal';

  if (!user || bids.length === 0) {
    return <div>Have no offers</div>;
  }

  return (
    <ColumnFlex justify="center" align={isTimelineSimple ? 'stretch' : 'center'}>
      {isTimelineSimple ? (
        <TimeLineSimple
          showContent={timeline === 'horizontal'}
          active={activeItemId}
          onActiveClick={setActive}
          items={timelineItems}
          size={size}
        />
      ) : (
        <TimeLine active={activeItemId} onActiveClick={setActive} items={timelineItems} size={size} />
      )}
      <Responsive.HideOn breakpoints={['md', 'lg']}>{actionsRender}</Responsive.HideOn>
    </ColumnFlex>
  );
};
