import gql from 'graphql-tag';

export const CONGRATULATIONS_BID_QUERY = gql`
  query CongratulationsBidQuery($id: ID) {
    bid(id: $id) {
      id
      rentAmount
      moveinDate
      termsOfLease
      updatedAt
      offer {
        id
        updatedAt
        approveOfferStartTime
        timeframeToSignContract
        activeOfferSingleUnit {
          id
          name
        }
      }
    }
  }
`;

export const CONGRATULATIONS_BID_MUTATION = gql`
  mutation CongratulationsBidMutation($filter: BidKeyFilter, $data: BidUpdateInput!) {
    bidUpdate(filter: $filter, data: $data) {
      id
      offerAccepted
    }
  }
`;
