import React from 'react';
import { PropertyOffer } from './PropertyOffer';
import { RenterLayout } from '../_layouts/RenterLayout';

export default function PropertyOfferPage() {
  return (
    <RenterLayout padding="none">
      <PropertyOffer />
    </RenterLayout>
  );
}
