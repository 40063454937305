import React from 'react';
import { Notifications } from '@/features';
import { Burger, MobileNavList } from './components';

type NavProps = {
  isRenter: boolean;
};

export const Navigation = ({ isRenter }: NavProps) => {
  const [navToggled, setNavToggled] = React.useState(false);

  const handleToggleNav = () => {
    setNavToggled(s => !s);
  };

  return (
    <React.Fragment>
      <Notifications />
      <Burger toggled={navToggled} onClick={handleToggleNav} />
      {navToggled && <MobileNavList isRenter={isRenter} handleToggleNav={handleToggleNav} />}
    </React.Fragment>
  );
};
