import React from 'react';

import { ErrorPage as ErrorPageContent } from './ErrorPage';
import { RenterLayout } from '../_layouts/RenterLayout';

export default function ErrorPage() {
  return (
    <RenterLayout>
      <ErrorPageContent />
    </RenterLayout>
  );
}
