import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Spin } from 'antd';

const LoadingContainer = styled.div<{ stretch?: boolean; height?: string }>`
  display: grid;
  justify-content: center;
  align-items: center;

  ${stretch =>
    stretch &&
    css`
      width: 100%;
      height: 100%;
      min-height: 100px;
    `}
  height: ${({ height }) => height}
`;

type LoaderProps = {
  stretch?: boolean;
  height?: string;
};

export const Loader = ({ stretch, height }: LoaderProps) => {
  return (
    <LoadingContainer stretch={stretch} height={height}>
      <Spin size="large" />
    </LoadingContainer>
  );
};
