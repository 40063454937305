import { ParsedQuery } from 'query-string';
import { SetQueryParamsHandler } from '@/features/_hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Company } from '@/shared/types/graphql';
import { COMPANY_QUERY } from '@/pages/CompanyLiveOffers/queries';
import { Grid, Heading } from '@/components';
import { Select } from 'antd';
import { SORT_OPTIONS } from '@/pages/CompanyLiveOffers/constants';

type HeaderProps = {
  queryParams: ParsedQuery;
  setQueryParams: SetQueryParamsHandler;
};

const Header: React.FC<HeaderProps> = ({ setQueryParams, queryParams }) => {
  const { id: companyId } = useParams<{ id: string }>();

  const onChangeSortBy = React.useCallback(
    (value: any) => {
      setQueryParams({ ...queryParams, ...JSON.parse(value) });
    },
    [queryParams, setQueryParams],
  );

  const { data: companyData, loading } = useQuery<{ company: Company }>(COMPANY_QUERY, {
    skip: !companyId,
    variables: {
      id: companyId,
    },
  });

  const sortValue = JSON.stringify({
    sortBy: queryParams?.sortBy,
    sortOrder: queryParams?.sortOrder,
  });

  const companyName = companyData?.company.name || '';

  return (
    <Grid.Layout gap="8px" alignItems="center" justifyItems="center" stretch>
      <Heading type="h1">{companyName} Available Rentals</Heading>
      <Grid.Layout gap="8px" stretch>
        <Select onChange={onChangeSortBy} value={sortValue} loading={loading} placeholder="Sort by">
          {SORT_OPTIONS.map(option => (
            <Select.Option key={JSON.stringify(option.value)} value={JSON.stringify(option.value)}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Grid.Layout>
    </Grid.Layout>
  );
};

export default Header;
