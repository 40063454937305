export enum CompanySingleUnits {
  OFFERS_PAGE = 'property',
  OFFER = 'offer',
  SECURE_NOW = 'secure_now',
}

export const SORT_OPTIONS = [
  {
    label: 'Sort by: Time Asc',
    value: {
      sortBy: 'createdAt',
      sortOrder: 'ASC',
    },
  },
  {
    label: 'Sort by: Time Desc',
    value: {
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    },
  },
];
