import { useCurrentUser } from '@/features/_hooks';

export const useAllowedCommunities = () => {
  const { user } = useCurrentUser();

  const regionalManagerCommunitiesList = user?.regionalManager?.items || [];
  const propertyManagerCommunity = user?.communityManager?.items || [];

  return [...regionalManagerCommunitiesList, ...propertyManagerCommunity].filter(Boolean);
};
