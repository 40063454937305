import React from 'react';

import {
  BidCreateModal,
  BidDeleteModal,
  VirtualTourModal,
  OfferRejectModal,
  AgentContactModal,
  CalendarModal,
  ScheduleShowingContactModal,
} from './modals';

export type GeneralModalProps = {
  props: Record<string, any>;
  setProps: (data: Record<string, any>) => void;
  close: () => void;
};

type ModalContextProps = {
  bidCreateModal: GeneralModalProps;
  bidDeleteModal: GeneralModalProps;
  virtualTourModal: GeneralModalProps;
  offerRejectModal: GeneralModalProps;
  agentContactModal: GeneralModalProps;
  calendarModal: GeneralModalProps;
  scheduleShowingContactModal: GeneralModalProps;
};

const ModalContext = React.createContext<ModalContextProps | undefined>(undefined);

type UseModalProps = Record<string, any>;

const useModal = (
  initialProps: UseModalProps = { visible: false },
): [Record<string, any>, (data: Record<string, any>) => void] => {
  const [props, setProps] = React.useState(initialProps);
  return [props, setProps];
};

type ModalProviderProps = {
  children: React.ReactChild | React.ReactChild[];
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  // BidCreateModal
  const [bidCreateModalProps, setBidCreateModalProps] = useModal();
  const closeBidCreateModal = React.useCallback(() => setBidCreateModalProps((s: any) => ({ ...s, visible: false })), [
    setBidCreateModalProps,
  ]);

  // BidDeleteModal
  const [bidDeleteModalProps, setBidDeleteModalProps] = useModal();
  const closeBidDeleteModal = React.useCallback(() => setBidDeleteModalProps((s: any) => ({ ...s, visible: false })), [
    setBidDeleteModalProps,
  ]);

  // VirtualTourModal
  const [virtualTourModalProps, setVirtualTourModalProps] = useModal();
  const closeVirtualModal = React.useCallback(() => setVirtualTourModalProps((s: any) => ({ ...s, visible: false })), [
    setVirtualTourModalProps,
  ]);

  // OfferRejectModal
  const [offerRejectModalProps, setOfferRejectModalProps] = useModal();
  const closeOfferRejectModal = React.useCallback(
    () => setOfferRejectModalProps((...s: any) => ({ ...s, visible: false })),
    [setOfferRejectModalProps],
  );

  // AgentContactModal
  const [agentContactModalProps, setAgentContactModalProps] = useModal();
  const closeAgentContactModal = React.useCallback(
    () => setAgentContactModalProps((...s: any) => ({ ...s, visible: false })),
    [setAgentContactModalProps],
  );

  // CalendarModal
  const [calendarModalProps, setCalendarModalProps] = useModal();
  const closeCalendarModal = React.useCallback(() => setCalendarModalProps((...s: any) => ({ ...s, visible: false })), [
    setCalendarModalProps,
  ]);

  // ScheduleShowingContactModal
  const [scheduleShowingContactModalProps, setScheduleShowingContactModalProps] = useModal();
  const closeScheduleShowingContactModal = React.useCallback(
    () => setScheduleShowingContactModalProps((...s: any) => ({ ...s, visible: false })),
    [setScheduleShowingContactModalProps],
  );

  return (
    <ModalContext.Provider
      value={{
        bidCreateModal: {
          props: bidCreateModalProps,
          setProps: setBidCreateModalProps,
          close: closeBidCreateModal,
        },
        bidDeleteModal: {
          props: bidDeleteModalProps,
          setProps: setBidDeleteModalProps,
          close: closeBidDeleteModal,
        },
        virtualTourModal: {
          props: virtualTourModalProps,
          setProps: setVirtualTourModalProps,
          close: closeVirtualModal,
        },
        offerRejectModal: {
          props: offerRejectModalProps,
          setProps: setOfferRejectModalProps,
          close: closeOfferRejectModal,
        },
        agentContactModal: {
          props: agentContactModalProps,
          setProps: setAgentContactModalProps,
          close: closeAgentContactModal,
        },
        calendarModal: {
          props: calendarModalProps,
          setProps: setCalendarModalProps,
          close: closeCalendarModal,
        },
        scheduleShowingContactModal: {
          props: scheduleShowingContactModalProps,
          setProps: setScheduleShowingContactModalProps,
          close: closeScheduleShowingContactModal,
        },
      }}
    >
      {children}
      {bidCreateModalProps.visible && <BidCreateModal {...bidCreateModalProps} />}
      {bidDeleteModalProps.visible && <BidDeleteModal {...bidDeleteModalProps} />}
      {virtualTourModalProps.visible && <VirtualTourModal {...virtualTourModalProps} />}
      {offerRejectModalProps.visible && <OfferRejectModal {...offerRejectModalProps} />}
      {agentContactModalProps.visible && <AgentContactModal {...agentContactModalProps} />}
      {calendarModalProps.visible && <CalendarModal {...calendarModalProps} />}
      {scheduleShowingContactModalProps.visible && (
        <ScheduleShowingContactModal {...scheduleShowingContactModalProps} />
      )}
    </ModalContext.Provider>
  );
};

export const useModalsState = () => {
  const modalsState = React.useContext(ModalContext);

  if (!modalsState) {
    throw new Error('useModalsState should be used within the ModalContext');
  }

  return modalsState;
};
