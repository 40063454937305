import React from 'react';
import pluralize from 'pluralize';
import moment from 'moment';
import { styled } from '@/theme';
import { toast } from 'react-toastify';
import { Button, Icon } from 'antd';
import { composeValidators, phoneNumber } from '@/shared/validators';

import { useQuery } from 'react-apollo';
import { Loader, TimeLineSimple, InputField } from '@/components';
import { useCurrentUser } from '@/features/_hooks/useCurrentUser';
import { BidOfferInfoSimple } from '@/features/BidOffersTimeline/BidOfferInfo';
import { EntityOption } from '@/features';
import { BID_UPDATE_MUTATION, RENTER_QUERY, RENTER_UPDATE } from './queries';
import { ChevronRight } from '@/static/icons';
import { Offer, Bid, User, SmartPhone } from '@/shared/types/graphql';
import { calculateBidRank, stringifyDate, normalizePhone, formatAmount } from '@/shared/utils';
import { TERMS_OF_LEASE_MIN_AMOUNT, TERMS_OF_LEASE_MAX_AMOUNT } from '@/shared/constants';
import { useMutationSafe } from '@/features/_hooks';

const EditFormContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

const EditFormContainerLoading = styled.div`
  display: grid;
  min-height: 500px;
`;

const EditFormControls = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

const EditFormControl = styled.div<{ changed: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;

  > *:nth-child(even) {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

    > *:nth-child(even) {
      color: ${props => (props.changed ? props.theme.vars.primaryColor : '#323c47')};
      font-size: 20px;
      line-height: 1.2em;
    }
  }

  > *:nth-child(odd) {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 0;

    &:not([disabled]) {
      color: ${props => props.theme.vars.primaryColor};
      border: 1px solid ${props => props.theme.vars.primaryColor};
    }
  }
`;

const EditFormActions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 14px;
`;

type EditFormState = { rent: number; securityDeposite: number; moveIn: string; term: number };
type EditFormActions =
  | { kind: 'rent-inc' }
  | { kind: 'rent-dec' }
  | { kind: 'deposite-inc' }
  | { kind: 'deposite-dec' }
  | { kind: 'move-inc' }
  | { kind: 'move-dec' }
  | { kind: 'term-inc' }
  | { kind: 'term-dec' }
  | { kind: 'flush'; value?: EditFormState };

export function EditOfferForm(props: {
  offer: Offer;
  mode: { kind: 'edit' } | { kind: 'beat'; value: Bid } | { kind: 'match'; value: Bid } | { kind: 'secure' };
  validation?: false;
  showContent?: false;
  onCancel: () => void;
  onPlace?: (bid: Bid) => void;
}) {
  const { user } = useCurrentUser();

  const { data: renterData, loading: renterLoading } = useQuery<{ user: User }>(RENTER_QUERY, {
    variables: { id: user?.id },
    skip: !user?.id,
    onCompleted: renterData => setRenterPhone(renterData?.user?.renter?.phone),
  });

  const renter = renterData?.user?.renter;
  const [renterPhone, setRenterPhone] = React.useState<SmartPhone | null | undefined>(renter?.phone);
  const renterPhoneError = composeValidators(
    (value: any) => (value ? undefined : 'Phone number is required to make an offer'),
    phoneNumber,
  )(renterPhone?.number, []);
  const renterPhoneTouched = renterPhone?.number !== renter?.phone?.number;

  const [renterMutate] = useMutationSafe(RENTER_UPDATE);

  const [bidMutate] = useMutationSafe(BID_UPDATE_MUTATION, {
    onCompleted: () => {
      toast.success('Offer successfully created');
      props.onCancel();
    },
  });

  const [updating, setUpdating] = React.useState(false);
  const [suggesting, setSuggesting] = React.useState(false);

  const yourBidIndex = props.offer?.bid?.items?.findIndex(bid => bid?.renter?.user?.id === user?.id) as number;
  const yourBid = props.offer?.bid?.items[yourBidIndex] as Bid;
  const incBidAmount = props.offer?.incrementedBidAmount || 100;
  const minimumLeaseLength = props.offer?.minimumLeaseLength || TERMS_OF_LEASE_MIN_AMOUNT;
  const maximumLeaseLength = props.offer?.maximumLeaseLength || TERMS_OF_LEASE_MAX_AMOUNT;

  const [state, send] = React.useReducer(
    function EditFormReducer(state: EditFormState, action: EditFormActions): EditFormState {
      switch (action.kind) {
        case 'rent-inc':
          return { ...state, rent: state.rent + incBidAmount };
        case 'rent-dec': {
          const rent = state.rent - incBidAmount;
          if (props.offer?.rentAmount && rent < props.offer.rentAmount) {
            return state;
          } else {
            return { ...state, rent };
          }
        }

        case 'deposite-inc':
          return { ...state, securityDeposite: state.securityDeposite + incBidAmount };
        case 'deposite-dec': {
          const securityDeposite = state.securityDeposite - incBidAmount;
          if (props.offer?.securityDeposite && securityDeposite < props.offer.securityDeposite) {
            return state;
          } else {
            return { ...state, securityDeposite };
          }
        }

        case 'move-inc':
          return {
            ...state,
            moveIn: moment(state.moveIn)
              .add(1, 'day')
              .format('YYYY-MM-DD'),
          };
        case 'move-dec': {
          const moveIn = moment(state.moveIn)
            .subtract(1, 'day')
            .format('YYYY-MM-DD');
          if (props.offer?.moveinDate && moment(moveIn).isBefore(props.offer?.moveinDate)) {
            return state;
          } else {
            return { ...state, moveIn };
          }
        }

        case 'term-inc':
          const term = state.term + 1;
          if (term > maximumLeaseLength) {
            return state;
          } else {
            return { ...state, term };
          }
        case 'term-dec': {
          const term = state.term - 1;
          if (term < minimumLeaseLength) {
            return state;
          } else {
            return { ...state, term };
          }
        }

        case 'flush':
          return {
            rent: action?.value?.rent || yourBid?.rentAmount || 0,
            securityDeposite: action.value?.securityDeposite || yourBid?.securityDeposite || 0,
            moveIn: action?.value?.moveIn || yourBid?.moveinDate || '2020-07-31',
            term: action?.value?.term || yourBid?.termsOfLease || 0,
          };

        default:
          return state;
      }
    },
    {
      rent: yourBid?.rentAmount || 0,
      securityDeposite: yourBid?.securityDeposite || props.offer?.securityDeposite || 0,
      moveIn: yourBid?.moveinDate || '2020-07-31',
      term: yourBid?.termsOfLease || 0,
    },
  );

  const yourNewBidPos = calculateBidRank(
    {
      ...yourBid,
      rentAmount: state.rent,
      moveinDate: state.moveIn,
      termsOfLease: state.term,
      securityDeposite: state.securityDeposite,
    },
    props.offer,
  );

  const yourNewBid = {
    ...yourBid,
    ...yourNewBidPos,
    rentAmount: state.rent,
    moveinDate: state.moveIn,
    termsOfLease: state.term,
    securityDeposite: state.securityDeposite,
  };

  const timelineBids = (props.offer?.bid?.items || [])
    .map(bid => (bid?.id === yourBid?.id ? yourNewBid : bid))
    .sort((bidA, bidB) =>
      typeof bidA?.rank === 'number' && typeof bidB?.rank === 'number'
        ? bidA?.rank > bidB?.rank
          ? -1
          : bidA?.rank < bidB?.rank
          ? +1
          : 0
        : 0,
    );

  const controllsDisabled = props.mode.kind === 'secure';

  const rentControls = (
    <EditFormControl changed={state.rent !== yourBid.rentAmount}>
      <Button
        type="default"
        block
        size="large"
        disabled={controllsDisabled || state.rent - incBidAmount < (props.offer?.rentAmount as number)}
        onClick={() => send({ kind: 'rent-dec' })}
      >
        -${formatAmount(incBidAmount)}
      </Button>
      <EntityOption label="Rent" value={`$${formatAmount(state.rent)}/mo`} />
      <Button type="default" block size="large" disabled={controllsDisabled} onClick={() => send({ kind: 'rent-inc' })}>
        +${formatAmount(incBidAmount)}
      </Button>
    </EditFormControl>
  );

  const depositControls = (
    <EditFormControl changed={state.securityDeposite !== yourBid.securityDeposite}>
      <Button
        type="default"
        block
        size="large"
        disabled={
          controllsDisabled ||
          !props.offer.securityDepositeEditable ||
          state.securityDeposite - incBidAmount < (props.offer?.securityDeposite as number)
        }
        onClick={() => send({ kind: 'deposite-dec' })}
      >
        -${formatAmount(incBidAmount)}
      </Button>
      <EntityOption label="Security Deposit" value={`$${formatAmount(state.securityDeposite || 0)}`} />
      <Button
        type="default"
        block
        size="large"
        disabled={controllsDisabled || !props.offer.securityDepositeEditable}
        onClick={() => send({ kind: 'deposite-inc' })}
      >
        +${formatAmount(incBidAmount)}
      </Button>
    </EditFormControl>
  );

  const moveInControls = (
    <EditFormControl changed={state.moveIn !== yourBid.moveinDate}>
      <Button
        type="default"
        block
        size="large"
        disabled={
          controllsDisabled ||
          moment(state.moveIn)
            .subtract(1, 'day')
            .isBefore(moment(props.offer?.moveinDate))
        }
        onClick={() => send({ kind: 'move-dec' })}
      >
        <Icon component={ChevronRight} css={{ fontSize: 16 }} rotate={180} />
      </Button>
      <EntityOption label="Move-in date" value={moment(state.moveIn).format('MMMM DD, YYYY')} />
      <Button type="default" block size="large" disabled={controllsDisabled} onClick={() => send({ kind: 'move-inc' })}>
        <Icon component={ChevronRight} css={{ fontSize: 16 }} />
      </Button>
    </EditFormControl>
  );

  const termControls = (
    <EditFormControl changed={state.term !== yourBid.termsOfLease}>
      <Button
        type="default"
        block
        size="large"
        disabled={controllsDisabled || state.term - 1 < minimumLeaseLength}
        onClick={() => send({ kind: 'term-dec' })}
      >
        <Icon component={ChevronRight} css={{ fontSize: 16 }} rotate={180} />
      </Button>
      <EntityOption label="Term" value={state.term + ' ' + pluralize('month', state.term)} />
      <Button
        type="default"
        block
        size="large"
        disabled={controllsDisabled || state.term + 1 > maximumLeaseLength}
        onClick={() => send({ kind: 'term-inc' })}
      >
        <Icon component={ChevronRight} css={{ fontSize: 16 }} />
      </Button>
    </EditFormControl>
  );

  const withPhoneChecks = (placeOfferHandler: (bid: Bid) => Promise<void> | void) => async (bid: Bid) => {
    if (renterPhoneError) {
      if (!renterPhoneTouched) {
        setRenterPhone({ ...renterPhone, number: '' });
      }
      return;
    }

    setUpdating(true);

    if (!renter?.phone || renterPhoneTouched) {
      delete renterPhone?.__typename;
      await renterMutate({
        variables: {
          data: {
            id: renter?.id,
            phone: renterPhone,
          },
        },
      });
    }

    await placeOfferHandler(bid);
  };

  const onPlaceOffer = withPhoneChecks(
    props.onPlace ||
      (async (yourNewBid: Bid) => {
        delete yourNewBid?.__typename;

        await bidMutate({
          refetchQueries: ['SingleUnitOfferQuery'],
          awaitRefetchQueries: true,
          variables: {
            data: {
              ...yourNewBid,
              renter: {
                connect: {
                  id: yourNewBid?.renter?.id,
                },
              },
              offer: {
                connect: {
                  id: props.offer?.id,
                },
              },
            },
          },
        });
      }),
  );

  React.useLayoutEffect(
    function switchMode() {
      switch (props.mode.kind) {
        case 'beat': {
          send({
            kind: 'flush',
            value: {
              rent: (props.mode.value?.rentAmount as number) + incBidAmount,
              securityDeposite: props.mode.value?.securityDeposite as number,
              moveIn: props.mode.value?.moveinDate as string,
              term: props.mode.value?.termsOfLease as number,
            },
          });
          break;
        }
        case 'match': {
          send({
            kind: 'flush',
            value: {
              rent: props.mode.value?.rentAmount as number,
              securityDeposite: props.mode.value?.securityDeposite as number,
              moveIn: props.mode.value?.moveinDate as string,
              term: props.mode.value?.termsOfLease as number,
            },
          });
          break;
        }
        case 'secure': {
          send({
            kind: 'flush',
            value: {
              rent: props.offer?.secureNowAmount as number,
              securityDeposite: props.offer?.securityDeposite as number,
              moveIn: props.offer?.moveinDate as string,
              term: props.offer?.termsOfLease as number,
            },
          });
        }
      }
    },
    // This effect is supposed to run only once per mount!
    // https://github.com/facebook/create-react-app/issues/6880
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (updating || renterLoading) {
    return (
      <EditFormContainerLoading>
        <Loader stretch />
      </EditFormContainerLoading>
    );
  }

  if (suggesting) {
    const topBid = props.offer.bid?.items[0] as Bid;
    return (
      <SuggestedOffers
        offer={props.offer}
        yourBid={yourBid}
        topBid={topBid}
        onGoBack={() => setSuggesting(false)}
        onSelectOffer={onPlaceOffer}
      />
    );
  }

  return (
    <EditFormContainer>
      <EditOfferTimeline bids={timelineBids} />
      {props.showContent !== false && (
        <BidOfferInfoSimple
          owner
          offer={props.offer}
          bid={yourBid}
          bids={[]}
          userBidPlace={yourBidIndex}
          activeItemId={yourBidIndex}
          size="md"
          isUserRejected={false}
        />
      )}
      <EditFormControls>
        {rentControls}
        {depositControls}
        {moveInControls}
        {termControls}
      </EditFormControls>
      <EditFormActions>
        {!renter?.phone && (
          <InputField
            mask="(999) 999-9999"
            addonBefore={renterPhone?.code ? `+${renterPhone.code}` : '+1'}
            meta={{
              error: renterPhoneError,
              touched: renterPhoneTouched,
            }}
            input={{
              name: 'renter.phone.number',
              value: renterPhone?.number,
              onChange: e => {
                const newPhoneNumber = normalizePhone(e.target.value);
                setRenterPhone({ ...renterPhone, number: newPhoneNumber });
              },
            }}
          />
        )}
        <Button
          type="primary"
          block
          size="large"
          disabled={
            props.validation === false
              ? false
              : state.rent === yourBid.rentAmount &&
                state.moveIn === yourBid.moveinDate &&
                state.term === yourBid.termsOfLease &&
                state.securityDeposite === yourBid.securityDeposite
          }
          onClick={() => onPlaceOffer(yourNewBid)}
        >
          {props.mode.kind === 'secure' ? 'SecureNow™!' : 'Place Offer'}
        </Button>
        {props.mode.kind !== 'secure' && (
          <Button
            type="default"
            block
            size="large"
            disabled={yourBidIndex === 0 || renterPhoneError}
            onClick={() => setSuggesting(true)}
          >
            View Suggested Offers
          </Button>
        )}
        {props.onCancel && (
          <Button type="default" block size="large" onClick={props.onCancel}>
            Back
          </Button>
        )}
      </EditFormActions>
    </EditFormContainer>
  );
}

const EditOfferTimelineContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
`;

function EditOfferTimeline(props: { bids: Bid[] }) {
  const { user } = useCurrentUser();
  const timelineItems = props.bids.map((bid, index) => {
    return {
      id: index,
      title: bid?.renter?.user?.id === user?.id ? 'You' : `${index + 1}`,
      subTitle: `${bid?.rank}`,
      actualIndex: index,
    };
  });

  return (
    <EditOfferTimelineContainer>
      <TimeLineSimple showContent={false} items={timelineItems} inReverse />
    </EditOfferTimelineContainer>
  );
}

const SuggestedOffersContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

const SuggestedOffersList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

const SuggestedOfferCard = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const SuggestedOfferCardResume = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;
  padding: 12px;

  > * {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 8px;

    > *:nth-child(even) {
      font-size: 20px;
    }
  }
`;

const SuggestedOfferCardSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e3e3;
  padding: 12px 0;
  width: 100%;
  color: #939393;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  > *:first-child {
    margin-left: 16px;
  }

  > *:last-child {
    margin-right: 16px;
    color: #f37a22;
  }
`;

const SuggestedOffersActions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
`;

const SuggestedOffersTitle = styled.span`
  color: ${props => props.theme.vars.black};
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    font-weight: 500;
    width: 100%;
  }
`;

function SuggestedOffers(props: {
  offer: Offer;
  yourBid: Bid;
  topBid: Bid;
  onGoBack: () => void;
  onSelectOffer: (newBid: Bid) => void;
}) {
  const rentOffer = React.useMemo(() => {
    if (props.yourBid.id === props.topBid.id) {
      return null;
    } else if (props.yourBid?.rank && props.topBid?.rank && props.yourBid.rank > props.topBid.rank) {
      return null;
    } else {
      const topRank = props.topBid.rank as number;
      const topRent = props.topBid.rentAmount as number;

      let newRentAmount = topRent + (props.offer.incrementedBidAmount as number);
      let { rank: calcNewTop } = calculateBidRank(
        {
          ...props.yourBid,
          rentAmount: newRentAmount,
        },
        props.offer,
      );

      if (calcNewTop < topRank) {
        // if new rank position is still lower than top
        // we are trying to bruteforce-find new one N-times
        for (let i = 0; i < 10; i++) {
          newRentAmount += props.offer.incrementedBidAmount as number;

          const newPos = calculateBidRank(
            {
              ...props.yourBid,
              rentAmount: newRentAmount,
            },
            props.offer,
          );

          calcNewTop = newPos.rank;

          if (calcNewTop > topRank) {
            break;
          }
        }
      }

      if (calcNewTop < topRank) {
        return null;
      } else {
        const newBid = {
          ...props.yourBid,
          rentAmount: newRentAmount,
        };
        return (
          <SuggestedOfferCard>
            <SuggestedOfferCardResume>
              <EntityOption selected label="Rent" value={`$${newRentAmount}`} />
              <EntityOption label="Lease" value={`${props.yourBid?.termsOfLease} mo.`} />
              <EntityOption label="Lease starts" value={stringifyDate(props.yourBid?.moveinDate)} />
            </SuggestedOfferCardResume>
            <SuggestedOfferCardSelect onClick={() => props.onSelectOffer(newBid)}>
              <span>Select this offer</span>
              <Icon component={ChevronRight} css={{ fontSize: 16 }} />
            </SuggestedOfferCardSelect>
          </SuggestedOfferCard>
        );
      }
    }
  }, [props]);

  const termOffer = React.useMemo(() => {
    if (props.yourBid.id === props.topBid.id) {
      return null;
    } else if (props.yourBid?.rank && props.topBid?.rank && props.yourBid.rank > props.topBid.rank) {
      return null;
    } else {
      const topRank = props.topBid.rank as number;
      const topTerm = props.topBid.termsOfLease as number;

      let newTermAmount = topTerm + 1;
      let { rank: calcNewTop } = calculateBidRank(
        {
          ...props.yourBid,
          termsOfLease: newTermAmount,
        },
        props.offer,
      );

      if (calcNewTop < topRank) {
        // if new rank position is still lower than top
        // we are trying to bruteforce-find new one N-times
        for (let i = 0; i < 100; i++) {
          newTermAmount += 1;

          const newPos = calculateBidRank(
            {
              ...props.yourBid,
              termsOfLease: newTermAmount,
            },
            props.offer,
          );

          calcNewTop = newPos.rank;

          if (calcNewTop > topRank) {
            break;
          }
        }
      }

      if (calcNewTop < topRank) {
        return null;
      } else {
        const newBid = {
          ...props.yourBid,
          termsOfLease: newTermAmount,
        };
        return (
          <SuggestedOfferCard>
            <SuggestedOfferCardResume>
              <EntityOption label="Rent" value={`$${props.yourBid?.rentAmount}`} />
              <EntityOption selected label="Lease" value={`${newTermAmount} mo.`} />
              <EntityOption label="Lease starts" value={stringifyDate(props.yourBid?.moveinDate)} />
            </SuggestedOfferCardResume>
            <SuggestedOfferCardSelect onClick={() => props.onSelectOffer(newBid)}>
              <span>Select this offer</span>
              <Icon component={ChevronRight} css={{ fontSize: 16 }} />
            </SuggestedOfferCardSelect>
          </SuggestedOfferCard>
        );
      }
    }
  }, [props]);

  const moveInOffer = React.useMemo(() => {
    if (props.yourBid.id === props.topBid.id) {
      return null;
    } else if (props.yourBid?.rank && props.topBid?.rank && props.yourBid.rank > props.topBid.rank) {
      return null;
    } else {
      const topRank = props.topBid.rank as number;
      const yourMoveIn = props.yourBid.moveinDate as string;

      let newMoveIn = moment(yourMoveIn).subtract(1, 'day');
      if (newMoveIn.isBefore(moment(props.offer.moveinDate))) {
        return null;
      }

      let { rank: calcNewTop } = calculateBidRank(
        {
          ...props.yourBid,
          moveinDate: newMoveIn.format('YYYY-MM-DD'),
        },
        props.offer,
      );

      if (calcNewTop < topRank) {
        // if new rank position is still lower than top
        // we are trying to bruteforce-find new one N-times
        for (let i = 0; i < 100; i++) {
          newMoveIn = newMoveIn.subtract(1, 'day');

          if (newMoveIn.isBefore(moment(props.offer.moveinDate))) {
            return null;
          }

          const newPos = calculateBidRank(
            {
              ...props.yourBid,
              moveinDate: newMoveIn.format('YYYY-MM-DD'),
            },
            props.offer,
          );

          calcNewTop = newPos.rank;

          if (calcNewTop > topRank) {
            break;
          }
        }
      }

      if (calcNewTop < topRank) {
        return null;
      } else {
        const newBid = {
          ...props.yourBid,
          moveinDate: newMoveIn.format('YYYY-MM-DD'),
        };
        return (
          <SuggestedOfferCard>
            <SuggestedOfferCardResume>
              <EntityOption label="Rent" value={`$${props.yourBid?.rentAmount}`} />
              <EntityOption label="Lease" value={`${props.yourBid?.termsOfLease} mo.`} />
              <EntityOption selected label="Lease starts" value={stringifyDate(newMoveIn.format('YYYY-MM-DD'))} />
            </SuggestedOfferCardResume>
            <SuggestedOfferCardSelect onClick={() => props.onSelectOffer(newBid)}>
              <span>Select this offer</span>
              <Icon component={ChevronRight} css={{ fontSize: 16 }} />
            </SuggestedOfferCardSelect>
          </SuggestedOfferCard>
        );
      }
    }
  }, [props]);

  return (
    <SuggestedOffersContainer>
      <SuggestedOffersTitle>Suggested Offers</SuggestedOffersTitle>
      <SuggestedOffersList>
        {rentOffer}
        {termOffer}
        {moveInOffer}
        {rentOffer === null && termOffer === null && moveInOffer === null && (
          <>
            <span style={{ margin: '0 20px' }}>
              We have nothing to suggest at this time. Either your offer is already ideal or you pushed your move-in
              date way too far.
            </span>
            <span style={{ margin: '0 20px' }}>
              If you aren{"'"}t at the first place, try changing move-in date as close to the initial offer as possible,
              add more rent, and/or expand lease period.
            </span>
          </>
        )}
      </SuggestedOffersList>
      <SuggestedOffersActions>
        <Button block size="large" onClick={props.onGoBack}>
          Back
        </Button>
      </SuggestedOffersActions>
    </SuggestedOffersContainer>
  );
}
