import React from 'react';

import { ColumnFlex, Tag } from '@/components';
import { OfferTitle } from './styled';
import { Offer, Bid, Maybe } from '@/shared/types/graphql';
import { useCurrentUser, useOfferBidCombinedStatus } from '@/features/_hooks';
import { BidOfferActions } from './BidOfferActions';
import { BidOffersTimeline } from '@/features';
import { OFFER_STATUSES, BID_STATUSES, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { Congratulations, OfferAccepted } from './components';

type PropertyOfferTimelineProps = {
  offer?: Maybe<Offer>;
  onImprove?: () => void;
  onBeat?: (bid: Bid) => void;
  onMatch?: (bid: Bid) => void;
  onSecure?: () => void;
  onContact?: () => void;
};

const auctionEndedText = ({
  offer,
  isWinner,
  isOfferAccepted,
  bid,
  onContact,
}: {
  offer?: Maybe<Offer>;
  isWinner: boolean;
  bid?: Maybe<Bid>;
  isOfferAccepted?: Maybe<boolean>;
  onContact?: () => void;
}) => {
  if (isWinner) {
    if (offer?.status === OFFER_STATUSES.leased) {
      return <OfferTitle isPrimaryColor>Congratulations! You won this rental!</OfferTitle>;
    }
    if (isOfferAccepted) {
      return <OfferAccepted bid={bid} offer={offer} onContact={onContact} />;
    }
    return <Congratulations bidId={bid?.id} onContact={onContact} />;
  }

  switch (offer?.status) {
    case OFFER_STATUSES.pending: {
      return <OfferTitle>Auction Has Not Started Yet</OfferTitle>;
    }
    case OFFER_STATUSES.secured:
    case OFFER_STATUSES.closed:
    case OFFER_STATUSES.ended: {
      return <OfferTitle>Auction Has Already Ended</OfferTitle>;
    }
    default: {
      return <OfferTitle>Auction Is Not Active</OfferTitle>;
    }
  }
};

export const PropertyOfferTimeline: React.FC<PropertyOfferTimelineProps> = ({
  offer,
  onImprove,
  onBeat,
  onMatch,
  onSecure,
  onContact,
}) => {
  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);
  const { user } = useCurrentUser();
  const currentUserId = user?.id;

  const bids = offer?.bid?.items || [];
  const isUserRejected = bids.some(
    bid => bid?.status === BID_STATUSES.rejected && bid?.renter?.user?.id === currentUserId,
  );

  if (isUserRejected) {
    return (
      <Tag color="red" type="raised">
        Your offer was rejected
      </Tag>
    );
  }

  const filteredBids = bids.filter(bid => bid?.status !== BID_STATUSES.rejected);

  const userBidIndex = filteredBids.findIndex(bid => bid?.renter?.user?.id === user?.id);
  const userBid = filteredBids[userBidIndex];

  const isWinner =
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES['you-won'] ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES['you-accept'] ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES['you-secured'] ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.awarded;

  if (offer?.status !== OFFER_STATUSES.live) {
    return auctionEndedText({
      offer,
      isWinner,
      isOfferAccepted: userBid?.offerAccepted,
      bid: userBid,
      onContact,
    });
  }

  return (
    <ColumnFlex align="center" justify="center">
      <BidOffersTimeline
        timeline="horizontal"
        offer={offer}
        bids={filteredBids}
        isUserRejected={isUserRejected}
        actions={({ userBidPlace, activeItemId, offer, bid }) => (
          <BidOfferActions
            userBidPlace={userBidPlace}
            userBid={userBid}
            activeItemId={activeItemId}
            offer={offer}
            bid={bid}
            onImprove={onImprove}
            onBeat={onBeat}
            onMatch={onMatch}
            onSecure={onSecure}
            onContact={onContact}
          />
        )}
      />
    </ColumnFlex>
  );
};
