import * as React from 'react';
import { Button } from 'antd';
import { useQuery } from 'react-apollo';

import { Grid, AsyncContent, Text } from '@/components';
import { EntityOption } from '@/features';
import { styled } from '@/theme';
import { useModalsState } from '@/providers';
import { Maybe, Bid } from '@/shared/types/graphql';
import {
  stringifyNA,
  stringifyDate,
  stringifyTermsOfLease,
  stringifyRentAmount,
  getTimeToApproveOffer,
} from '@/shared/utils';

import { AnnouncingTitle } from '../../styled';
import { CONGRATULATIONS_BID_QUERY, CONGRATULATIONS_BID_MUTATION } from './queries';
import { useParams } from 'react-router-dom';
import { useMutationSafe } from '@/features/_hooks';

const CongratulationsTextContainer = styled.div``;

const Resume = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
  justify-content: center;
  align-items: center;

  > *:nth-child(odd) {
    text-align: end;
  }
`;

const TimerReminder = styled.div`
  font-weight: 600;
  font-size: 14px;

  .bid-timer-title {
    color: ${({ theme }) => theme.vars.primaryColor};
    margin-right: 10px;
  }
`;

export const Congratulations = ({ bidId, onContact }: { bidId?: Maybe<string>; onContact?: () => void }) => {
  const { offerRejectModal, agentContactModal } = useModalsState();

  const { id: offerId } = useParams();

  const [bidUpdate, { loading: bidUpdateLoading }] = useMutationSafe(CONGRATULATIONS_BID_MUTATION);

  const handleOfferReject = () => {
    if (bidId) {
      offerRejectModal.setProps({
        visible: true,
        bidId,
        offerId,
        options: {
          refetchQueries: ['CongratulationsBidQuery', 'SingleUnitOfferQuery'],
          awaitRefetchQueries: true,
        },
      });
    }
  };

  const onOfferAccept = () => {
    if (bidId) {
      bidUpdate({
        variables: {
          filter: {
            id: bidId,
          },
          data: {
            offerAccepted: true,
          },
        },
        refetchQueries: ['CongratulationsBidQuery', 'SingleUnitOfferQuery'],
      });
    }
  };

  const handleContactAgent =
    onContact ||
    (() => {
      if (bidId) {
        agentContactModal.setProps({
          visible: true,
          bidId,
        });
      }
    });

  const { data: bidResponse, loading: bidLoading } = useQuery<{ bid: Bid }>(CONGRATULATIONS_BID_QUERY, {
    variables: {
      id: bidId,
    },
    skip: !bidId,
  });

  const bid = bidResponse?.bid;

  const offer = bid?.offer;
  const singleUnit = offer?.activeOfferSingleUnit;
  const timeToApproveOffer = getTimeToApproveOffer(bid?.offer);

  return (
    <AsyncContent loading={bidLoading} height="300px">
      <Grid.Layout gap="20px" columns="1fr" style={{ marginTop: '20px' }}>
        <CongratulationsTextContainer>
          <AnnouncingTitle>Congratulations!</AnnouncingTitle>
          <AnnouncingTitle>It&apos;s time to secure your new home!</AnnouncingTitle>
        </CongratulationsTextContainer>
        <Resume>
          <EntityOption fragmented label="Property Name" value={stringifyNA(singleUnit?.name)} type="uppercase-label" />
          <EntityOption
            fragmented
            label="Rent Amount"
            value={stringifyRentAmount(bid?.rentAmount)}
            type="uppercase-label"
          />
          <EntityOption fragmented label="Move In Date" value={stringifyDate(bid?.moveinDate)} type="uppercase-label" />
          <EntityOption
            fragmented
            label="Lease"
            value={stringifyTermsOfLease(bid?.termsOfLease)}
            type="uppercase-label"
          />
        </Resume>
        <Grid.Layout gap="12px" columns="1fr">
          <Button type="primary" size="large" onClick={onOfferAccept} loading={bidUpdateLoading}>
            Accept Offer
          </Button>
          <Button size="large" onClick={handleOfferReject}>
            Reject
          </Button>
          <Button size="large" onClick={handleContactAgent}>
            Contact Agent
          </Button>
        </Grid.Layout>
        <TimerReminder>
          <span className="bid-timer-title">Important:</span>
          <Text type="subtitle">You have {timeToApproveOffer} left remaining to accept this offer and sign lease</Text>
        </TimerReminder>
      </Grid.Layout>
    </AsyncContent>
  );
};
