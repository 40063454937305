import { DateTime } from 'luxon';
import * as R from 'ramda';

import { BID_STATUSES } from '../constants';

const calculateVariationOfMoveinDate = ({ bidMoveInDate, offerRentAmount, offerTermsOfLease, offerMoveInDate }) => {
  const leaseExpirationDate = offerMoveInDate.plus({ months: offerTermsOfLease });
  const durationOfLease = leaseExpirationDate.diff(offerMoveInDate, 'days').toObject().days;
  const pricePerDay = (offerRentAmount * offerTermsOfLease) / durationOfLease;

  return pricePerDay * bidMoveInDate.diff(offerMoveInDate, 'days').toObject().days;
};

const calculateVariationOfTermsOfLease = ({
  bidTermsOfLease,
  offerRentAmount,
  offerTermsOfLease,
  weightingForLongerStay,
  weightingForShorterStay,
}) => {
  const weightingForDurationOfStay =
    bidTermsOfLease < offerTermsOfLease ? weightingForShorterStay : weightingForLongerStay;

  return ((bidTermsOfLease - offerTermsOfLease) * offerRentAmount * weightingForDurationOfStay) / 100;
};

// SERIESSUM(X; n; m; a) formula:
// a[1]*x^n + a[2]*x^(n+m) + ... + a[i]*x^(n+(i-1)*m), where i is the number of entries in array `a`
// in our case parameters equals to
// x = 1 + annualRentalIncrease / 100 (the input to the power series. Varies depending on the type of approximation, may be angle, exponent, or some other value)
// n = 1 (the initial power to which to raise x in the power series)
// m = 1 (the additive increment by which to increase x)
// a = 1 (even though in the true formula it should be an array in our case it always equals to 1)
// for more information have a look at this article:
// https://support.google.com/docs/answer/3093444?hl=en
const seriesSum = ({ numberOfPeriods, annualRentalIncrease }) => {
  if (numberOfPeriods < 0) {
    return 0;
  }

  let result = 0;
  const x = 1 + annualRentalIncrease / 100;

  for (let i = 0; i < numberOfPeriods; i++) {
    result += x ** (1 + 1 * i);
  }

  return result;
};

const calculateVariationOfRentAmount = ({
  bidRentAmount,
  bidTermsOfLease,
  offerTermsOfLease,
  weightingForRentalIncrease,
  annualRentalIncrease,
}) => {
  const numberOfPeriods = Math.ceil(bidTermsOfLease / offerTermsOfLease) - 1;
  const avgIncrese =
    numberOfPeriods > 0 ? seriesSum({ numberOfPeriods, annualRentalIncrease }) / numberOfPeriods - 1 : 0;

  // It seems that there is a mistake in the formula
  // Present day formula doesn't take into account rent amount increase if bidTermsOfLease === 12
  // But I couldn't explain it to my manager
  // That's how I think this formula should look like:
  // (avgIncrese * (bidRentAmount * bidTermsOfLease - offerRentAmount * offerTermsOfLease) * weightingForRentalIncrease) / 100
  return (avgIncrese * (bidRentAmount * (bidTermsOfLease - offerTermsOfLease)) * weightingForRentalIncrease) / 100;
};

export const calculateBidRank = (bid, offer) => {
  // ranking constants
  const weightingForLongerStay = !R.isNil(R.prop('weightingForLongerStay', offer))
    ? R.prop('weightingForLongerStay', offer)
    : 8;

  const weightingForShorterStay = !R.isNil(R.prop('weightingForShorterStay', offer))
    ? R.prop('weightingForShorterStay', offer)
    : 12;

  const weightingForRentalIncrease = !R.isNil(R.prop('weightingForRentalIncrease', offer))
    ? R.prop('weightingForRentalIncrease', offer)
    : 75;

  const annualRentalIncrease = !R.isNil(R.prop('annualRentalIncrease', offer))
    ? R.prop('annualRentalIncrease', offer)
    : 3;

  // bid params
  const bidRentAmount = R.prop('rentAmount', bid);
  const bidTermsOfLease = R.prop('termsOfLease', bid);
  const bidMoveInDate = R.prop('moveinDate', bid) && DateTime.fromISO(bid.moveinDate);
  const bidStatus = R.prop('status', bid);

  // offer params
  const offerRentAmount = R.prop('rentAmount', offer);
  const offerTermsOfLease = R.prop('termsOfLease', offer);
  const offerMoveInDate = R.prop('moveinDate', offer) && DateTime.fromISO(offer.moveinDate);

  //  if we have no full data that we are needed in for bid rank calculation we set value of rank to 0
  if (
    !(bidRentAmount && bidTermsOfLease && bidMoveInDate && offerRentAmount && offerTermsOfLease && offerMoveInDate) ||
    bidStatus === BID_STATUSES.rejected ||
    bidStatus === BID_STATUSES.canceled
  ) {
    return { rank: 0, debugPresentDaySystem: 0, debugV1: 0, debugV2: 0, debugV3: 0 };
  }

  // variations params
  // V1: Takes into account move-in date
  const variationOfMoveinDate = calculateVariationOfMoveinDate({
    bidMoveInDate,
    offerRentAmount,
    offerTermsOfLease,
    offerMoveInDate,
  });
  // V2: Takes into account weighting of Long/Short Term Lease
  const variationOfTermsOfLease = calculateVariationOfTermsOfLease({
    bidTermsOfLease,
    offerRentAmount,
    offerTermsOfLease,
    weightingForLongerStay,
    weightingForShorterStay,
  });
  // V3: Takes into account rent amount increase
  const variationOfRentAmount = calculateVariationOfRentAmount({
    bidRentAmount,
    bidTermsOfLease,
    offerRentAmount,
    offerTermsOfLease,
    weightingForRentalIncrease,
    annualRentalIncrease,
  });

  const debugPresentDaySystem = bidRentAmount * bidTermsOfLease;
  const debugV1 = bidRentAmount * bidTermsOfLease - variationOfMoveinDate;
  const debugV2 = bidRentAmount * bidTermsOfLease - variationOfMoveinDate + variationOfTermsOfLease;
  const debugV3 =
    bidRentAmount * bidTermsOfLease - variationOfMoveinDate + variationOfTermsOfLease + variationOfRentAmount;
  const rank =
    Math.round(
      ((bidRentAmount * bidTermsOfLease - variationOfMoveinDate + variationOfTermsOfLease + variationOfRentAmount) /
        bidTermsOfLease) *
        100,
    ) / 100;

  return {
    rank,
    debugPresentDaySystem,
    debugV1,
    debugV2,
    debugV3,
  };
};
