import { styled } from '@/theme';

export const PropertyHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  > *:first-child {
    color: #323c47 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    letter-spacing: 0.5px !important;
    line-height: 20px !important;
    margin-bottom: 20px !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > * {
    margin-bottom: 20px;
  }
`;

export const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;

  > button {
    padding: 0;
  }
`;

export const Info = styled.div`
  padding: 0 24px;
`;

export const MediaContainer = styled.span`
  position: relative;

  button.slick-arrow {
    background-color: transparent !important;
  }

  button.slick-arrow::before {
    color: white !important;
  }

  .slick-dots {
    display: none !important;
  }
`;

export const TimeAndOffersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 8px;
  border-radius: 1em;
`;
