import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Offer, OfferListResponse } from '@/shared/types/graphql';
import { RENTAL_OFFERS_LIST_QUERY } from '@/pages/CompanyLiveOffers/queries';
import { AsyncContent } from '@/components';
import {
  OffersContainer,
  PropertyCardsTag,
  OffersCardsEmptyTag,
  OffersCardsEmptyLogo,
  OffersCardsEmptyTitle,
} from '../styled';
import { CompanySingleUnits } from '@/pages/CompanyLiveOffers/constants';
import { ReactComponent as LivoSecureLogo } from '@/static/LivoSecureColor.svg';
import OfferCard from '@/pages/CompanyLiveOffers/components/OfferCard';
import { PropertyBidView } from '@/pages/Property/PropertyBidView';
import { AppRoutes } from '@/shared/constants/appRoutes';
import { useCurrentUser, useQueryParams } from '@/features/_hooks';
import { RenterContext } from '@/providers';
import Header from '@/pages/CompanyLiveOffers/components/Header';

export type PageState = {
  currentPage: CompanySingleUnits;
  offer?: Offer;
};

const Offers = () => {
  const [queryParams, setQueryParams] = useQueryParams<{
    sortBy: string;
    sortOrder: string;
  }>(100, {
    sortBy: 'createdAt',
    sortOrder: 'DESC',
  });
  const { id: companyId } = useParams<{ id: string }>();
  const { user } = useCurrentUser();
  const { anchorMenuData } = React.useContext(RenterContext);

  const [{ offer, currentPage }, setPageState] = React.useState<PageState>({
    currentPage: CompanySingleUnits.OFFERS_PAGE,
  });

  const { data, loading } = useQuery<{ offersList: OfferListResponse }>(RENTAL_OFFERS_LIST_QUERY, {
    skip: !queryParams?.sortBy,
    fetchPolicy: 'network-only',
    variables: {
      sort: {
        [queryParams.sortBy]: queryParams.sortOrder,
      },
      filter: { activeOfferSingleUnit: { company: { id: { equals: companyId } } }, status: { equals: 'live' } },
    },
  });

  const offers: Offer[] = data?.offersList?.items || [];

  const onCancel = React.useCallback(() => {
    setPageState({ currentPage: CompanySingleUnits.OFFERS_PAGE });
    anchorMenuData?.setAnchorMenuVisible(true);
  }, [anchorMenuData]);

  const renderContent = () => {
    switch (currentPage) {
      case CompanySingleUnits.OFFERS_PAGE:
        return (
          <OffersContainer>
            <Header setQueryParams={setQueryParams} queryParams={queryParams} />
            <PropertyCardsTag>
              <Choose>
                <When condition={!offers.length}>
                  <OffersCardsEmptyTag>
                    <LivoSecureLogo css={OffersCardsEmptyLogo} />
                    <OffersCardsEmptyTitle>
                      Sorry! We have no live transactions to show you at this moment
                    </OffersCardsEmptyTitle>
                  </OffersCardsEmptyTag>
                </When>
                <Otherwise>
                  {offers.map(offer => (
                    <OfferCard key={offer.id!} offer={offer} setPageState={setPageState} user={user} />
                  ))}
                </Otherwise>
              </Choose>
            </PropertyCardsTag>
          </OffersContainer>
        );
      case CompanySingleUnits.OFFER:
        return <PropertyBidView offer={offer as Offer} onCancel={onCancel} />;
      case CompanySingleUnits.SECURE_NOW:
        return <PropertyBidView secureNow offer={offer as Offer} onCancel={onCancel} />;
      default:
        return <Redirect to={AppRoutes.RENTER_NOT_FOUND_PAGE} />;
    }
  };

  return <AsyncContent loading={loading}>{renderContent()}</AsyncContent>;
};

export default Offers;
