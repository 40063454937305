import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { hot } from 'react-hot-loader/root';
import { AppProvider, PermissionsProvider } from '8base-react-sdk';
import { ApolloError } from 'apollo-client';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ApolloLink } from 'apollo-link';
import errorCodes from '@8base/error-codes';
import * as R from 'ramda';
import { sendToSentry } from '@/shared/sentry';
import { Result } from 'antd';

import { ThemeProvider, ModalProvider } from '@/providers';
import { TOAST_SUCCESS_MESSAGE, HIDE_TOAST_ERROR_MESSAGE } from '@/shared/constants';
import { authClient } from '@/shared/auth';
import { useCurrentUserBlockedSub } from '@/features/_hooks';
import { Loader, Grid } from '@/components';
import fragmentTypes from './shared/graphql/fragmentTypes.json';
import tablesListData from './shared/graphql/tableSchema.json';

import { Routes } from './Routes';

import './overrideAntd';
import { errorResolver } from './shared/graphql/errorResolver';

const { REACT_APP_API_ENDPOINT } = process.env as any;

const companyIdLink = new ApolloLink((operation, forward) => {
  const pathname = window.location.pathname;
  const [, rootRoute, id] = pathname.split('/');

  const isLocalhost = window.location.hostname === 'localhost';

  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      'custom-8base': JSON.stringify({
        ...(rootRoute === 'management' && !!id ? { companyId: id } : {}),
        clientType: isLocalhost ? 'localhost' : 'mobile',
      }),
    },
  }));

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return forward(operation);
});

const ApplicationContent = ({ appLoading, appError }: { appLoading: boolean; appError?: ApolloError }) => {
  // Fetch data now and store them to cache to use them later
  const { userData } = useCurrentUserBlockedSub();
  const { loading: userLoading } = userData;

  const loading = appLoading || userLoading;

  if (R.path(['graphQLErrors', 0, 'code'], appError) === errorCodes.InternalErrorCode) {
    return (
      <Grid.Layout stretch alignItems="center">
        <Result
          status="500"
          title="Internal Error"
          subTitle="Oops, an error has occurred! Don't worry, we've been reported about that."
        />
      </Grid.Layout>
    );
  }

  return loading ? <Loader stretch /> : <Routes />;
};

const NotHotApp = () => {
  // eslint-disable-next-line no-console
  console.warn('MOBILE APP');

  const onRequestSuccess = ({ operation }: any) => {
    const context = operation.getContext();
    const message = context[TOAST_SUCCESS_MESSAGE];

    if (message) {
      toast.success(message, context.toastOpts);
    }
  };

  const onRequestError = ({ graphQLErrors, operation }: any) => {
    const hasGraphQLErrors = Array.isArray(graphQLErrors) && graphQLErrors.length > 0;

    const context = operation.getContext();
    const hideToastErrorMessage = context[HIDE_TOAST_ERROR_MESSAGE];

    if (R.path([0, 'code'], graphQLErrors) === errorCodes.InternalErrorCode) {
      // eslint-disable-next-line no-console
      console.error(errorCodes.InternalErrorCode, graphQLErrors[0]);
    } else if (!hideToastErrorMessage && hasGraphQLErrors) {
      graphQLErrors.forEach((error: any) => {
        errorResolver(error);
      });
    }

    sendToSentry('graphQL-onRequestError', { graphQLErrors, operation });
  };
  const tablesList = tablesListData?.system?.tablesList?.items;

  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider>
          <AppProvider
            uri={REACT_APP_API_ENDPOINT}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            authClient={authClient}
            onRequestSuccess={onRequestSuccess}
            onRequestError={onRequestError}
            extendLinks={links => [companyIdLink, ...links]}
            withSubscriptions
            introspectionQueryResultData={fragmentTypes}
            tablesList={tablesList as any}
          >
            {({ loading, error }) => (
              <PermissionsProvider type="user">
                <ModalProvider>
                  <ApplicationContent appLoading={loading} appError={error} />
                </ModalProvider>
              </PermissionsProvider>
            )}
          </AppProvider>
          <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        </ThemeProvider>
      </DndProvider>
    </BrowserRouter>
  );
};

export const App = hot(NotHotApp);
