import React from 'react';
import { Modal } from 'antd';

import { useModalsState } from '@/providers';
import { AgentInformation } from '@/features';
import { Scrollable } from '@/components';

type AgentContactModalProps = {
  visible?: boolean;
  options?: {
    awaitRefetchQueries?: boolean;
    refetchQueries: Array<string>;
  };
  bidId?: string;
};

export const AgentContactModal = ({ visible, bidId }: AgentContactModalProps) => {
  const { agentContactModal } = useModalsState();

  return (
    <Modal
      title="Contact Agent"
      className="livo-modal"
      visible={visible}
      onCancel={agentContactModal.close}
      width="90%"
      style={{ maxWidth: 600 }}
      centered
      footer={null}
    >
      <Scrollable padding="20px">
        <AgentInformation bidId={bidId} />
      </Scrollable>
    </Modal>
  );
};
