import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { GuestRoute } from '@/features/_routing/GuestRoute';

import { AuthContainer } from './routes/root';
import { ConfirmedContainer } from './routes/confirmed';
import { CallbackContainer } from './routes/callback';
import { SignUp } from './routes/signup';
import { Confirmation } from './routes/confirmation';

export const Auth = () => (
  <>
    <Switch>
      <Route exact path="/auth" component={AuthContainer} />
      <Route exact path="/auth/confirmed" component={ConfirmedContainer} />
      <Route path="/auth/callback" component={CallbackContainer} />
      <GuestRoute exact path="/auth/signup" component={SignUp} />
      <Route exact path="/auth/confirmation" component={Confirmation} />
      <Redirect to="/auth" />
    </Switch>
  </>
);
