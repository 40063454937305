import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQueryParams } from './useQueryParams';

type InitialQueryParams = {
  sortBy?: string;
  sortOrder?: 'ascend' | 'descend';
};

export const useSearch = (
  initial: InitialQueryParams = {},
): [{ search: string; throttledSearch: string }, (value?: string) => void] => {
  const [queryParams, setQueryParams] = useQueryParams(1000);

  React.useEffect(() => {
    if (isEmpty(queryParams)) {
      setQueryParams({
        sortBy: 'updatedAt',
        sortOrder: 'descend',
        ...initial,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search, setSearch] = React.useState(queryParams.query ? String(queryParams.query) : '');

  const setQuery = React.useCallback(
    (value?: any) => {
      setSearch(value);
      setQueryParams({ ...queryParams, query: value || undefined, page: '1' });
    },
    [queryParams, setQueryParams],
  );

  const throttledSearch = queryParams.query as string;

  return [{ search, throttledSearch }, setQuery];
};
