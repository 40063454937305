export const DRAFT_STATUS = 'draft';
export const NOT_AVALIABLE = 'N/A';
export const TOAST_SUCCESS_MESSAGE = 'TOAST_SUCCESS_MESSAGE';
export const HIDE_TOAST_ERROR_MESSAGE = 'HIDE_TOAST_ERROR_MESSAGE';
export const ERROR_CODES = {
  CommonErrorCode: 'CustomFunctionError',
  ValidationErrorCode: 'CustomFunctionValidationError',
  ImportValidationErrorCode: 'CustomFunctionImportValidationError',
  InternalErrorCode: 'CustomFunctionInternalError',
};
