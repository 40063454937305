import React from 'react';
import { styled } from '@/theme';
import { Icon } from 'antd';
import * as R from 'ramda';

import { SingleUnit } from '@/shared/types/graphql';
import { NEW_AMENITY_GROUPS } from '@/shared/constants';

export const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 30px;
  }
`;

export const Amenity = styled.div`
  display: grid;
  grid-template-columns: 22px auto;
  grid-template-rows: auto 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
`;

export const SectionHeading = styled.h2`
  color: #323c47;
  font-weight: 500 !important;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const SectionInfoValue = styled.span`
  line-height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #323c47;
`;

export const SectionAmenitiesListContainer = styled.div`
  grid-column: -1/1;
`;

export const AmenityItem = styled.span`
  font-size: 1.2rem;
`;

type PropertyAmenitiesProps = {
  singleUnit: SingleUnit;
};

const getGroupedAmenities = (amenityListResponse?: any): Array<any> => {
  const filteredAmenities: any = { ...amenityListResponse };
  const groupedAmenities: any = [];
  for (const key in filteredAmenities) {
    if (!filteredAmenities[key] || !Array.isArray(filteredAmenities[key])) {
      delete filteredAmenities[key];
    } else {
      groupedAmenities.push({
        groupKey: key,
        groupName: NEW_AMENITY_GROUPS[key]?.label,
        groupIcon: NEW_AMENITY_GROUPS[key]?.icon,
        amenitiesList: filteredAmenities[key],
      });
    }
  }
  return groupedAmenities.filter(Boolean) || [];
};

export const PropertyAmenities: React.FC<PropertyAmenitiesProps> = ({ singleUnit }) => {
  const unitAmenities: any = singleUnit?.singleUnitAmenity;
  const communityAmenities: any = singleUnit?.community?.communityAmenity;

  const groupedUnitAmenities = getGroupedAmenities(unitAmenities);
  const groupedCommunityAmenities = getGroupedAmenities(communityAmenities);

  const amenities = React.useMemo(
    function renderAmenities() {
      if (!R.isEmpty(groupedUnitAmenities) && !R.isEmpty(groupedCommunityAmenities)) {
        return (
          <>
            <SectionHeading>Amenities</SectionHeading>
            <AmenitiesContainer>
              {groupedCommunityAmenities
                .concat(groupedUnitAmenities)
                .map(({ amenitiesList, groupIcon, groupName, groupKey }) => (
                  <Amenity key={groupKey}>
                    <Icon component={groupIcon} style={{ fontSize: '2.4rem', marginRight: 10 }} />
                    <SectionInfoValue>{groupName}</SectionInfoValue>
                    <SectionAmenitiesListContainer>
                      <AmenityItem>{amenitiesList.join(', ')}</AmenityItem>
                    </SectionAmenitiesListContainer>
                  </Amenity>
                ))}
            </AmenitiesContainer>
          </>
        );
      }
    },
    [groupedUnitAmenities, groupedCommunityAmenities],
  );

  return <React.Fragment>{amenities}</React.Fragment>;
};
