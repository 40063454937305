import React from 'react';
import { Property } from './Property';
import { RenterLayout } from '../_layouts/RenterLayout';

export default function ProperyPage() {
  return (
    <RenterLayout padding="none">
      <Property />
    </RenterLayout>
  );
}
