import React from 'react';
import { Carousel } from 'antd';
import Maybe from 'graphql/tsutils/Maybe';
import { File, User } from '@/shared/types/graphql';
import ImagesCarousel, { Modal, ModalGateway, ViewType } from 'react-images/lib/components';
import {
  CarouselImage,
  CarouselImageWrapper,
  CarouselWrapper,
  Sizes,
  CarouselThumbImageWrapper,
  CarouseThumbWrapper,
  CarouselContainer,
} from '@/components/MediaGallery/styled';
import { useModalsState } from '@/providers';
import { Button } from 'livo-components/src/components';
import { VideoIcon, CalendarFilledIcon } from '@/static/icons';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { getCompressedImageUrl } from '@/shared/utils';
import { isYoutubeLink } from 'livo-shared/src';

type MediaGalleryProps = {
  mediaList?: File[];
  width?: Sizes;
  height?: Sizes;
  objectFit?: string;
  withThumbs?: boolean;
  withModal?: boolean;
  virtualTour?: Maybe<string>;
  calendarLink?: Maybe<string>;
  contact?: Maybe<User>;
  loading?: boolean;
};

interface VirtualTourModalProps {
  visible: boolean;
  virtualTour: string;
}

type VirtualTourProps = {
  virtualTour: string;
  setModalProps: (virtualTourModalProps: VirtualTourModalProps) => void;
};

const VirtualTourButton: React.FC<VirtualTourProps> = ({ virtualTour, setModalProps }) => (
  <Button type="primary" icon={VideoIcon} onClick={() => setModalProps({ visible: true, virtualTour })}>
    Virtual Tour
  </Button>
);

const VirtualTourLink: React.FC<{ virtualTour: string }> = ({ virtualTour }) => (
  <a href={virtualTour} target="_blank" rel="noopener noreferrer">
    <Button type="primary" icon={VideoIcon}>
      Virtual Tour
    </Button>
  </a>
);

const useSliderRefs = () => {
  const [sliderSlick, setSliderSlick]: any = React.useState();
  const [thumbsSliderSlick, setThumbsSliderSlick]: any = React.useState();

  const sliderSlickRef: any = React.useRef();
  const thumbsSliderRef: any = React.useRef();

  const slick = sliderSlickRef?.current?.slick;
  const thumbsSlick = thumbsSliderRef?.current?.slick;

  React.useEffect(() => {
    setSliderSlick(sliderSlickRef?.current?.slick);
    setThumbsSliderSlick(thumbsSliderRef?.current?.slick);
  }, [slick, thumbsSlick]);

  return {
    slider: { slick: sliderSlick, ref: sliderSlickRef },
    thumbsSlider: { slick: thumbsSliderSlick, ref: thumbsSliderRef },
  };
};

export const MediaGallery: React.FC<MediaGalleryProps> = ({
  withThumbs = false,
  mediaList = [],
  width,
  height,
  objectFit = 'cover',
  withModal = true,
  virtualTour,
  calendarLink,
  contact,
  loading,
}) => {
  const { slider, thumbsSlider } = useSliderRefs();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { virtualTourModal, calendarModal, scheduleShowingContactModal } = useModalsState();

  const sliderSlickSlickToGo = React.useCallback(
    (index: number) => () => {
      slider.slick.slickGoTo(index);
    },
    [slider.slick],
  );

  const rawMediaList = [...mediaList];
  const formattedMediaList = rawMediaList.map((item: any) => {
    const rawImgItem = { ...item };
    const rawImgUrl = rawImgItem?.downloadUrl;

    const compressedImgUrl = getCompressedImageUrl(rawImgUrl);
    return { ...rawImgItem, compressedImgUrl };
  });

  const handleOpenModal = React.useCallback(() => setModalIsOpen(true), [setModalIsOpen]);
  const handleCloseModal = React.useCallback(() => setModalIsOpen(false), [setModalIsOpen]);

  const handleOpenCalendly = () => calendarModal.setProps({ visible: true, calendarLink });
  const handleOpenScheduleShowingContact = () =>
    scheduleShowingContactModal.setProps({ visible: true, contact, loading });

  const IsCalendarLinkExist = Boolean(calendarLink);

  return (
    <CarouselContainer>
      <CarouselWrapper width={width} height={height}>
        <Carousel arrows swipeToSlide ref={slider.ref} asNavFor={thumbsSlider.slick}>
          <Choose>
            <When condition={formattedMediaList.length > 0}>
              {formattedMediaList.map(item => (
                <CarouselImageWrapper key={item?.compressedImgUrl ?? ''} width={width} height={height}>
                  <CarouselImage src={item?.compressedImgUrl ?? ''} objectFit={objectFit} onClick={handleOpenModal} />
                </CarouselImageWrapper>
              ))}
            </When>
            <Otherwise>
              <CarouselImageWrapper width={width} height={height}>
                <CarouselImage src={MediaGalleryPlaceholder} objectFit={objectFit} />
              </CarouselImageWrapper>
            </Otherwise>
          </Choose>
        </Carousel>
      </CarouselWrapper>
      {withThumbs && (
        <CarouseThumbWrapper>
          <Carousel
            swipeToSlide
            ref={thumbsSlider.ref}
            slidesToShow={4}
            dots={false}
            focusOnSelect
            centerMode
            centerPadding="0"
            arrows
            infinite
          >
            {(formattedMediaList.length >= 4
              ? formattedMediaList
              : [...formattedMediaList, ...new Array(4 - formattedMediaList.length)]
            ).map((item, index) => (
              <CarouselThumbImageWrapper key={item?.compressedImgUrl ?? index} width={width} height={height}>
                {item && (
                  <CarouselImage
                    src={item.compressedImgUrl ?? ''}
                    objectFit={objectFit}
                    onClick={sliderSlickSlickToGo(index)}
                  />
                )}
              </CarouselThumbImageWrapper>
            ))}
          </Carousel>
        </CarouseThumbWrapper>
      )}
      {withModal && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={handleCloseModal}>
              <ImagesCarousel
                views={
                  (formattedMediaList?.map(item => ({
                    src: item?.downloadUrl,
                    thumbnail: item?.downloadUrl,
                  })) as unknown) as ViewType[]
                }
                currentIndex={slider.slick?.innerSlider?.state?.currentSlide}
                trackProps={{
                  infinite: true,
                }}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
      {virtualTour && (
        <div css={{ textAlign: 'center', marginTop: 25 }}>
          {isYoutubeLink(virtualTour) ? (
            <VirtualTourButton virtualTour={virtualTour} setModalProps={virtualTourModal.setProps} />
          ) : (
            <VirtualTourLink virtualTour={virtualTour} />
          )}
        </div>
      )}
      <div css={{ textAlign: 'center', marginTop: 25 }}>
        <Button
          type="primary"
          icon={CalendarFilledIcon}
          onClick={IsCalendarLinkExist ? handleOpenCalendly : handleOpenScheduleShowingContact}
        >
          Schedule Showing
        </Button>
      </div>
    </CarouselContainer>
  );
};
