import styled from '@/theme/styled';

export type Sizes = {
  xs?: string;
  lg?: string;
};

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 8px;
  }

  .ant-carousel {
    .slick-dots {
      li {
        margin: 0 4px !important;

        button {
          background-color: #fff !important;
          height: 10px !important;
          width: 10px !important;
          opacity: 1 !important;
          border: 1px solid ${props => props.theme.vars.primaryColor} !important;
          border-radius: 50% !important;

          &:hover {
            opacity: 1 !important;
          }
        }
      }

      li.slick-active button {
        transform: scale(1.4);
        background-color: ${props => props.theme.vars.primaryColor} !important;
      }
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      height: 24px;
      width: 24px;
      opacity: 0.8;
      z-index: 1;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        box-shadow: inset 0 1px 3px -2px #000000;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .slick-prev {
      left: 10px;

      &:before {
        content: '<';
        font-size: 24px;
        line-height: 1;
        color: #ffffff;
        font-weight: 500;
      }
    }

    .slick-next {
      right: 10px;

      &:before {
        content: '>';
        font-size: 24px;
        line-height: 1;
        color: #ffffff;
        font-weight: 500;
      }
    }

    .slick-dots-bottom {
      bottom: 26px !important;
    }
  }

  @media (max-width: ${props => props.theme.vars.breakpoints.md.max}) {
    & > :first-child {
      margin-bottom: 0;
    }
  }
`;

export const CarouselWrapper = styled.div<{ width?: Sizes; height?: Sizes }>`
  position: relative;
  width: ${props => props?.width?.lg || '100%'};
  height: ${props => props?.height?.lg || '400px'};

  @media (max-width: 767px) {
    width: ${props => props?.width?.xs || '100%'};
    height: ${props => props?.height?.xs || '300px'};
  }
`;

export const CarouselImageWrapper = styled.div<{ width?: Sizes; height?: Sizes }>`
  width: ${props => props?.width?.lg || '100%'};
  height: ${props => props?.height?.lg || '400px'};

  @media (max-width: 767px) {
    width: ${props => props?.width?.xs || '100%'};
    height: ${props => props?.height?.xs || '300px'};
  }
`;

export const CarouselImage = styled.img<{ objectFit: string }>`
  object-fit: ${props => props.objectFit};
  height: 100%;
  width: 100%;
`;

export const CarouseThumbWrapper = styled.div`
  position: relative;
  width: calc(100% + 8px);

  margin-left: -4px;
  margin-right: -4px;

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    display: none;
  }
`;

export const CarouselThumbImageWrapper = styled.div<{ width?: Sizes; height?: Sizes }>`
  height: 100px;
  padding: 0 4px;

  & img {
    cursor: pointer;
    border-radius: 5px;
  }

  @media (max-width: 767px) {
  }
`;
