import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@/theme/styled';
import { useAuth } from '8base-react-sdk';
import { AppRoutes } from '@/shared/constants/appRoutes';

const MobileNavLink = styled(Link)`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.vars.black};
  display: block;
  padding: 10px 0;
`;

const MobileNavListTag = styled.ul`
  position: absolute;
  z-index: 99;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  margin: 0 !important;
  padding: 24px;
  background-color: #fff;
`;

const MobileNavListItem = styled.li``;

type MobileNavListProps = {
  isRenter: boolean;
  handleToggleNav: () => void;
};

export const MobileNavList = ({ isRenter, handleToggleNav }: MobileNavListProps) => {
  const auth = useAuth();

  const handleLogout = async () => {
    localStorage.clear();
    await auth.authClient.logout();
    await auth.authClient.purgeState();
  };

  return (
    <MobileNavListTag>
      <MobileNavListItem>
        <MobileNavLink to={AppRoutes.RENTER_RENTALS} onClick={handleToggleNav}>
          Livo Transactions
        </MobileNavLink>
      </MobileNavListItem>
      <MobileNavListItem>
        <MobileNavLink to={AppRoutes.RENTER_OFFERS} onClick={handleToggleNav}>
          My Rentals
        </MobileNavLink>
      </MobileNavListItem>
      <MobileNavListItem>
        <MobileNavLink to={AppRoutes.RENTER_ACCOUNT} onClick={handleToggleNav}>
          My Account
        </MobileNavLink>
      </MobileNavListItem>
      <MobileNavListItem>
        <MobileNavLink to="/logging-out" onClick={handleLogout}>
          Logout
        </MobileNavLink>
      </MobileNavListItem>
    </MobileNavListTag>
  );
};
