import React from 'react';
import { Maybe } from '@/shared/types/graphql';

export const RenterContext = React.createContext<{
  // eslint-disable-next-line @typescript-eslint/ban-types
  onLogin?: Function;
  anchorMenuData?: {
    overviewRef: Maybe<React.RefObject<HTMLDivElement>>;
    detailsRef: Maybe<React.RefObject<HTMLDivElement>>;
    amenitiesRef: Maybe<React.RefObject<HTMLDivElement>>;
    setLinkState: React.Dispatch<React.SetStateAction<'none' | 'overview' | 'details' | 'amenities'>>;
    setAnchorMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  };
}>({});
