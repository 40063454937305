import React from 'react';
import DOMPurify from 'dompurify';

import { PhoneLink, WebsiteLink, FlexLayout } from '@/components';
import { SingleUnit, Maybe } from '@/shared/types/graphql';
import { stringifyAddress, stringifyNA } from '@/shared/utils';
import { COMPANY_TYPES } from '@/shared/constants';
import { useCompanyTypeCheck } from '@/features/_hooks';
import { DOMPURIFY_CONFIG } from 'livo-shared';

import { Resume, UppercaseLabel, SectionHeading, SectionInfo, PropertyLogo, Description } from './styled';
import { EntityOption } from '@/features';

type PropertyDetailsProps = {
  singleUnit?: Maybe<SingleUnit>;
};

export const PropertyDetails: React.FC<PropertyDetailsProps> = ({ singleUnit }) => {
  const communityLogo = singleUnit?.community?.logo;
  const companyLogo = singleUnit?.company?.companyLogo;
  const isMdu = useCompanyTypeCheck(COMPANY_TYPES.mdu);

  const description = singleUnit?.description;
  const sanitizedDescriptionHtml = React.useMemo(() => DOMPurify.sanitize(description as string, DOMPURIFY_CONFIG), [
    description,
  ]);

  return (
    <React.Fragment>
      <SectionHeading>Property Details</SectionHeading>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: stringifyNA(sanitizedDescriptionHtml) }} />
      </Description>

      <Resume>
        {communityLogo?.downloadUrl ? (
          <FlexLayout align="center">
            <UppercaseLabel>listed by</UppercaseLabel>
            <PropertyLogo src={communityLogo?.downloadUrl} />
          </FlexLayout>
        ) : companyLogo?.downloadUrl ? (
          <FlexLayout align="center">
            <UppercaseLabel>listed by</UppercaseLabel>
            <PropertyLogo src={companyLogo?.downloadUrl} />
          </FlexLayout>
        ) : null}
      </Resume>

      <SectionInfo>
        <EntityOption type="uppercase-label" label="Address" value={stringifyAddress(singleUnit?.address)} />
        {isMdu && (
          <EntityOption type="uppercase-label" label="Property" value={stringifyNA(singleUnit?.community?.name)} />
        )}
        <EntityOption
          type="uppercase-label"
          label="Website"
          value={<WebsiteLink website={singleUnit?.community?.website || singleUnit?.website} />}
        />
        <EntityOption
          type="uppercase-label"
          label="Phone"
          value={<PhoneLink phone={singleUnit?.community?.phoneNumber || singleUnit?.phone} />}
        />
      </SectionInfo>
    </React.Fragment>
  );
};
