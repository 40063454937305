import React from 'react';
import { Offers as OfferList } from './Offers';
import { RenterLayout } from '../_layouts/RenterLayout';

export default function OffersPage() {
  return (
    <RenterLayout>
      <OfferList />
    </RenterLayout>
  );
}
