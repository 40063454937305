import React from 'react';
import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import { TableQueryParams, useQueryParams, SetQueryParamsHandler } from '@/features/_hooks';

import * as R from 'ramda';

import { styled } from '@/theme';

const TableContainer = styled.div`
  height: 100%;

  .ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        .ant-table {
          height: calc(100% - 68px);
        }
      }
    }
  }
`;

export const Table = (props: TableProps<any>) => {
  const [queryParams, setQueryParams]: [TableQueryParams, SetQueryParamsHandler] = useQueryParams();

  const dataSource = props?.dataSource;
  const pagination = props?.pagination;

  React.useEffect(() => {
    if (R.isEmpty(dataSource) && pagination) {
      const { current } = pagination;
      const { page } = queryParams;

      if (current && current > 1 && page) {
        setQueryParams({ page: page - 1 });
      }
    }
  }, [dataSource, pagination, queryParams, setQueryParams]);

  return (
    <TableContainer>
      <AntTable {...props} />
    </TableContainer>
  );
};
