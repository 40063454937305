import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { AmenityGroupListResponse } from '@/shared/types/graphql';
import { AMENITY_GROUPS, AMENITY_LABELS } from '@/shared/constants';
import { GroupOptionItem } from '@/components/SelectField/SelectField';

export const AMENITY_GROUPS_LIST_QUERY = gql`
  query AmenityGroupsList {
    amenityGroupsList {
      items {
        id
        key
        amenity {
          items {
            id
            key
          }
        }
      }
    }
  }
`;

export type AmenityGroupOptionItem = GroupOptionItem & {
  groupKey: string;
};

export type AmenityGroupOptionsList = Array<AmenityGroupOptionItem>;

type AmenityGroupsListQuery = {
  amenityGroupsList: AmenityGroupListResponse;
};

export type AmenityMap = Record<
  string,
  {
    id: string;
    key: string;
    groupKey: string;
  }
>;

export const useAmenityGroups = (): {
  groupedOptions: AmenityGroupOptionsList;
  amenityMap: AmenityMap;
  data?: AmenityGroupsListQuery;
  loading: boolean;
} => {
  const { data, loading } = useQuery<AmenityGroupsListQuery>(AMENITY_GROUPS_LIST_QUERY);

  const amenityMap: AmenityMap = {};

  const groupedOptions =
    data?.amenityGroupsList?.items.map(amenityGroup => {
      const groupKey = amenityGroup?.key || '';

      const options =
        amenityGroup?.amenity?.items.map(amenity => {
          const amenityKey = amenity?.key || '';
          const value = amenity?.id || '';

          amenityMap[value] = {
            id: value,
            key: amenityKey,
            groupKey,
          };

          return {
            value,
            label: AMENITY_LABELS[amenityKey],
          };
        }) || [];

      return {
        groupName: AMENITY_GROUPS[groupKey].label,
        groupKey,
        options,
      };
    }) || [];

  return { groupedOptions, amenityMap, data, loading };
};
