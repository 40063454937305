import React from 'react';

import { Offer } from '@/shared/types/graphql';
import { Icon } from 'antd';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { getCompressedImageUrl, stringifyAddress } from '@/shared/utils';
import { ColumnFlex, RowFlex, Text, Tag, Timer } from '@/components';
import { styled } from '@/theme';
import pluralize from 'pluralize';
import { BidOffersTimeline } from '@/features';
import { BID_STATUSES, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { Link, useHistory } from 'react-router-dom';
import { useOfferBidCombinedStatus } from '@/features/_hooks';
import { ChevronRight } from '@/static/icons';
import { getSingleRentalBiddingUrl, getSingleRentalUrl } from '@/shared/constants/appRoutes';

const CardWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  border-radius: 4px;
  height: auto;
  min-width: auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  & > * {
    height: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.8;

    display: ${props => (props.disabled ? 'block' : 'none')};
  }
`;

const OfferHeaderTag = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  max-height: 100px;
  margin: 20px 20px 0 20px;
  border-radius: 6px;
  background-color: #f1f1f1;
  overflow: hidden;
  min-height: 93px;
`;

const OfferInfoColumnTag = styled(ColumnFlex)`
  padding: 12px;
  overflow: hidden;

  > *:not(:first-child) {
    > * {
      font-size: 13px !important;
      height: auto;
    }
  }
`;

const OfferImageWrapperTag = styled.div`
  position: relative;
`;

const OffersCountPositionTag = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 1;
`;

const OfferImageTag = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
`;

const OfferDetailsLink = styled(Link)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e3e3;
  padding: 12px 0;
  width: 100%;
  color: #939393;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  > *:first-child {
    margin-left: 16px;
  }

  > *:last-child {
    margin-right: 16px;
    color: ${props => (props.disabled ? 'gray' : '#f37a22')};
  }
`;

const TimeLineContainer = styled.div`
  > * {
    justify-content: center !important;
    align-items: stretch !important;
    padding: 20px !important;
  }

  > * > * {
    padding: 0 !important;
    margin: 0 !important;
  }
`;

type OfferCardProps = {
  offer: Offer;
};

export const OfferCard: React.FC<OfferCardProps> = ({ offer }) => {
  const bidsList = offer?.bid?.items || [];
  const filteredBids = bidsList.filter(bid => bid?.status !== BID_STATUSES.rejected);
  const history = useHistory();

  const singleUnit = offer?.historyOfferSingleUnit;
  const mediaImageUrl = singleUnit?.singleUnitHasMedia?.items[0]?.media?.file?.downloadUrl;
  const compressedImage = getCompressedImageUrl(mediaImageUrl);

  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);

  const statusTagPresentation = React.useMemo(
    function renderStatus() {
      switch (offerBidCombinedStatus.kind) {
        case OFFER_COMBINED_STATUSES.rejected:
          return (
            <Tag color="red" type="raised">
              Rejected
            </Tag>
          );
        case OFFER_COMBINED_STATUSES.awarded:
          return (
            <Tag color="green" type="raised">
              Awarded
            </Tag>
          );
        case OFFER_COMBINED_STATUSES.leased:
          return (
            <Tag color="red" type="raised">
              Leased
            </Tag>
          );
        case OFFER_COMBINED_STATUSES.canceled:
          return (
            <Tag color="red" type="raised">
              Canceled
            </Tag>
          );
        case OFFER_COMBINED_STATUSES['you-accept']:
          return (
            <Tag color="orange" type="raised">
              Accept
            </Tag>
          );
        case OFFER_COMBINED_STATUSES['you-won']:
        case OFFER_COMBINED_STATUSES['you-secured']:
          return (
            <Tag color="green" type="raised">
              You won
            </Tag>
          );
        case OFFER_COMBINED_STATUSES['ended']:
          return (
            <Tag color="red" type="raised">
              Ended
            </Tag>
          );
        default:
          return (
            <Tag color="green" type="raised">
              {filteredBids.length} {pluralize('offer', filteredBids.length)}
            </Tag>
          );
      }
    },
    [offerBidCombinedStatus, filteredBids],
  );

  const buttonText = React.useMemo(
    function renderText() {
      switch (offerBidCombinedStatus.kind) {
        case OFFER_COMBINED_STATUSES['you-accept']:
          return 'Accept offer';
        case OFFER_COMBINED_STATUSES['you-won']:
        case OFFER_COMBINED_STATUSES['you-secured']:
          return 'View checklist';
        default:
          return 'View offer details';
      }
    },
    [offerBidCombinedStatus],
  );

  return (
    <CardWrapper disabled={offerBidCombinedStatus.offerCardDisabled}>
      <If condition={!!singleUnit}>
        <OfferHeaderTag onClick={() => history.push(getSingleRentalUrl(offer?.id))}>
          <OfferImageWrapperTag>
            <OfferImageTag src={compressedImage || MediaGalleryPlaceholder} />
            <OffersCountPositionTag>{statusTagPresentation}</OffersCountPositionTag>
          </OfferImageWrapperTag>
          <OfferInfoColumnTag>
            <Text type="title" ellipsis={true}>
              {singleUnit?.name}
            </Text>
            <Text ellipsis={true}>{stringifyAddress(singleUnit?.address)}</Text>
            <If condition={offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.live}>
              <RowFlex align="center">
                <Timer endsDate={offer?.endsIn} icon={false} outline={false} />
              </RowFlex>
            </If>
          </OfferInfoColumnTag>
        </OfferHeaderTag>
      </If>
      <TimeLineContainer>
        <BidOffersTimeline
          timeline="simple"
          offer={offer}
          bids={filteredBids}
          isUserRejected={offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.rejected}
          size="sm"
        />
      </TimeLineContainer>
      {offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.leased ||
      offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.canceled ||
      offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.rejected ||
      offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.ended ? (
        undefined
      ) : (
        <OfferDetailsLink to={getSingleRentalBiddingUrl(offer?.id)}>
          <span>{buttonText}</span>
          <Icon component={ChevronRight} css={{ fontSize: 16 }} />
        </OfferDetailsLink>
      )}
    </CardWrapper>
  );
};
