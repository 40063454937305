import { SmartPhone, Maybe } from '../types/graphql';
import { NOT_AVALIABLE } from '../constants';

export const stringifyPhone = (phone?: Maybe<SmartPhone>) => {
  if (!phone) {
    return NOT_AVALIABLE;
  }

  const { code, number } = phone;

  return code ? `${code} ${number}` : number;
};
