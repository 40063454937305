import React from 'react';
import { DatePicker, TimePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { TimePickerProps } from 'antd/lib/time-picker';
import moment, { Moment } from 'moment';
import styled from '@emotion/styled';

import { Maybe } from '@/shared/types/graphql';
import { FormField } from '@/components';
import { MetaType, InputType } from '@/shared/types';
import { omit } from 'ramda';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 11px;
  width: 100%;
`;

type GeneralProps = {
  label?: React.ReactNode;
  note?: string;
  width?: string | number;
  stretch?: boolean;
};

type DateAndTimePickerFieldProps = {
  input: InputType;
  meta: MetaType;
  datePickerProps: DatePickerProps & GeneralProps;
  timePickerProps: TimePickerProps & GeneralProps;
  disabledAfterDate?: string;
  disabledBeforeDate?: string;
  minutesCorrection?: number;
  timezone?: string;
  'data-e2e-id'?: string;
};

export const DateAndTimePickerField = ({
  input,
  meta,
  datePickerProps,
  timePickerProps,
  disabledAfterDate,
  disabledBeforeDate,
  minutesCorrection = 10,
  timezone = moment.tz.guess(),
  ...rest
}: DateAndTimePickerFieldProps) => {
  const { name, value, onChange } = input;

  const DatePickerWidth = datePickerProps.stretch ? '100%' : datePickerProps.width ? datePickerProps.width : 150;
  const TimePickerWidth = timePickerProps.stretch ? '100%' : timePickerProps.width ? timePickerProps.width : 150;

  const handleChange = (date: Moment | null) => {
    const currentDate = date
      ? date
          .clone()
          .tz(timezone)
          .seconds(0)
      : date;

    if (!value && currentDate && disabledAfterDate) {
      const afterDate = moment(disabledAfterDate);
      currentDate.hours(afterDate.hours());
      currentDate.minutes(afterDate.minutes() - minutesCorrection);
    }

    if (!value && currentDate && disabledBeforeDate) {
      const beforeDate = moment(disabledBeforeDate);
      currentDate.hours(beforeDate.hours());
      currentDate.minutes(beforeDate.minutes() + minutesCorrection);
    }

    const newValue = currentDate ? currentDate.toISOString() : currentDate;

    onChange && onChange(newValue);
  };

  const { label: datePickerLabel, note: datePickerNote, ...datePickerRest } = datePickerProps;
  const { label: timePickerLabel, note: timePickerNote, ...timePickerRest } = timePickerProps;

  const disabledDate = (currentDate: Maybe<Moment>): boolean => {
    if (!currentDate && !disabledAfterDate && !disabledBeforeDate) {
      return false;
    }

    if (currentDate && (disabledBeforeDate || disabledAfterDate)) {
      const afterDate = moment(disabledAfterDate);
      const beforeDate = moment(disabledBeforeDate);

      if (disabledBeforeDate && disabledAfterDate) {
        return currentDate.valueOf() < beforeDate.valueOf() || currentDate.valueOf() > afterDate.valueOf();
      }

      if (disabledBeforeDate) {
        if (!value) {
          currentDate.hours(beforeDate.hours()).minutes(beforeDate.minutes() + minutesCorrection);
        }

        currentDate.second(0);

        return currentDate.valueOf() < beforeDate.valueOf();
      } else {
        if (!value) {
          currentDate.hours(afterDate.hours()).minutes(afterDate.minutes() - minutesCorrection);
        }

        currentDate.second(0);

        return currentDate.valueOf() > afterDate.valueOf();
      }
    }

    return false;
  };

  const disabledHours = () => {
    const disabledHours: Array<number> = [];

    if (!disabledAfterDate && !disabledBeforeDate) {
      return disabledHours;
    }

    if (value) {
      for (let i = 0; i < 24; i++) {
        const date = moment(value);

        date.hour(i);

        if (disabledDate(date)) {
          disabledHours.push(i);
        }
      }
    }

    return disabledHours;
  };

  const disabledMinutes = (selectedHour: number) => {
    const disabledMinutes: Array<number> = [];

    if (!disabledAfterDate && !disabledBeforeDate) {
      return disabledMinutes;
    }

    if (value) {
      for (let i = 0; i < 60; i++) {
        const date = moment(value);

        date.hour(selectedHour);
        date.minute(i);

        if (disabledDate(date)) {
          disabledMinutes.push(i);
        }
      }
    }

    return disabledMinutes;
  };

  return (
    <Container {...omit(['data-e2e-id'], rest)}>
      <FormField label={datePickerLabel} note={datePickerNote} meta={meta} data-e2e-id={`${rest['data-e2e-id']}.date`}>
        <DatePicker
          style={{ width: DatePickerWidth }}
          name={name}
          value={value ? moment(value).tz(timezone) : value}
          onChange={handleChange}
          {...datePickerRest}
          disabledDate={disabledDate}
        />
      </FormField>
      <FormField label={timePickerLabel} note={timePickerNote} data-e2e-id={`${rest['data-e2e-id']}.time`}>
        <TimePicker
          style={{ width: TimePickerWidth }}
          value={value ? moment(value).tz(timezone) : value}
          onChange={handleChange}
          {...timePickerRest}
          disabled={!value}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
        />
      </FormField>
    </Container>
  );
};
