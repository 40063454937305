import React from 'react';
import styled from '@/theme/styled';
import { Heading } from '../Heading';

export const CustomCardContainer = styled.div<{ width?: string; hasHeader?: boolean; hasFooter?: boolean }>`
  display: grid;
  grid-template-rows: ${({ hasHeader, hasFooter }) => {
    if (hasHeader && hasFooter) {
      return '61px 1fr auto';
    } else if (hasHeader && !hasFooter) {
      return '61px 1fr';
    } else if (!hasHeader && hasFooter) {
      return '1fr auto';
    } else {
      return 'auto';
    }
  }};
  background-color: #fff;

  width: ${({ width }) => (width ? width : 'auto')};

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    border-radius: 0;
  }
`;

export const CustomCardHeader = styled.header`
  ${'>*'} {
    text-align: center;
  }
`;

export const CustomCardBody = styled.div<{ padding: 'sm' | 'md' | 'none'; overflowBody: string }>`
  padding: ${({ padding }) => {
    if (padding === 'md') {
      return '3.2rem 2.4rem';
    }
    if (padding === 'sm') {
      return '2.4rem 2.4rem';
    }
    return '0';
  }};
  overflow: ${({ overflowBody }) => overflowBody};

  @media (max-width: ${props => props.theme.vars.breakpoints.sm.max}) {
    padding: 0;
  }
`;

export const CustomCardFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 2.4rem 0;
`;

type CustomCardProps = {
  header?: string | React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactChild | React.ReactChild[];
  padding?: 'sm' | 'md' | 'none';
  overflowBody?: string;
  'data-e2e-id'?: string;
  width?: string;
};

export const CustomCard = ({
  header,
  footer,
  children,
  padding = 'md',
  overflowBody = 'auto',
  width,
  ...rest
}: CustomCardProps) => (
  <CustomCardContainer hasHeader={header ? true : false} hasFooter={footer ? true : false} width={width} {...rest}>
    {header && (
      <CustomCardHeader>{typeof header === 'string' ? <Heading type="h1">{header}</Heading> : header}</CustomCardHeader>
    )}
    <CustomCardBody padding={padding} overflowBody={overflowBody}>
      {children}
    </CustomCardBody>
    {footer && <CustomCardFooter>{footer}</CustomCardFooter>}
  </CustomCardContainer>
);
