import styled from '@/theme/styled';

// const ITEM_GAP = '40px';
const MG_VALUE = 20;
const MOBILE_ITEM_GAP = `${MG_VALUE}px`;

export type Size = 'sm' | 'md';

export const TimelineWrapperHor = styled.div<{ size: Size; withContent: boolean }>`
  display: grid;
  grid-template-columns: unset;
  grid-template-rows: auto auto;
  grid-gap: ${props => (props.withContent ? MOBILE_ITEM_GAP : 'unset')};
`;

export const TimelineItemCirclesWrapperHor = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${MOBILE_ITEM_GAP};
  position: relative;
`;

export const TimelineItemSquare = styled.div<{ active: boolean; size: Size }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50px;
  border: 2px solid ${props => props.theme.vars.primaryColor};
  background-color: ${props => (props.active ? props.theme.vars.primaryColor : '#ffffff')};
  color: ${props => (props.active ? '#ffffff' : props.theme.vars.primaryColor)};
  border-radius: 8px;
  cursor: pointer;
`;

export const TimelineOthersItemSquare = styled(TimelineItemSquare)`
  border-color: #ffffff;
  background-color: #ffffff;
  padding: 4px;
`;

export const TimelineItemCircleLineHor = styled.div`
  position: absolute;
  cursor: default;
  pointer-events: none;
  background-color: ${props => props.theme.vars.primaryColor};
  top: 50%;
  height: 1px;
  width: 100%;
`;

export const TimelineItemLineHor = styled.div<{ index: number; size: Size }>`
  position: absolute;
  cursor: default;
  pointer-events: none;
  border-bottom: 1px solid #323c47;

  ${props => {
    switch (true) {
      case props.index === 0: {
        const first = `
          height: 20px;
          width: calc(150% + 8px + ${MG_VALUE + MG_VALUE / 2}px - 1px);
          border-left: 1px solid #323c47;
          left: 50%;
          bottom: -22px;
          `;
        return first;
      }
      case props.index === 1: {
        const second = `
          height: 20px;
          width: calc(50% + 2px + ${MG_VALUE / 2}px);
          border-left: 1px solid #323c47;
          left: 50%;
          bottom: -22px;
          `;
        return second;
      }
      case props.index === 2: {
        const third = `
            height: 20px;
            width: calc(50% + 2px + ${MG_VALUE / 2}px);
            border-right: 1px solid #323c47;
            right: 50%;
            bottom: -22px;
          `;
        return third;
      }
      case props.index === 3: {
        const fourth = `
            height: 20px;
            width: calc(150% + 8px + ${MG_VALUE + MG_VALUE / 2}px - 1px);
            border-right: 1px solid #323c47;
            right: 50%;
            bottom: -22px;
          `;
        return fourth;
      }
    }
  }};
`;

export const TimelineContentLineHor = styled.div<{ size: Size }>`
  position: absolute;
  cursor: default;
  pointer-events: none;
  border-right: 1px solid #323c47;
  width: 1px;
  left: 50%;
  height: 20px;
  top: -20px;
`;

export const TimelineItemTitle = styled.div<{ size: Size }>`
  line-height: 1;
  text-align: center;
  font-size: 16px;
`;

export const TimelineOthersItemTitle = styled(TimelineItemTitle)`
  color: gray;
  font-size: 12px;
`;

export const TimelineItemSubTitle = styled.div<{ size: Size }>`
  font-size: 14px;
`;

export const TimelineContent = styled.div<{ size: Size }>`
  position: relative;
  margin-top: 20px;
`;
