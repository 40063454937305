import gql from 'graphql-tag';

export const BID_UPDATE_MUTATION = gql`
  mutation BidUpdate($data: BidUpdateInput!) {
    bidUpdate(data: $data) {
      id
      offer {
        id
        status
        activeOfferSingleUnit {
          id
        }
      }
    }
  }
`;

export const RENTER_QUERY = gql`
  query RenterQuery($id: ID!) {
    user(id: $id) {
      renter {
        id
        phone {
          number
        }
      }
    }
  }
`;

export const RENTER_UPDATE = gql`
  mutation RenterUpdate($data: RenterUpdateInput!) {
    renterUpdate(data: $data) {
      id
    }
  }
`;
