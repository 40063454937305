import React from 'react';
import { Button } from 'antd';

import { Maybe, Bid, Offer } from '@/shared/types/graphql';
import { Grid } from '@/components';
import { useModalsState } from '@/providers';
import { AnnouncingTitle } from '../../styled';
import { OfferChecklistView } from '@/features';
import { stringifyHref } from '@/shared/utils';

export const OfferAccepted = ({
  bid,
  offer,
  onContact,
}: {
  bid?: Maybe<Bid>;
  offer?: Maybe<Offer>;
  onContact?: () => void;
}) => {
  const { agentContactModal } = useModalsState();

  const handleContactAgent =
    onContact ||
    (() => {
      if (bid?.id) {
        agentContactModal.setProps({
          visible: true,
          bidId: bid?.id,
        });
      }
    });

  const leaseExternalUrl = offer?.leaseLink || '';
  const leaseDownloadUrl = offer?.leaseContract?.downloadUrl || '';

  const ButtonUrl = ({ buttonText, leaseContract }: { buttonText: string; leaseContract: string }) => {
    if (!Boolean(leaseContract)) {
      return null;
    }

    const link = stringifyHref(leaseContract);

    return (
      <Button type="primary" size="large" onClick={() => window.open(link)} css={{ marginRight: '10px;' }}>
        {buttonText}
      </Button>
    );
  };

  return (
    <Grid.Layout style={{ marginTop: '20px' }} gap="20px" columns="1fr">
      <AnnouncingTitle>Let&apos;s make it official!</AnnouncingTitle>
      <OfferChecklistView bid={bid} />
      <Grid.Layout columns="1fr" gap="12px">
        <ButtonUrl buttonText="Download Lease Agreement" leaseContract={leaseDownloadUrl} />
        <ButtonUrl buttonText="Open Lease Agreement" leaseContract={leaseExternalUrl} />

        <Button size="large" onClick={handleContactAgent}>
          Contact Agent
        </Button>
      </Grid.Layout>
    </Grid.Layout>
  );
};
