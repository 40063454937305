import React from 'react';
import { useQuery } from 'react-apollo';
import { Field, Form } from '8base-react-sdk';

import { Grid, InputField, CustomCard, Loader, AddressFields } from '@/components';
import { InformationSection } from '@/features';
import { US_STATES_LIST } from '@/shared/constants';
import { composeValidators, required, phoneNumber } from '@/shared/validators';
import { normalizePhone } from '@/shared/utils';
import { User } from '@/shared/types/graphql';
import { BodyContainer, PersonalInforamtion, PersonalInformationColumn, FormContainer } from './styled';
import { AccountFooter } from './components';
import { USER_RENTER_ACCOUNT, UPDATE_USER_RENTER_ACCOUNT } from './queries';
import { useMutationSafe } from '@/features/_hooks';

export const Account = () => {
  const coercedStatesOfUSA = React.useMemo(
    () => Object.entries(US_STATES_LIST).map(([value, label]) => ({ value, label: `${value} - ${label}` })),
    [],
  );

  const [updateUser, { loading: updateRenterLoading }] = useMutationSafe(UPDATE_USER_RENTER_ACCOUNT, {
    refetchQueries: ['UserRenterAccount'],
    awaitRefetchQueries: true,
  });

  const { data, loading: userLoading } = useQuery<{ user: User }>(USER_RENTER_ACCOUNT);

  const user = data?.user;

  const onSubmit = (data: any) => {
    updateUser({
      variables: {
        data,
      },
    });
  };

  if (userLoading) {
    return <Loader stretch />;
  }

  return (
    <Form type="UPDATE" tableSchemaName="Users" onSubmit={onSubmit} initialValues={user}>
      {({ handleSubmit: onSubmit, form, pristine, values }) => {
        return (
          <FormContainer onSubmit={onSubmit}>
            <CustomCard
              header="My Account"
              padding="none"
              footer={<AccountFooter loading={updateRenterLoading} isPristine={pristine} resetForm={form.reset} />}
            >
              <BodyContainer>
                <PersonalInforamtion>
                  <InformationSection header="Personal Information">
                    <PersonalInformationColumn>
                      <Field label="First Name" name="firstName" component={InputField} />
                      <Field label="Last Name" name="lastName" component={InputField} />
                      <Field label="Email Address" name="email" component={InputField} disabled />
                    </PersonalInformationColumn>
                  </InformationSection>
                  <InformationSection header="Contact Information">
                    <Grid.Layout gap="16px">
                      <Grid.Box>
                        <AddressFields
                          country="renter.address.country"
                          street1="renter.address.street1"
                          zip="renter.address.zip"
                          city="renter.address.city"
                          state="renter.address.state"
                          stateOptions={coercedStatesOfUSA}
                          isRequired={false}
                        />
                      </Grid.Box>
                      <Grid.Box>
                        <Field
                          label="Phone Number*"
                          name="renter.phone.number"
                          autoComplete="disabled"
                          mask="(999) 999-9999"
                          component={InputField}
                          validate={composeValidators(required, phoneNumber)}
                          addonBefore={values?.renter?.phone?.code ? `+${values.renter.phone.code}` : '+1'}
                          parse={normalizePhone}
                        />
                      </Grid.Box>
                    </Grid.Layout>
                  </InformationSection>
                </PersonalInforamtion>
              </BodyContainer>
            </CustomCard>
          </FormContainer>
        );
      }}
    </Form>
  );
};
