import React from 'react';
import { Offer, User } from '@/shared/types/graphql';
import { RenterContext } from '@/providers';
import { useAuth } from '8base-react-sdk';
import { useHistory } from 'react-router-dom';
import { RowFlex, Tag, Text, Timer } from '@/components';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { getCompressedImageUrl, stringifyAddress } from '@/shared/utils';
import { ActionsContainer } from '@/pages/Property/styled';
import { Button } from 'antd';
import { CompanySingleUnits } from '@/pages/CompanyLiveOffers/constants';
import { PageState } from '@/pages/CompanyLiveOffers/components/Offers';
import {
  CardWrapper,
  OfferHeaderTag,
  OfferImageWrapperTag,
  OfferImageTag,
  OffersCountPositionTag,
  OfferInfoColumnTag,
  ActionsWrapper,
} from '../styled';

type OfferCardProps = {
  offer: Offer;
  user?: User;
  setPageState: (data: PageState) => void;
};

type PropertyActionsProps = {
  secureNow: boolean;
  onBeatOffer: () => void;
  onSecureNow: () => void;
};

const LiveOfferActions = ({ secureNow, onBeatOffer, onSecureNow }: PropertyActionsProps) => {
  return (
    <ActionsContainer>
      {secureNow && (
        <Button type="primary" size="large" onClick={onSecureNow}>
          SecureNow™
        </Button>
      )}
      <Button type="primary" size="large" onClick={onBeatOffer}>
        Make an Offer
      </Button>
    </ActionsContainer>
  );
};

const OfferCard: React.FC<OfferCardProps> = ({ offer, user, setPageState }) => {
  const { onLogin, anchorMenuData } = React.useContext(RenterContext);
  const { isAuthorized } = useAuth();
  const history = useHistory();

  const isHasBidAlready = !!offer.bid?.items.find(bid => bid.renter?.user?.id === user?.id);
  const offerId = offer?.id;

  const singleUnit = offer?.historyOfferSingleUnit;
  const mediaImageUrl = singleUnit?.singleUnitHasMedia?.items[0]?.media?.file?.downloadUrl;
  const compressedImage = getCompressedImageUrl(mediaImageUrl);

  const onBeatOffer = React.useCallback(() => {
    setPageState({ currentPage: CompanySingleUnits.OFFER, offer });
    anchorMenuData?.setAnchorMenuVisible(false);
  }, [anchorMenuData, offer, setPageState]);

  const onSecureNow = React.useCallback(() => {
    setPageState({ currentPage: CompanySingleUnits.SECURE_NOW, offer });
    anchorMenuData?.setAnchorMenuVisible(false);
  }, [anchorMenuData, offer, setPageState]);

  return (
    <CardWrapper>
      <If condition={!!singleUnit}>
        <OfferHeaderTag onClick={() => history.push(getSingleRentalUrl(offer?.id))}>
          <OfferImageWrapperTag>
            <OfferImageTag src={compressedImage || MediaGalleryPlaceholder} />
            <OffersCountPositionTag>
              <Tag color="orange" type="raised">
                {offer?.status}
              </Tag>
            </OffersCountPositionTag>
          </OfferImageWrapperTag>
          <OfferInfoColumnTag>
            <Text type="title" ellipsis={true}>
              {singleUnit?.name}
            </Text>
            <Text ellipsis={true}>{stringifyAddress(singleUnit?.address)}</Text>
            <RowFlex align="center">
              <Timer endsDate={offer?.endsIn} icon={false} outline={false} />
            </RowFlex>
          </OfferInfoColumnTag>
        </OfferHeaderTag>
      </If>
      <ActionsWrapper>
        <Choose>
          <When condition={!isAuthorized}>
            <LiveOfferActions
              secureNow={!!offer?.secureNow}
              onBeatOffer={() => onLogin && onLogin({ offerId })}
              onSecureNow={() => onLogin && onLogin({ offerId, secureNow: true })}
            />
          </When>
          <When condition={isAuthorized && !isHasBidAlready}>
            <LiveOfferActions secureNow={!!offer?.secureNow} onSecureNow={onSecureNow} onBeatOffer={onBeatOffer} />
          </When>
        </Choose>
      </ActionsWrapper>
    </CardWrapper>
  );
};

export default OfferCard;
