import React from 'react';
import pluralize from 'pluralize';
import { styled } from '@/theme';

import { useHistory } from 'react-router-dom';
import { CustomCard, Loader, ColumnFlex, Responsive, Text, ButtonLink } from '@/components';
import { useCurrentUser } from '@/features/_hooks/useCurrentUser';
import { EditOfferForm } from '@/features/EditOfferForm';

import { Container, OfferAuctionContainer } from '@/pages/PropertyOffer/styled';

import { File, Bid, Offer } from '@/shared/types/graphql';
import { NOT_AVALIABLE } from '@/shared/constants';

import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';

import { useBidCreate } from '@/providers/modalProvider/modals';
import { AppRoutes, getSingleRentalBiddingUrl } from '@/shared/constants/appRoutes';
import { getCompressedImageUrl } from '@/shared/utils';

const OfferHeaderTag = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  max-height: 100px;
  border-radius: 6px;
  background-color: #f1f1f1;
  overflow: hidden;
`;

const Button = styled(ButtonLink)`
  font-size: 14px;
`;

const OfferInfoColumnTag = styled(ColumnFlex)`
  padding: 12px;
  overflow: hidden;

  > *:not(:first-child) {
    > * {
      font-size: 13px !important;
      height: auto;
    }
  }
`;

const OfferImageWrapperTag = styled.div`
  position: relative;
`;

const OfferImageTag = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
`;

type PropertyOfferProps = {
  secureNow?: boolean;
  offer: Offer;
  onCancel: () => void;
};

export const PropertyBidView: React.FC<PropertyOfferProps> = ({ onCancel, secureNow, offer }) => {
  const offerId = offer?.id;
  const { user } = useCurrentUser();
  const [viewType, setViewType] = React.useState<{ kind: 'edit' } | { kind: 'secure' } | { kind: 'loading' }>({
    kind: secureNow ? 'secure' : 'edit',
  });
  const singleUnit = offer.historyOfferSingleUnit;
  const offerRepr: Offer = {
    ...offer,
    bid: offer?.bid
      ? {
          ...offer.bid,
          items: [
            ...(offer.bid?.items || []),
            {
              id: 'yourBid',
              renter: { id: 'youAre', user },
              rentAmount: offer?.rentAmount,
              moveinDate: offer?.moveinDate,
              termsOfLease: offer?.termsOfLease,
            },
          ],
        }
      : null,
  };

  const history = useHistory();
  const { renterLoading, onSubmit: createBid } = useBidCreate({
    options: {
      refetchQueries: ['SingleUnitOfferQuery'],
      awaitRefetchQueries: true,
      onError: () => setViewType({ kind: 'edit' }),
      onCompleted: () => history.push(getSingleRentalBiddingUrl(offerId)),
    },
    offerId: offerRepr?.id,
  });

  const onBeatOffer = React.useCallback(
    (bid: Bid) => {
      setViewType({ kind: 'loading' });
      createBid({
        moveinDate: bid.moveinDate,
        rentAmount: bid.rentAmount as number,
        termsOfLease: bid.termsOfLease as number,
        securityDeposite: bid?.securityDeposite as number,
        _offerId: offerRepr?.id as string,
      });
    },
    [offerRepr, createBid, setViewType],
  );

  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items || [];
  const singleUnitMediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];

  const communityHasMedia = singleUnit?.community?.communityHasMedia?.items || [];
  const communityMediaList = React.useMemo(() => communityHasMedia.map(cm => cm?.media?.file), [
    communityHasMedia,
  ]) as File[];

  const mediaList = [...singleUnitMediaList, ...communityMediaList];
  const formattedMediaList = mediaList.map((item: any) => {
    const rawImgItem = { ...item };
    const rawImgUrl = rawImgItem?.downloadUrl;

    const compressedImgUrl = getCompressedImageUrl(rawImgUrl);
    return { ...rawImgItem, compressedImgUrl };
  });

  const houseMetaLabel = React.useMemo(() => {
    const squareFt = singleUnit?.squareFt;
    const bedrooms = singleUnit?.bedrooms;
    const bathrooms = singleUnit?.bathrooms;
    const squareFtLabel = squareFt ? `${squareFt} sqft` : `${NOT_AVALIABLE} sqft`;
    const bedroomsLabel = bedrooms ? `${bedrooms} ${pluralize('bed', bedrooms)}` : `${NOT_AVALIABLE} beds`;
    const bathroomsLabel = bathrooms ? `${bathrooms} ${pluralize('bath', bathrooms)}` : `${NOT_AVALIABLE} baths`;

    return (
      <span>
        <span style={{ color: 'black' }}>{`${squareFtLabel}`}</span>
        <span style={{ margin: '0 4px 0 8px' }}>|</span>
        <span style={{ color: 'black' }}>{`${bedroomsLabel}`}</span>
        <span style={{ margin: '0 4px 0 8px' }}>|</span>
        <span style={{ color: 'black' }}>{`${bathroomsLabel}`}</span>
      </span>
    );
  }, [singleUnit]);

  if (renterLoading || viewType.kind === 'loading') {
    return <Loader stretch />;
  }

  return (
    <CustomCard padding="none">
      <Container>
        <If condition={!!singleUnit}>
          <OfferHeaderTag onClick={() => history.push(AppRoutes.RENTER_RENTALS)}>
            <OfferImageWrapperTag>
              <OfferImageTag src={formattedMediaList[0]?.compressedImgUrl || MediaGalleryPlaceholder} />
            </OfferImageWrapperTag>
            <OfferInfoColumnTag>
              <Text type="title" ellipsis={true}>
                {singleUnit?.name}
              </Text>
              <Text ellipsis={true}>{houseMetaLabel}</Text>
            </OfferInfoColumnTag>
          </OfferHeaderTag>
        </If>
        <OfferAuctionContainer>
          <Responsive.HideOn breakpoints={['xs', 'sm', 'md']} css={{ marginBottom: 30 }}>
            <Button onClick={onCancel}>Go Back</Button>
          </Responsive.HideOn>
          {(viewType.kind === 'edit' || viewType.kind === 'secure') && (
            <EditOfferForm
              offer={offerRepr}
              mode={viewType}
              showContent={false}
              validation={false}
              onPlace={onBeatOffer}
              onCancel={onCancel}
            />
          )}
        </OfferAuctionContainer>
      </Container>
    </CustomCard>
  );
};
