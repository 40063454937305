import React from 'react';
import styled from '@emotion/styled';

import { Grid, Heading } from '@/components';
import { useQuery } from 'react-apollo';
import { RENTAL_OFFERS_LIST_QUERY } from './queries';
import { useQueryParams } from '@/features/_hooks';
import { OfferCardGrid } from './OfferCardGrid';
import { OfferListResponse } from '@/shared/types/graphql';
import { Select } from 'antd';
import { OFFER_STATUSES } from '@/shared/constants';

const SORT_OPTIONS = [
  {
    label: 'Sort by: Time Asc',
    value: {
      sortBy: 'createdAt',
      sortOrder: 'ASC',
    },
  },
  {
    label: 'Sort by: Time Desc',
    value: {
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    },
  },
];

const FILTER_OPTIONS = [
  {
    label: 'View: Active Offers',
    value: 'active',
  },
  {
    label: 'View: Leased Offers',
    value: 'leased',
  },
  {
    label: 'View: All Offers',
    value: 'all',
  },
];

const OffersContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 112px;
  margin-bottom: 20px;
`;

export const Offers = () => {
  const [queryParams, setQueryParams] = useQueryParams<{
    sortBy: string;
    sortOrder: string;
    filter: 'all' | 'leased' | 'active';
  }>(100, {
    sortBy: 'createdAt',
    sortOrder: 'DESC',
    filter: 'all',
  });

  let statusFilter = {};

  if (queryParams?.filter === 'leased') {
    statusFilter = {
      status: {
        equals: OFFER_STATUSES.leased,
      },
    };
  } else if (queryParams?.filter === 'active') {
    statusFilter = {
      status: {
        not_in: [
          OFFER_STATUSES.leased,
          OFFER_STATUSES.draft,
          OFFER_STATUSES.canceled,
          OFFER_STATUSES.closed,
          OFFER_STATUSES.ended,
        ],
      },
    };
  }

  const { data, loading } = useQuery<{ offersList: OfferListResponse }>(RENTAL_OFFERS_LIST_QUERY, {
    skip: !queryParams?.sortBy,
    fetchPolicy: 'network-only',
    variables: {
      sort: {
        [queryParams.sortBy]: queryParams.sortOrder,
      },
      filter: {
        AND: [
          statusFilter,
          {
            historyOfferSingleUnit: {
              id: {
                is_not_empty: true,
              },
            },
          },
          {
            bid: {
              some: {
                renter: {
                  user: {
                    id: {
                      equals: '__loggedInUserId',
                    },
                  },
                },
              },
            },
          },
        ],
      },
    },
  });

  const onChangeSortBy = React.useCallback(
    (value: any) => {
      setQueryParams({ ...queryParams, ...JSON.parse(value) });
    },
    [queryParams, setQueryParams],
  );

  const onChangeFilter = React.useCallback(
    (value: any) => {
      setQueryParams({ ...queryParams, filter: value });
    },
    [queryParams, setQueryParams],
  );

  const sortValue = JSON.stringify({
    sortBy: queryParams?.sortBy,
    sortOrder: queryParams?.sortOrder,
  });

  const header = (
    <Grid.Layout gap="8px" alignItems="center" justifyItems="center" stretch>
      <Heading type="h1">My Rentals</Heading>
      <Grid.Layout gap="8px" stretch>
        <Select onChange={onChangeFilter} value={queryParams?.filter} loading={loading} placeholder="Sort by">
          {FILTER_OPTIONS.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
        <Select onChange={onChangeSortBy} value={sortValue} loading={loading} placeholder="Sort by">
          {SORT_OPTIONS.map(option => (
            <Select.Option key={JSON.stringify(option.value)} value={JSON.stringify(option.value)}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Grid.Layout>
    </Grid.Layout>
  );

  return (
    <OffersContainer>
      {header}
      <OfferCardGrid offers={data?.offersList?.items || []} loading={loading} />
    </OffersContainer>
  );
};
