import React from 'react';
import pluralize from 'pluralize';
import { Button } from 'antd';
import { styled } from '@/theme';

import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import MediaGalleryPlaceholder from '@/static/MediaGalleryPlaceholder.png';
import { CustomCard, Loader, ColumnFlex, Link, Text, TimerStylish } from '@/components';
import { useCurrentUser, useOfferBidCombinedStatus } from '@/features/_hooks';
import { EditOfferForm, AgentInformation } from '@/features';
import { Container, OfferAuctionContainer, OfferTitle, OfferSubTitle } from './styled';
import { OFFER_SINGLE_UNIT_QUERY } from './queries';
import { PropertyOfferTimeline } from './PropertyOfferTimeline';
import { DateTime } from 'luxon';
import { Offer, File, Bid } from '@/shared/types/graphql';
import { formPlace } from '@/shared/utils';
import { NOT_AVALIABLE, OFFER_COMBINED_STATUSES } from '@/shared/constants';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';

const OfferHeaderTag = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  max-height: 100px;
  border-radius: 6px;
  background-color: #f1f1f1;
  overflow: hidden;
`;

const OfferInfoColumnTag = styled(ColumnFlex)`
  padding: 12px;
  overflow: hidden;

  > *:not(:first-child) {
    > * {
      font-size: 13px !important;
      height: auto;
    }
  }
`;

const OfferImageWrapperTag = styled.div`
  position: relative;
`;

const OfferImageTag = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
`;

const AgentInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;

  > *:first-child > *:first-child {
    justify-self: center;
  }
`;
// eslint-disable-next-line @typescript-eslint/ban-types
type PropertyOfferProps = {};

export const PropertyOffer: React.FC<PropertyOfferProps> = () => {
  const { id: offerId } = useParams<{ id: string }>();
  const history = useHistory();
  const { user } = useCurrentUser();
  const [viewType, setViewType] = React.useState<
    | { kind: 'offer' }
    | { kind: 'edit' }
    | { kind: 'beat'; value: Bid }
    | { kind: 'match'; value: Bid }
    | { kind: 'secure' }
    | { kind: 'contact-agent' }
  >({ kind: 'offer' });

  const { data: offerResponse, loading: offerLoading } = useQuery<{ offer: Offer }>(OFFER_SINGLE_UNIT_QUERY, {
    variables: { id: offerId },
    skip: !offerId,
    fetchPolicy: 'network-only',
  });

  const singleUnit = offerResponse?.offer?.historyOfferSingleUnit;

  const offer = offerResponse?.offer || {};
  const singleUnitHasMedia = singleUnit?.singleUnitHasMedia?.items || [];
  const singleUnitMediaList = React.useMemo(() => singleUnitHasMedia.map(sum => sum?.media?.file), [
    singleUnitHasMedia,
  ]) as File[];

  const communityHasMedia = singleUnit?.community?.communityHasMedia?.items || [];
  const communityMediaList = React.useMemo(() => communityHasMedia.map(cm => cm?.media?.file), [
    communityHasMedia,
  ]) as File[];

  const mediaList = [...singleUnitMediaList, ...communityMediaList];

  const houseMetaLabel = React.useMemo(() => {
    const squareFt = singleUnit?.squareFt;
    const bedrooms = singleUnit?.bedrooms;
    const bathrooms = singleUnit?.bathrooms;
    const squareFtLabel = squareFt ? `${squareFt} sqft` : `${NOT_AVALIABLE} sqft`;
    const bedroomsLabel = bedrooms ? `${bedrooms} ${pluralize('bed', bedrooms)}` : `${NOT_AVALIABLE} beds`;
    const bathroomsLabel = bathrooms ? `${bathrooms} ${pluralize('bath', bathrooms)}` : `${NOT_AVALIABLE} baths`;

    return (
      <span>
        <span style={{ color: 'black' }}>{`${squareFtLabel}`}</span>
        <span style={{ margin: '0 4px 0 8px' }}>|</span>
        <span style={{ color: 'black' }}>{`${bedroomsLabel}`}</span>
        <span style={{ margin: '0 4px 0 8px' }}>|</span>
        <span style={{ color: 'black' }}>{`${bathroomsLabel}`}</span>
      </span>
    );
  }, [singleUnit]);

  const offerBidCombinedStatus = useOfferBidCombinedStatus(offer);

  if (offerLoading) {
    return <Loader stretch />;
  }

  const userBidIndex = offer?.bid?.items?.findIndex(bid => bid?.renter?.user?.id === user?.id);
  const userBidId = (typeof userBidIndex === 'number' && offer?.bid?.items?.[userBidIndex]?.id) || null;

  // eslint-disable-next-line
  const isOfferEnded = DateTime.fromISO(offer?.endsIn).diffNow().milliseconds < 0;

  const isShouldRedirect =
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.canceled ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.rejected ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.leased ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.draft ||
    offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.none;

  if (isShouldRedirect) {
    return <Redirect to={getSingleRentalUrl(offerId)} />;
  }

  return (
    <CustomCard padding="none">
      <Container>
        <If condition={!!singleUnit && !!offerId}>
          <OfferHeaderTag onClick={() => history.push(getSingleRentalUrl(offerId))}>
            <OfferImageWrapperTag>
              <OfferImageTag src={mediaList[0]?.downloadUrl || MediaGalleryPlaceholder} />
            </OfferImageWrapperTag>
            <OfferInfoColumnTag>
              <Text type="title" ellipsis={true}>
                {singleUnit?.name}
              </Text>
              <Text ellipsis={true}>{houseMetaLabel}</Text>
            </OfferInfoColumnTag>
          </OfferHeaderTag>
        </If>
        <OfferAuctionContainer>
          <Link to={getSingleRentalUrl(offerId)}>Go Back</Link>
          {viewType.kind === 'offer' && offerBidCombinedStatus.kind !== OFFER_COMBINED_STATUSES.awarded && (
            <ColumnFlex align="center" justify="center" css={{ margin: 20 }}>
              {typeof userBidIndex === 'number' && userBidIndex >= 0 ? (
                <OfferTitle>You are in {formPlace(userBidIndex + 1)} place!</OfferTitle>
              ) : (
                <OfferTitle>Current Top Offers</OfferTitle>
              )}
              {typeof userBidIndex === 'number' && userBidIndex > 0 ? (
                <OfferSubTitle>Make another offer to get back on the top</OfferSubTitle>
              ) : null}
            </ColumnFlex>
          )}
          {viewType.kind === 'offer' && offerBidCombinedStatus.kind === OFFER_COMBINED_STATUSES.live && (
            <div style={{ margin: '20px 0' }}>
              <TimerStylish endsDate={offer?.endsIn} />
            </div>
          )}
          {viewType.kind === 'offer' && (
            <PropertyOfferTimeline
              offer={offer}
              onImprove={() => setViewType({ kind: 'edit' })}
              onBeat={bid => setViewType({ kind: 'beat', value: bid })}
              onMatch={bid => setViewType({ kind: 'match', value: bid })}
              onSecure={() => setViewType({ kind: 'secure' })}
              onContact={() => setViewType({ kind: 'contact-agent' })}
            />
          )}
          {(viewType.kind === 'edit' ||
            viewType.kind === 'beat' ||
            viewType.kind === 'match' ||
            viewType.kind === 'secure') && (
            <EditOfferForm offer={offer} mode={viewType} onCancel={() => setViewType({ kind: 'offer' })} />
          )}
          {viewType.kind === 'contact-agent' && (
            <AgentInfoContainer>
              <AgentInformation bidId={userBidId} />
              <Button block type="default" size="large" onClick={() => setViewType({ kind: 'offer' })}>
                Back
              </Button>
            </AgentInfoContainer>
          )}
        </OfferAuctionContainer>
      </Container>
    </CustomCard>
  );
};
