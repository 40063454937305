import React from 'react';
import styled from '@emotion/styled';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';

import { Tag, FlexLayout } from '@/components';
import { ChevronRight } from '@/static/icons';
import { EntityOption } from '@/features/EntityOption';
import { LeftTime } from '@/features/LeftTime';

import { formatAmount, getCompressedImageUrl, stringifyAddress, stringifySingleUnitType } from '@/shared/utils';
import { NOT_AVALIABLE } from '@/shared/constants';
import { getSingleRentalUrl } from '@/shared/constants/appRoutes';
import { useOfferBidCombinedStatus } from '@/features/_hooks';

import { Offer } from '@/shared/types/graphql';

const PropertyCardTag = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  border-radius: 4px;
  height: auto;
  min-width: auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const PropertyCardImageTag = styled.div`
  height: 300px;
  min-width: 100%;
  position: relative;
  background: #fafafa;

  > img {
    object-fit: cover;
    position: absolute;
    height: 100%;
    min-width: 100%;
  }
`;

const PropertyCardImageOffersTag = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const PropertyCardInfoTag = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 16px;
  margin: 0 0;
  width: 100%;

  & > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const PropertyCardInfoTitleTag = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  > *:first-child {
    font-size: 18px;
    font-weight: 500;
    color: #323c47;
  }
`;

const PropertyCardInfoOffferTag = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  > * {
    color: white;
    margin-bottom: 2em;
    background: rgba(0, 0, 0, 0.6);
    padding: 6px;
    border-radius: 1em;
  }
`;

const PropertyCardDetailsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e3e3;
  padding: 12px 0;
  width: 100%;
  color: #939393;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  > *:first-child {
    margin-left: 16px;
  }

  > *:last-child {
    margin-right: 16px;
    color: #f37a22;
  }
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;

  @media (min-width: 426px) {
    grid-template-columns: 1fr auto;
  }
`;

const HouseOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e3e3e3;
`;

const TimeAndOffersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

function TimeAndOffers(props: { endsIn: any; bidsCount: number }) {
  const { endsIn, bidsCount } = props;
  return (
    <TimeAndOffersContainer>
      <LeftTime size="sm" endsDate={DateTime.fromISO(endsIn)} withIcon={false} short={true} />
      <span style={{ padding: '0 6px' }}>-</span>
      <span style={{ fontSize: 12 }}>{`${bidsCount} ${pluralize('offer', bidsCount)}`}</span>
    </TimeAndOffersContainer>
  );
}

type PropertyCardProps = {
  offer: Offer;
};

export const PropertyCard: React.FC<PropertyCardProps> = ({ offer }) => {
  const singleUnit = offer?.historyOfferSingleUnit;

  const squareFt = singleUnit?.squareFt;
  const bedrooms = singleUnit?.bedrooms;
  const bathrooms = singleUnit?.bathrooms;
  const address = singleUnit?.address;
  const name = singleUnit?.name;
  const mediaUrl = singleUnit?.singleUnitHasMedia?.items[0]?.media?.file?.downloadUrl;
  const compressedImage = getCompressedImageUrl(mediaUrl);
  const bidsCount = offer?.bid?.count || 0;
  const securityDeposit = formatAmount(offer?.securityDeposite);
  const termsOfLease = offer?.termsOfLease;
  const rentAmount = formatAmount(offer?.rentAmount);
  const moveinDate = offer?.moveinDate;
  const endsIn = offer?.endsIn;

  const { kind: combinedOfferStatus } = useOfferBidCombinedStatus(offer);

  const houseMetaLabel = React.useMemo(() => {
    const squareFtLabel = squareFt ? `${squareFt} sqft` : `${NOT_AVALIABLE} sqft`;
    const bedroomsLabel = bedrooms ? `${bedrooms} ${pluralize('bed', bedrooms)}` : `${NOT_AVALIABLE} beds`;
    const bathroomsLabel = bathrooms ? `${bathrooms} ${pluralize('bath', bathrooms)}` : `${NOT_AVALIABLE} baths`;

    return `${squareFtLabel} | ${bedroomsLabel} | ${bathroomsLabel}`;
  }, [squareFt, bedrooms, bathrooms]);

  const termsOfLeaseLabel = termsOfLease ? `${termsOfLease} ${pluralize('month', termsOfLease)}` : NOT_AVALIABLE;
  const moveInDatelabel = moveinDate ? DateTime.fromISO(moveinDate).toFormat('DD') : NOT_AVALIABLE;
  const securityDepositLabel = securityDeposit ? `$${securityDeposit}` : NOT_AVALIABLE;
  const rentAmountLabel = rentAmount ? `$${rentAmount}/month` : NOT_AVALIABLE;
  const adressLabel = address ? stringifyAddress(address) : NOT_AVALIABLE;

  const type = singleUnit?.type ? stringifySingleUnitType(singleUnit.type) : NOT_AVALIABLE;

  return (
    <PropertyCardTag>
      <PropertyCardImageTag>
        <img src={compressedImage || undefined} />
        <PropertyCardImageOffersTag>
          <Choose>
            <When condition={combinedOfferStatus === 'leased'}>
              <Tag color="red" type="raised">
                Property Leased
              </Tag>
            </When>
            <When condition={combinedOfferStatus === 'ended'}>
              <Tag color="red" type="raised">
                Auction Ended
              </Tag>
            </When>
            <When condition={combinedOfferStatus === 'pending'}>
              <Tag color="orange" type="raised">
                Auction Upcoming
              </Tag>
            </When>
            <When condition={combinedOfferStatus === 'canceled'}>
              <Tag color="red" type="raised">
                Auction Canceled
              </Tag>
            </When>
          </Choose>
        </PropertyCardImageOffersTag>
        <PropertyCardInfoOffferTag>
          {combinedOfferStatus === 'live' && <TimeAndOffers endsIn={endsIn} bidsCount={bidsCount} />}
        </PropertyCardInfoOffferTag>
      </PropertyCardImageTag>
      <FlexLayout direction="column">
        <PropertyCardInfoTag>
          <PropertyCardInfoTitleTag>
            <span>{name}</span>
            {offer?.numberId && <span>{`Livo Offer #${offer.numberId}`}</span>}
          </PropertyCardInfoTitleTag>
          <HouseOptionsContainer>
            <EntityOption fragmented label={type} type="uppercase-label" value={houseMetaLabel} />
            <EntityOption fragmented label="Address" type="uppercase-label" value={adressLabel} />
          </HouseOptionsContainer>
          <OptionsContainer>
            <EntityOption fragmented label="Rent amount" value={rentAmountLabel} />
            <EntityOption fragmented label="Preferred Terms" value={termsOfLeaseLabel} />
            <EntityOption fragmented label="Move-in Date" value={moveInDatelabel} />
            <EntityOption fragmented label="Security Deposit" value={securityDepositLabel} />
          </OptionsContainer>
        </PropertyCardInfoTag>
        <PropertyCardDetailsLink to={getSingleRentalUrl(offer?.id)}>
          <span>view details & make an offer</span>
          <Icon component={ChevronRight} css={{ fontSize: 16 }} />
        </PropertyCardDetailsLink>
      </FlexLayout>
    </PropertyCardTag>
  );
};
