import React from 'react';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { Form } from '8base-react-sdk';

import { useModalsState } from '@/providers';
import { ModalFooter, Scrollable } from '@/components';
import { BID_DELETE_MUTATION } from './queries';
import { useMutationSafe } from '@/features/_hooks';

export const BidDeleteModal = () => {
  const { bidDeleteModal } = useModalsState();
  const { bidId, options = {}, visible } = bidDeleteModal.props;

  const [bidDelete] = useMutationSafe(BID_DELETE_MUTATION, {
    onCompleted: () => {
      bidDeleteModal.close();
      toast.success('Offer successfully withdrawn');
      options.onCompleted && options.onCompleted();
    },
  });

  const onSubmit = React.useCallback(async () => {
    await bidDelete({
      ...options,
      variables: { data: { id: bidId } },
    });
  }, [bidId, bidDelete, options]);

  return (
    <Form type="CREATE" tableSchemaName="Bid" onSubmit={onSubmit}>
      {({ handleSubmit: onSubmit, form, submitting }) => (
        <Modal
          title="Withdraw Offer"
          className="livo-modal"
          visible={visible as boolean}
          onCancel={bidDeleteModal.close}
          afterClose={form.reset}
          centered
          footer={
            <ModalFooter
              cancelProps={{
                disabled: submitting,
                onClick: bidDeleteModal.close,
              }}
              okProps={{
                text: 'Withdraw Offer',
                type: 'danger',
                htmlType: 'submit',
                disabled: submitting,
                loading: submitting,
                onClick: onSubmit,
              }}
            />
          }
        >
          {<Scrollable>Are you sure you want withdraw your offer?</Scrollable>}
        </Modal>
      )}
    </Form>
  );
};
