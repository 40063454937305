import React from 'react';
import styled from '@emotion/styled';

import { Offer } from '@/shared/types/graphql';
import { PropertyCard } from './PropertyCard';
import { PortfolioIcon } from '@/static/icons';
import { Icon } from 'antd';
import { Loader } from '@/components';

const PropertyCardsTag = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 20px;
  position: relative;
  height: 100%;
`;

const PropertyCardsLoaderTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const PropertyCardsEmptyTag = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #929ea8;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
`;

type PropertyCardsProps = {
  offers: Offer[];
  loading?: boolean;
};

export const PropertyCards: React.FC<PropertyCardsProps> = ({ offers, loading }) => {
  if (loading) {
    return (
      <PropertyCardsLoaderTag>
        <Loader stretch />
      </PropertyCardsLoaderTag>
    );
  }

  return (
    <PropertyCardsTag>
      {offers.length === 0 ? (
        <PropertyCardsEmptyTag>
          <Icon component={PortfolioIcon} css={{ fontSize: 40, marginBottom: 10 }} />
          Sorry! we have no rentals
          <br />
          to show you at this moment
        </PropertyCardsEmptyTag>
      ) : (
        offers.map((offer, i) => <PropertyCard key={offer.id || i} offer={offer} />)
      )}
    </PropertyCardsTag>
  );
};
