import React from 'react';
import * as R from 'ramda';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '8base-react-sdk';
import { sendToSentry } from '@/shared/sentry';
import { useCurrentUser } from '@/features/_hooks';

import { Auth } from '@/pages/Auth';
import Account from '@/pages/Account';
import Properties from '@/pages/Properties';
import Property from '@/pages/Property';
import PropertyOffer from '@/pages/PropertyOffer';
import Offers from '@/pages/Offers';
import ErrorPage from '@/pages/ErrorPage';
import NotFound from '@/pages/NotFound';
import Blocked from '@/pages/Blocked';

import { Loader } from '@/components';
import { AppRoutes } from '@/shared/constants/appRoutes';
import Store from '@/shared/utils/Store';
import { storageType, storageKey, redirectType } from '@/shared/types/store';
import LiveOffers from '@/pages/CompanyLiveOffers';

function LoggingOut() {
  return <Loader stretch />;
}

export const Routes = () => {
  const { isAuthorized, authClient } = useAuth();
  const { isEmailVerified } = authClient.getState();
  const { user } = useCurrentUser();
  const store = new Store({ storageType: storageType.Session, storageKey: storageKey.Redirect });

  const history = useHistory();

  const authState = ((): 'guest' | 'authorized-non-verified' | 'authorized' | 'blocked' => {
    // user is banned
    if (user?.isBlocked) {
      return 'blocked';
    }

    // general flow
    if (isAuthorized && isEmailVerified) {
      return 'authorized';
    } else if (isAuthorized && !isEmailVerified) {
      return 'authorized-non-verified';
    } else {
      return 'guest';
    }
  })();

  React.useEffect(
    function onAuthStateChange() {
      switch (authState) {
        case 'authorized':
          const redirect = store.get(redirectType.afterLogin);
          const redirectUrl = R.pathOr(null, ['pathname'], redirect);
          if (redirectUrl) {
            store.drop(redirectType.afterLogin);
            history.replace(redirectUrl);
          }
          break;
      }
    },
    [authState, history, store],
  );

  React.useEffect(
    function invalidateAuthStateOnChange() {
      const onStorageChange = () => {
        const authState = authClient.getState();
        const desynced = user && user.email !== authState.email;
        const globalRef = window as any;

        if (desynced && !globalRef._desynced) {
          globalRef._desynced = true;
          alert('User is desynchronized, session will be closed, please log-in in a minute');
          authClient.batch(() => {
            sendToSentry('User is desynchronized', { authEmail: authState.email, userEmail: user?.email });
            authClient.purgeState();
            authClient.logout();
          });
        }
      };
      window.addEventListener('storage', onStorageChange);
      return () => window.removeEventListener('storage', onStorageChange);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return (
    <Choose>
      <When condition={authState === 'blocked'}>
        <Route component={Blocked} />
      </When>
      <When condition={authState === 'authorized'}>
        <Switch>
          <Route path="/logging-out" component={LoggingOut} />
          <Route exact path="/">
            <Redirect to={AppRoutes.RENTER_OFFERS} />
          </Route>
          <Route exact path={AppRoutes.RENTER_RENTALS} component={Properties} />
          <Route exact path={AppRoutes.RENTER_SINGLE_RENTAL} component={Property} />
          <Route exact path={AppRoutes.RENTALS_ID_COMPANY_SINGLE_UNITS} component={LiveOffers} />
          <Route exact path={AppRoutes.RENTER_SINGLE_RENTAL_BIDDING} component={PropertyOffer} />
          <Route path={AppRoutes.RENTER_ACCOUNT} component={Account} />
          <Route path={AppRoutes.RENTER_OFFERS} component={Offers} />
          <Route exact path={AppRoutes.RENTER_ERROR_PAGE} component={ErrorPage} />
          <Route exact path={AppRoutes.RENTER_NOT_FOUND_PAGE} component={NotFound} />
          <Route>
            <Redirect to={AppRoutes.RENTER_OFFERS} />
          </Route>
        </Switch>
      </When>
      <When condition={authState === 'authorized-non-verified'}>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route>
            <Redirect to="/auth/confirmation" />
          </Route>
        </Switch>
      </When>
      <Otherwise>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route exact path={AppRoutes.RENTER_SINGLE_RENTAL} component={Property} />
          <Route exact path={AppRoutes.RENTALS_ID_COMPANY_SINGLE_UNITS} component={LiveOffers} />
          <Route exact path={AppRoutes.RENTER_ERROR_PAGE} component={ErrorPage} />
          <Route exact path={AppRoutes.RENTER_NOT_FOUND_PAGE} component={NotFound} />
          <Route>
            <Redirect to="/auth" />
          </Route>
        </Switch>
      </Otherwise>
    </Choose>
  );
};
