import gql from 'graphql-tag';

export const OFFER_SINGLE_UNIT_QUERY = gql`
  query SingleUnitOfferQuery($id: ID!) {
    offer(id: $id) {
      id
      incrementedBidAmount
      securityDeposite
      securityDepositeEditable
      termsOfLease
      minimumLeaseLength
      maximumLeaseLength
      rentAmount
      moveinDate
      moveinDateEditable
      startsIn
      endsIn
      secureNowAmount
      secureNow
      status
      historyOfferSingleUnit {
        id
        name
        type
        description
        bedrooms
        bathrooms
        squareFt
        address {
          country
          street1
          street2
          zip
          city
          state
        }
        community {
          id
          communityHasMedia(sort: { order: ASC }) {
            items {
              id
              altName
              order
              media {
                id
                name
                file {
                  id
                  fileId
                  filename
                  downloadUrl
                  meta
                }
              }
            }
          }
        }
        phone {
          code
          number
        }
        singleUnitHasMedia(sort: { order: ASC }) {
          items {
            id
            altName
            order
            media {
              id
              name
              file {
                id
                fileId
                filename
                downloadUrl
                meta
              }
            }
          }
        }
      }
      leaseContract {
        id
        downloadUrl
      }
      leaseLink
      bid(sort: [{ rank: DESC }, { updatedAt: ASC }]) {
        items {
          id
          status
          rentAmount
          termsOfLease
          moveinDate
          offerAccepted
          applicationFormComplete
          screeningComplete
          leaseSigned
          depositCheck
          securityDeposite
          offer {
            id
            status
            screeningLink
            livoManagesScreening
            applicationLink
            activeOfferSingleUnit {
              id
              company {
                id
                companyWesternReportingAccount {
                  id
                  isUsingWesternReporting
                  password
                  login
                }
              }
            }
          }
          renter {
            id
            user {
              id
              firstName
              lastName
            }
          }

          rank
          debugPresentDaySystem
          debugV1
          debugV2
          debugV3
        }
        count
      }
    }
  }
`;

export const CHECK_SCREENING_LINK = gql`
  query CheckWrScreeningLink($renterFilter: WesternReportingScreeningFilter) {
    westernReportingScreeningsList(filter: $renterFilter) {
      items {
        id
        screeningLink
      }
    }
  }
`;

export const WR_SCREENING_MUTATION = gql`
  mutation screeningInfo($data: WesternReportingScreeningCreateInput!) {
    westernReportingScreeningCreate(data: $data) {
      id
      screeningID
      screeningLink
    }
  }
`;

export const BEGIN_WR_SCREENING = gql`
  mutation beginWRScreening($data: BeginWesternReportingScreeningInput!) {
    beginWesternReportingScreening(data: $data) {
      result
    }
  }
`;
